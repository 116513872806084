import Excel from '../assets/softwares/excel-logo.svg';
import Nx from '../assets/softwares/siemens-nx-logo.png';
import Python from '../assets/softwares/python.svg';

import COLORS from '../styles/colors';

import { Avatar, DEFAULT_AVATAR_SETTINGS } from '../components/AnimatedAvatar';

export enum NodeType {
  PERSON = 'person',
  MODEL = 'model',
  NULL = 'null',
}

export type Node = {
  id: string;
  type: NodeType;
  position: [number, number];
  parent: string[];
  image?: string;
  role?: string;
  avatar?: Avatar;
};

type Data = {
  settings: { grid: number; dotRadius: number; viewBox: [number, number] };
  nodes: Record<string, Node>;
};

const data: Data = {
  settings: {
    viewBox: [1100, 1000],
    grid: 30,
    dotRadius: 1.5,
  },
  nodes: {
    A: {
      id: 'A',
      type: NodeType.PERSON,
      position: [60, 600],
      parent: ['D'],
      role: 'Electronics Engineer',
      avatar: {
        ...DEFAULT_AVATAR_SETTINGS,
        clothing: { style: 4, color: COLORS.error.light.css },
        lips: true,
        hair: { style: 2 },
      },
    },
    B: {
      id: 'B',
      type: NodeType.PERSON,
      position: [690, 600],
      parent: ['D'],
      role: 'Thermal Engineer',
      avatar: {
        ...DEFAULT_AVATAR_SETTINGS,
        clothing: { style: 5, color: COLORS.error.regular.css },
        hair: { style: 5 },
        eyebrows: { ...DEFAULT_AVATAR_SETTINGS.eyebrows, width: 4 },
      },
    },
    C: {
      id: 'C',
      type: NodeType.PERSON,
      position: [420, 750],
      parent: ['D'],
      role: 'Mechanical Engineer',
      avatar: {
        ...DEFAULT_AVATAR_SETTINGS,
        clothing: { style: 3, color: COLORS.success.regular.css },
        lips: true,
        skin: String(COLORS.warn.dark.mix(COLORS.white, 0.5)),
        hair: { style: 3 },
        glasses: true,
        earrings: true,
      },
    },
    D: {
      id: 'D',
      type: NodeType.PERSON,
      position: [330, 450],
      parent: ['E', 'F'],
      role: 'System Engineer',
      avatar: {
        ...DEFAULT_AVATAR_SETTINGS,
        clothing: { style: 0, color: COLORS.success.light.css },
        skin: COLORS.warn.dark.css,
        hair: { style: 0 },
        beard: true,
      },
    },
    E: {
      id: 'E',
      type: NodeType.PERSON,
      position: [240, 150],
      parent: ['K'],
      role: 'Chief Engineer',
      avatar: {
        ...DEFAULT_AVATAR_SETTINGS,
        clothing: { style: 2, color: COLORS.warn.regular.css },
        lips: true,
        hair: { style: 1, ponytail: true },
      },
    },
    F: {
      id: 'F',
      type: NodeType.PERSON,
      position: [600, 300],
      parent: ['H'],
      role: 'Project Manager',
      avatar: {
        ...DEFAULT_AVATAR_SETTINGS,
        clothing: { style: 1, color: COLORS.brand.regular.css },
        hair: { style: 4 },
      },
    },
    G: {
      id: 'G',
      type: NodeType.MODEL,
      image: Excel,
      position: [150, 900],
      parent: ['C'],
    },
    H: {
      id: 'H',
      type: NodeType.MODEL,
      image: Python,
      position: [900, 150],
      parent: ['L', 'M'],
    },
    I: {
      id: 'I',
      type: NodeType.MODEL,
      image: Nx.src,
      position: [810, 900],
      parent: ['B'],
    },
    J: {
      id: 'J',
      type: NodeType.NULL,
      position: [510, 1050],
      parent: ['C'],
    },
    K: {
      id: 'K',
      type: NodeType.NULL,
      position: [150, -150],
      parent: [],
    },
    L: {
      id: 'L',
      type: NodeType.NULL,
      position: [1170, 0],
      parent: [],
    },
    M: {
      id: 'M',
      type: NodeType.NULL,
      position: [810, -150],
      parent: [],
    },
  },
};

export default data;
