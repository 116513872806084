import React, { useState } from 'react';
import styled from 'styled-components';
import { useInterval } from 'react-use';
import { animated, useSpring } from '@react-spring/web';
import { link as d3link, curveBumpX } from 'd3-shape';

import TYPO from '../styles/typography';
import COLORS from '../styles/colors';
import data, { Node } from '../data/insightsData';

import RequirementBox from '../styles/animations/RequirementBox';

const Container = styled.svg`
  position: relative;
  overflow: visible;
  & foreignObject {
    overflow: visible;
  }

  & .name {
    ${TYPO.p3}
    font-size:13px;
    font-weight: 500;
  }
  & .description {
    ${TYPO.p3}
    fill: ${COLORS.shades.s300.css}
  }
  & .type {
    ${TYPO.p3}
    font-size:8px;
    text-transform: uppercase;
    letter-spacing: 0.15em;
  }
`;

const bounds = (d: Node) => {
  const x = d.depth * (data.node.width + data.gap.h);
  const height =
    d.type === 'Requirement' ? data.node.heightMin : data.node.heightMax;
  const spaceV = data.node.heightMax + data.gap.v;
  const even = d.depth % 2 === 0;
  const top = even ? spaceV / 2 : 0;
  const y = top + d.offset * spaceV;

  return { x, y, width: data.node.width, height };
};

type DisplayLinkProps = {
  path: string;
  active: boolean;
};
const DisplayLink = ({ path, active }: DisplayLinkProps) => {
  const [ref, setRef] = useState(null);

  const length = ref?.getTotalLength() || 0;

  const { strokeDashoffset } = useSpring({
    strokeDashoffset: active ? length * 2 : length * 3,
    delay: 500,
  });

  return (
    <animated.path
      d={path}
      stroke={COLORS.error.regular.css}
      fill="none"
      strokeDasharray={length || 70}
      style={{ strokeDashoffset }}
      strokeWidth={2}
      ref={setRef}
      filter={`drop-shadow(0px 0px 10px ${COLORS.error.regular.opacity(0.3)})`}
    />
  );
};

const tagColor = (type: string) => {
  switch (type) {
    case 'Requirement':
      return {
        text: COLORS.rainbow.cyan.dark.css,
        background: COLORS.rainbow.cyan.dark.mix(COLORS.white, 0.9),
      };
    case 'Model':
      return {
        text: COLORS.rainbow.yellow.dark.css,
        background: COLORS.rainbow.yellow.dark.mix(COLORS.white, 0.9),
      };
    case 'Test Data':
      return {
        text: COLORS.rainbow.green.dark.css,
        background: COLORS.rainbow.green.dark.mix(COLORS.white, 0.9),
      };
    case 'Simulation':
      return {
        text: COLORS.rainbow.orange.dark.css,
        background: COLORS.rainbow.orange.dark.mix(COLORS.white, 0.9),
      };
    case 'Value':
      return {
        text: COLORS.rainbow.pink.dark.css,
        background: COLORS.rainbow.pink.dark.mix(COLORS.white, 0.9),
      };
    default:
      return {
        text: COLORS.brand.regular.css,
        background: COLORS.brand.regular.mix(COLORS.white, 0.9),
      };
  }
};
const diagonal = d3link<unknown, Node>(curveBumpX)
  .x((d) => d[0])
  .y((d) => d[1]);

type AnimationInsightsProps = React.HTMLAttributes<SVGElement> & {
  canAnimate: boolean;
};

export default function AnimationInsights({
  canAnimate,
}: AnimationInsightsProps) {
  const [count, setCount] = useState(0);

  useInterval(
    () => {
      setCount((prev) => (prev + 1) % 5);
    },
    canAnimate ? 1200 : null
  );

  return (
    <Container
      width="100%"
      height="100%"
      viewBox={`0 0 ${data.size[0]} ${data.size[1]}`}
      style={{ maxWidth: `${data.size[0]}px`, maxHeight: `${data.size[1]}px` }}
      preserveAspectRatio="xMidYMid meet"
    >
      <g transform={`translate(0,50)`}>
        {data.nodes.map((node) => {
          const index = data.highlight.indexOf(node.id);
          const active = index > -1;

          const { x, y, width, height } = bounds(node);

          const color = tagColor(node.type);
          return (
            <g transform={`translate(${x},${y})`} key={node.id}>
              <rect
                height={14}
                width={node.type.length * 6 + 16}
                x={0}
                y={-14}
                fill={color.background as string}
              />
              <text y={-4} x={7} className="type" fill={color.text}>
                {node.type}
              </text>
              <rect
                width={width}
                height={height}
                stroke={COLORS.shades.s200.css}
                fill={COLORS.white.css}
                rx={3}
              />
              <text x={6} y={20} className="name">
                {node.name}
              </text>
              {'logo' in node && (
                <image x={6} y={26} href={node.logo} height={16} width={16} />
              )}
              {'data' in node && (
                <text
                  x={'logo' in node ? 28 : 6}
                  y={38}
                  className="description"
                >
                  {node.data}
                </text>
              )}
              <RequirementBox
                success={active ? count > index : node.check}
                x={width - 26}
                size={18}
                y={7}
              />
            </g>
          );
        })}

        {data.links.map((d) => {
          const sourceNode = data.nodes[d.source];
          const targetNode = data.nodes[d.target];

          const source = bounds(sourceNode);
          const target = bounds(targetNode);
          const y1 = source.y + source.height / 2;
          const x1 = source.x + source.width;

          const y2 = target.y + target.height / 2;
          const x2 = target.x;

          const path = diagonal({
            source: [x1, y1],
            target: [x2, y2],
          });

          const index = data.highlight.indexOf(d.target);
          const active = index > -1;

          return (
            <React.Fragment key={d.id}>
              <path
                d={path}
                stroke={COLORS.shades.s300.css}
                fill="none"
                strokeDasharray="2,2"
              />
              {active && <DisplayLink path={path} active={index >= count} />}
            </React.Fragment>
          );
        })}
      </g>
    </Container>
  );
}
