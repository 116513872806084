import React, { memo } from 'react';
import styled, { css } from 'styled-components';
import useResizeObserver from 'use-resize-observer';

import type { BlockTableCompetitorsFragment } from '../schemas/api';

import useInview from '../hooks/useInview';

import TYPO from '../styles/typography';
import BREAKPOINTS, { breakpointSizes } from '../styles/breakpoints';
import { Grid, columns } from '../styles/grid';
import Heading from './Heading';
import COLORS from '../styles/colors';
import Image from './Image';
import Icon from './Icon';
import CTAButtons from './CTAButtons';

const HIGHLIGHT_OFFSET = 24;
const ROW_HEIGHT = 72;
const ROW_HEIGHT_MOBILE = 60;

const Container = styled(Grid)`
  margin-top: 180px;
`;

const Table = styled.div`
  ${columns(1, 12)};
  display: flex;
  flex-direction: column;
  margin-bottom: 48px;
`;

const TableHeader = styled.div`
  display: flex;
`;

const TableBody = styled.div`
  display: flex;
  flex-direction: column;
`;

const RowGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding-bottom: 24px;
`;

type RowGroupNameProps = {
  $highlight: boolean;
};

const RowGroupName = styled.div<RowGroupNameProps>`
  display: flex;
  justify-content: center;
  text-align: center;
  ${TYPO.strong}
  color: ${(props) =>
    props.$highlight ? COLORS.brand.regular.css : COLORS.shades.s300.css};
  height: 24px;
`;

const RowGroupItems = styled.div`
  display: flex;
`;

const CellStyle = css`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  padding: 6px;

  margin: 0;
`;

type CellProps = {
  $highlight: boolean;
  $first: boolean;
  $last: boolean;
};

const Cell = styled.div<CellProps>`
  ${CellStyle}
  flex:1;
  color: ${COLORS.shades.s300.css};

  overflow: hidden;
  position: relative;
  z-index: 0;
  ${(props) => {
    if (props.$highlight) {
      return css`
        border-left: 1px solid ${COLORS.brand.regular.css};
        border-right: 1px solid ${COLORS.brand.regular.css};
        ${props.$first &&
        css`
          margin-top: -${HIGHLIGHT_OFFSET}px;
          height: calc(100% + ${HIGHLIGHT_OFFSET}px);
          border-radius: 9px 9px 0 0;
          padding-top: ${HIGHLIGHT_OFFSET}px;
          border-top: 1px solid ${COLORS.brand.regular.css};
        `}
        ${props.$last &&
        css`
          height: calc(100% + ${HIGHLIGHT_OFFSET}px);
          border-radius: 0 0 9px 9px;
          border-bottom: 1px solid ${COLORS.brand.regular.css};
          padding-bottom: ${HIGHLIGHT_OFFSET}px;
        `}
        background: ${COLORS.white.css};
      `;
    }
    return css``;
  }}

  ${TYPO.p3}
  ${BREAKPOINTS.max.small`
  padding: 2px 0;
  font-size: 10px;
  & span {
  display:block;
  width:100%;
  padding:0 2px;
  }
`}

  @media (max-width: 380px) {
    font-size: 8px;
  }
`;

const HeaderCell = styled.div`
  ${CellStyle}
  flex-direction:column;
  gap: 6px;
  ${TYPO.strong}
`;

const CheckIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: ${COLORS.success.bright.css};
  color: ${COLORS.white.css};
`;

const Row = styled.div`
  display: flex;
  position: relative;
  margin: 0;
  border: 1px solid ${COLORS.shades.s200.css};
  border-bottom: 0;
  background: ${COLORS.white.css};
  height: ${ROW_HEIGHT}px;
  &:first-child {
    border-radius: 9px 9px 0 0;
  }
  &:last-child {
    border-radius: 0 0 9px 9px;
    border-bottom: 1px solid ${COLORS.shades.s200.css};
  }
  &:only-child {
    border-radius: 9px;
  }
  &:hover {
    background: ${COLORS.shades.s100.mix(COLORS.white, 0.7) as string};
    & ${Cell} {
      background: ${COLORS.shades.s100.mix(COLORS.white, 0.7) as string};
    }
  }

  ${BREAKPOINTS.max.small`
  height: ${ROW_HEIGHT_MOBILE}px;
`}
`;

const CellColumn = styled.div`
  ${CellStyle}
  display: flex;
  width: 100%;
  text-align: left;
  justify-content: flex-start;

  color: ${COLORS.black.css};
  ${TYPO.p2};
  flex: 0 0 230px;
  padding: 6px 12px;
  ${BREAKPOINTS.max.small`
  flex: 0 0 180px;
`}

  ${BREAKPOINTS.max.extraSmall`
  flex: 0 0 105px;
  font-size:12px;
`}

 @media (max-width: 380px) {
    flex: 0 0 95px;
    font-size: 11px;
  }
`;

type TableCompetitor = {
  columns: string[];
  rows: { name: string; items: string[] }[];
  content: Record<string, boolean | null | string>;
};
type BlockTableCompetitorsProps = {
  data: BlockTableCompetitorsFragment;
};
function BlockTableCompetitors({ data }: BlockTableCompetitorsProps) {
  const [inViewRef, inView] = useInview({});
  const { ref, width = 1 } = useResizeObserver<HTMLDivElement>();
  const table: TableCompetitor = data.data;

  if (!table) return null;

  const rows =
    width < breakpointSizes.extraSmall ? table.rows.slice(1) : table.rows;

  return (
    <Container ref={ref}>
      <Heading
        heading={data.heading}
        byline={data.byline}
        show={inView}
        ref={inViewRef}
      />
      <Table>
        <TableHeader>
          <CellColumn />
          {rows.map((d) => {
            return (
              <RowGroup key={d.name} style={{ flex: d.items.length }}>
                <RowGroupName $highlight={d.items.includes('Flow')}>
                  {d.name}
                </RowGroupName>
                <RowGroupItems>
                  {d.items.map((item) => {
                    const image = data.logos.items.find(
                      (d) => d.title.toLowerCase() === item.toLowerCase()
                    );

                    return (
                      <HeaderCell key={`${d}-${item}`}>
                        {image && (
                          <Image
                            src={image.url}
                            height={24}
                            width={24}
                            alt={image.title}
                          />
                        )}
                        <label>{item}</label>
                      </HeaderCell>
                    );
                  })}
                </RowGroupItems>
              </RowGroup>
            );
          })}
        </TableHeader>
        <TableBody>
          {table.columns.map((col, index) => {
            return (
              <Row key={col}>
                <CellColumn>{col}</CellColumn>
                {rows.map((group) => {
                  return group.items.map((item) => {
                    const cell = table.content[item][index];
                    const key = `item-${col}-${item}-${cell}`;

                    const props = {
                      $highlight: item === 'Flow',
                      $first: index === 0,
                      $last: index === table.columns.length - 1,
                    };
                    if (cell === false) {
                      return (
                        <Cell key={key} {...props}>
                          <Icon
                            name="X"
                            width={18}
                            height={18}
                            style={{ color: COLORS.error.regular.css }}
                          />
                        </Cell>
                      );
                    }
                    if (cell === true) {
                      return (
                        <Cell key={key} {...props}>
                          <CheckIconWrapper>
                            <Icon name="Check" width={12} height={12} />
                          </CheckIconWrapper>
                        </Cell>
                      );
                    }

                    if (cell === null) {
                      return (
                        <Cell key={key} {...props}>
                          <Icon name="Minus" width={12} height={12} />
                        </Cell>
                      );
                    }
                    return (
                      <Cell key={key} {...props}>
                        <span>{cell}</span>
                      </Cell>
                    );
                  });
                })}
              </Row>
            );
          })}
        </TableBody>
      </Table>
      <CTAButtons links={data.callToActions?.items} />
    </Container>
  );
}

export default memo(BlockTableCompetitors);
