import React, { useState, memo } from 'react';
import styled, { css } from 'styled-components';
import ReactPlayer, { ReactPlayerProps } from 'react-player/lazy';

import { useSpring, animated, easings } from '@react-spring/web';

import useInview from '../hooks/useInview';

import type { BlockSplashVideoFragment } from '../schemas/api';

import { fadeTranslateIn } from '../styles/animations';
import COLORS from '../styles/colors';

import { Grid, columns } from '../styles/grid';
import Icon from './Icon';
import Heading from './Heading';
import CTAButtons from './CTAButtons';
import Modal from './Modal';

const Container = styled(Grid)`
  padding: 64px 12px;
  display: flex;
  position: relative;
  width: 100%;
  min-height: calc(100vh - 108px);
  perspective: 800px;
  align-items: flex-start;
`;

const Background = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  height: calc(100vh - 108px);
  width: 100%;
  min-height: 700px;
  display: flex;
`;

const DotGrid = styled.div`
  width: 100%;
  height: 30%;
  background: radial-gradient(rgba(0, 0, 0, 0.2) 1px, transparent 1px);
  background-size: 24px 24px;
  align-self: flex-end;
`;

const Inner = styled.div`
  ${columns(1, 12)}
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 24px;
  padding: 0;
`;

type ContentProps = { $show: boolean; $fullScreen?: boolean };

const VideoContainer = styled.div<ContentProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  max-width: 1180px;
  width: 100%;
  opacity: 0;
  ${(props) =>
    props.$show &&
    css`
      animation: ${fadeTranslateIn(0, 48)} 1s ease-out;
      animation-fill-mode: both;
    `};

  pointer-events: all;

  & .videoContainer {
    display: flex;
    box-shadow: 0px 0px 1px ${COLORS.brand.regular.opacity(0.2)},
      0px 4px 100px ${COLORS.brand.regular.opacity(0.1)};
    border: 2px solid ${COLORS.brand.mid.css};
    border-radius: 6px;
    overflow: hidden;

    ${(props) => {
      if (props.$fullScreen) {
        return css`
          width: 100%;
          padding-top: 56.25%;
          position: relative;
          background: ${COLORS.black.css};
          & > div {
            top: 0;
            left: 0;
            position: absolute;
          }
        `;
      }
      return css``;
    }}
  }
`;

const VideoInViewContainer = styled(animated.div)`
  display: flex;
  transform-origin: top center;

  translate: none;
  rotate: none;
  scale: none;

  will-change: transform;

  & > span {
    position: absolute;
    top: 100vh;
    width: 100%;
  }
`;

const PlayButton = styled.div`
  z-index: 5;
  position: absolute;

  backdrop-filter: blur(2px);
  transition: all 0.4s cubic-bezier(0.34, 1.56, 0.64, 1);

  background: linear-gradient(
      180deg,
      ${COLORS.white.opacity(0)} 17.71%,
      ${COLORS.white.opacity(0.2)} 100%
    ),
    ${COLORS.white.opacity(0.01)};
  border: 1px solid ${COLORS.shades.s200.css};
  border-radius: 50%;

  box-shadow: 0 0 0 10px ${COLORS.white.opacity(0.2)};
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  & > svg {
    fill: ${COLORS.brand.regular.css};
    stroke: none;
  }

  &:hover {
    height: 110px;
    width: 110px;
    box-shadow: 0 0 0 4px ${COLORS.white.opacity(0.2)};
    border-color: ${COLORS.brand.regular.css};
  }
`;

const Trigger = styled.div`
  position: absolute;
  width: 100%;
  top: calc(100vh + 2px);
  height: 2px;
`;

const Glows = styled.div`
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  z-index: 3;
  mix-blend-mode: hard-light;
  & > div {
    position: absolute;
    z-index: 2;

    left: 0;
    top: 0;
    height: 2px;
    width: 25%;
    background: linear-gradient(
      to right,
      ${COLORS.brand.regular.opacity(0)} 0%,
      ${COLORS.brand.regular.opacity(1)} 90%,
      ${COLORS.brand.regular.opacity(0)} 100%
    );
  }
`;

const Close = styled.button`
  pointer-events: all;
  color: ${COLORS.white.css};
  margin-left: auto;
`;

const playerProps = (video: {
  url?: string | null;
  width?: number | string | null;
  height?: number | string | null;
}): ReactPlayerProps => ({
  width: video.width,
  height: video.height,
  url: video.url,
  muted: true,
  playsinline: true,
  loop: true,
  controls: false,
  className: 'videoContainer',
});

type VideoInViewProps = {
  children: React.ReactNode;
  inView: boolean;
};

const VideoInView = ({ inView, children }: VideoInViewProps) => {
  const [style] = useSpring(
    () => ({
      transform: `perspective(207rem)`,
      rotateX: `${inView ? 0 : -15}deg`,
      scale: inView ? 1 : 0.9,
    }),
    [inView]
  );

  return <VideoInViewContainer style={style}>{children}</VideoInViewContainer>;
};

const Glow = () => {
  const style = useSpring({
    from: { opacity: 1, left: '0%' },
    to: { opacity: 0, left: '75%' },
    loop: true,
    delay: 1000,
    config: {
      duration: 1500,
      easing: easings.easeOutCubic,
    },
  });

  return <animated.div style={style} />;
};

type HomeSplashHeroProps = {
  data: BlockSplashVideoFragment;
};
function HomeSplashHero({ data }: HomeSplashHeroProps) {
  const [inViewRef, inView] = useInview({});
  const [inViewTriggerRef, inViewTrigger] = useInview({ once: false });

  const [open, setOpen] = useState(false);
  const [show, setShow] = useState(false);

  const fullVideo = data.fullVideoLink || data.fullVideo;

  return (
    <>
      <Background>
        <DotGrid />
      </Background>
      <Container>
        <Heading
          heading={data.heading}
          byline={data.byline}
          show={inView}
          ref={inViewRef}
        />
        {!!data.callToActions?.items.length && (
          <CTAButtons links={data.callToActions?.items} byline={data.service} />
        )}
        <Inner>
          {fullVideo && (
            <Modal isOpen={open} onRequestClose={() => setOpen(false)}>
              <Close onClick={() => setOpen(false)}>
                <Icon name="X" width={24} height={24} />
              </Close>
              <VideoContainer $show $fullScreen={typeof fullVideo === 'string'}>
                <ReactPlayer
                  {...playerProps(
                    typeof fullVideo === 'string'
                      ? { url: fullVideo, width: '100%' }
                      : fullVideo
                  )}
                  playing={typeof fullVideo === 'string'}
                  controls
                  muted={false}
                />
              </VideoContainer>
            </Modal>
          )}
          {data.video && (
            <VideoInView inView={inViewTrigger}>
              <VideoContainer $show={show}>
                <Glows>
                  <Glow />
                </Glows>
                <PlayButton onClick={() => setOpen(true)}>
                  <Icon name="Play" width={24} height={24} />
                </PlayButton>
                <ReactPlayer
                  {...playerProps(data.video)}
                  playing={!open}
                  onStart={() => {
                    setShow(true);
                  }}
                />
              </VideoContainer>
            </VideoInView>
          )}
        </Inner>
      </Container>
      <Trigger ref={inViewTriggerRef} />
    </>
  );
}

export default memo(HomeSplashHero);
