import React, { memo } from 'react';
import styled from 'styled-components';

import type { BlockContentFragment } from '../schemas/api';

import Markdown from './Markdown';

import useInview from '../hooks/useInview';

import { staggerChildrenFadeTranslateIn } from '../styles/animations';
import TYPO from '../styles/typography';
import BREAKPOINTS from '../styles/breakpoints';
import { Grid, columns } from '../styles/grid';

import * as textUtils from '../utils/text';

type ContainerProps = { $show: boolean };
const Container = styled(Grid)<ContainerProps>`
  margin-top: 224px;
  & > div {
    ${(props) => staggerChildrenFadeTranslateIn(props.$show)}
  }
`;

const Content = styled.div`
  ${columns(8, 5)}

  ${BREAKPOINTS.max.medium`
    ${columns(7, 6)}
    `}
  ${BREAKPOINTS.max.small`
    ${columns(1, 12)}
    `}
`;

const Heading = styled.h1`
  ${TYPO.h1}
  ${columns(1, 4)}

  ${BREAKPOINTS.max.medium`
    ${columns(1, 6)}
    `}
  ${BREAKPOINTS.max.small`
    ${columns(1, 12)}
    margin-bottom: 24px;
    `}
`;

type ContentTwoColumnProps = {
  data: BlockContentFragment;
};

function ContentTwoColumn({ data }: ContentTwoColumnProps) {
  const [inViewRef, inView] = useInview({});
  return (
    <Container ref={inViewRef} $show={inView}>
      {data.heading && <Heading>{textUtils.highlight(data.heading)}</Heading>}
      <Content ref={inViewRef}>
        <Markdown content={data.content} />
      </Content>
    </Container>
  );
}

export default memo(ContentTwoColumn);
