import React, {
  ForwardedRef,
  cloneElement,
  forwardRef,
  useCallback,
  useMemo,
  useState,
} from 'react';

import {
  FloatingFocusManager,
  FloatingNode,
  FloatingPortal,
  FloatingTree,
  FloatingTreeType,
  MiddlewareData,
  Placement,
  ReferenceElement,
  UseRoleProps,
  autoUpdate,
  useClick,
  useDismiss,
  useFloating,
  useFloatingNodeId,
  useFloatingParentNodeId,
  useFloatingTree,
  useId,
  useInteractions,
  useRole,
  offset,
  flip,
  shift,
  size,
} from '@floating-ui/react';
import { mergeRefs } from 'react-merge-refs';
import styled from 'styled-components';

import { fadeTranslateIn } from '../styles/animations';
import COLORS from '../styles/colors';
import ELEVATION from '../styles/elevation';

const ANIMATION_DISTANCE = 6;

type ContainerProps = {
  $placement: Placement;
};

const Container = styled.div<ContainerProps>`
  overflow: visible;
  display: flex;
  animation-name: ${(props) => {
    const side = props.$placement.split('-')[0];
    switch (side) {
      case 'top':
        return fadeTranslateIn(0, ANIMATION_DISTANCE);
      case 'left':
        return fadeTranslateIn(ANIMATION_DISTANCE, 0);
      case 'right':
        return fadeTranslateIn(-ANIMATION_DISTANCE, 0);
      case 'bottom':
      default:
        return fadeTranslateIn(0, -ANIMATION_DISTANCE);
    }
  }};
  animation-duration: 0.2s;
  animation-timing-function: ease-out;
  animation-fill-mode: both;
  z-index: 9998;
  outline: none;

  background: ${COLORS.white.css};
  ${ELEVATION.s200};
  border-radius: 4px;

  &:focus {
    outline: none;
  }
`;

type RenderInfo = {
  middlewareData: MiddlewareData;
  placement: Placement;
  tree: FloatingTreeType<ReferenceElement> | null;
};

type FloatingProps = Omit<React.HTMLAttributes<HTMLElement>, 'children'> & {
  render:
    | React.ReactNode
    | ((close: () => void, data: RenderInfo) => React.ReactNode);
  placement?: Placement;
  children: JSX.Element;
  role?: UseRoleProps['role'];
  disabled?: boolean;
  onClose?: () => void;
};

const FloatingComponent = (
  {
    children,
    render,
    placement: initialPlacement = 'bottom',
    role,
    disabled,
    onClose,
    ...parentProps
  }: FloatingProps,
  parentRef: ForwardedRef<HTMLElement>
) => {
  const [open, setOpen] = useState(false);

  const id = useId();
  const nodeId = useFloatingNodeId();
  const tree = useFloatingTree();

  const floating = useFloating({
    nodeId,
    open,
    onOpenChange: (status) => {
      setOpen(status);
      if (!status && onClose) onClose();
    },
    middleware: [
      offset(2),
      flip(),
      size({
        apply({ availableWidth, availableHeight, elements }) {
          Object.assign(elements.floating.style, {
            maxWidth: `${availableWidth - 6}px`,
            maxHeight: `${availableHeight - 6}px`,
          });
        },
      }),
      shift(),
    ],
    placement: initialPlacement,
    whileElementsMounted: autoUpdate,
    strategy: 'fixed',
  });

  const { x, y, strategy, context, placement, middlewareData, refs } = floating;

  const { setReference, setFloating } = refs;

  const { getReferenceProps, getFloatingProps } = useInteractions([
    useRole(context, { role }),
    useClick(context),
    useDismiss(context),
  ]);

  // Preserve the consumer's ref
  const ref = useMemo(() => {
    return mergeRefs([setReference, (children as any).ref, parentRef]);
  }, [setReference, children, parentRef]);

  const close = useCallback(() => {
    setOpen(false);
    if (onClose) onClose();
  }, [onClose]);

  if (disabled) {
    return cloneElement(
      children,
      getReferenceProps({ ref, ...children.props, ...parentProps })
    );
  }

  return (
    <FloatingNode id={nodeId}>
      {cloneElement(
        children,
        getReferenceProps({ ref, ...children.props, ...parentProps })
      )}
      {open && (
        <FloatingPortal id="floating-portal">
          <FloatingFocusManager
            context={context}
            order={['reference']}
            returnFocus={false}
            modal
          >
            <Container
              $placement={placement}
              ref={setFloating}
              style={{
                position: strategy,
                top: y ?? 0,
                left: x ?? 0,
              }}
              aria-labelledby={id}
              data-ignore-click-outside={open}
              tabIndex={0}
              {...getFloatingProps()}
            >
              {typeof render === 'function'
                ? render(close, { middlewareData, placement, tree })
                : render}
            </Container>
          </FloatingFocusManager>
        </FloatingPortal>
      )}
    </FloatingNode>
  );
};

const Floating = forwardRef<HTMLElement, FloatingProps>(FloatingComponent);

const FloatingTreeComponent = (
  props: FloatingProps,
  ref: ForwardedRef<HTMLElement>
) => {
  const parentId = useFloatingParentNodeId();

  if (parentId == null) {
    return (
      <FloatingTree>
        <Floating {...props} ref={ref} />
      </FloatingTree>
    );
  }

  return <Floating {...props} ref={ref} />;
};

export default forwardRef<HTMLElement, FloatingProps>(FloatingTreeComponent);
