import React, {
  memo,
  useState,
  useRef,
  useMemo,
  useCallback,
  useEffect,
} from 'react';
import styled from 'styled-components';
import anime from 'animejs';

import TYPO from '../styles/typography';
import COLORS from '../styles/colors';
import { rotate } from '../styles/animations';
import { FONT_STRING } from '../styles/fonts';
import BREAKPOINTS from '../styles/breakpoints';

import AnimatedAvatar from './AnimatedAvatar';
import Bell from '../styles/animations/Bell';

import { getPathBezier } from '../utils/path';
import * as textUtils from '../utils/text';
import { pythagorean, randomInt } from '../utils/math';
import data, { NodeType, Node } from '../data/splashRequirementsData';

const NODE_WIDTH = 300;
const NODE_HEIGHT = 74;
const NODE_PADDING = 30;
const AVATAR_RADIUS = 50;

const NODE_STROKE = 4;
const NODE_ROLE_PADDING = 15;
const NODE_ROLE_HEIGHT = 30;

const BALLOON_RADIUS = 60;
const BALLOON_PIN_SIZE = 20;

const MODEL_WIDTH = 270;
const MODEL_HEIGHT = 74;
const MODEL_IMAGE_RECT = 90;
const MODEL_IMAGE_SIZE = 70;

const REQ_WIDTH = 28;

const SVG = styled.svg`
  height: 100%;
  width: 100%;
  & svg {
    overflow: visible;
  }
  & .shadow {
    filter: url(#shadow);
  }
  & * {
    transform-box: fill-box;
  }
  & rect {
    transform-origin: center;
  }
  & .balloon {
    transform-origin: bottom left;
  }
  & .requirementBox[data-success='true'] {
    & .backgroundBox {
      stroke: ${COLORS.success.dark.css};
      fill: ${COLORS.success.light.css};
    }
    & .sign {
      stroke: ${COLORS.success.dark.css};
    }
  }
  & .requirementBox[data-success='false'] {
    & .backgroundBox {
      stroke: ${COLORS.error.regular.css};
      fill: ${COLORS.error.light.css};
    }
    & .sign {
      stroke: ${COLORS.error.regular.css};
    }
  }
  & text {
    user-select: none;
    ${TYPO.small};
    font-size: 11px;
    text-transform: uppercase;
    letter-spacing: 1.5px;
  }
`;

const ContentGroup = styled.g`
  transform: translate(0px, 0px);
  ${BREAKPOINTS.max.ipad`
       shape-rendering: optimizeSpeed;
       text-rendering: optimizeSpeed;
  `}
`;

const LoaderHalfCircle = styled.circle`
  animation: ${rotate} 8s linear infinite;
  transform-origin: center;
`;

const LoaderDashCircle = styled.circle`
  animation: ${rotate} 4s linear infinite;
  transform-origin: center;
`;

function Loader({ radius, strokeWidth, ...props }) {
  return (
    <g {...props}>
      <LoaderHalfCircle
        cx={radius}
        cy={radius}
        r={radius}
        fill="none"
        strokeWidth={strokeWidth}
        stroke={COLORS.brand.regular.css}
        strokeDasharray={Math.PI * 10}
        strokeLinecap="round"
        className="outerRing"
      />
      <LoaderDashCircle
        cx={radius}
        cy={radius}
        r={radius}
        fill="none"
        strokeWidth={strokeWidth}
        stroke={COLORS.brand.regular.css}
        strokeDasharray="5 8"
        strokeLinecap="round"
      />
    </g>
  );
}

interface PencilIconProps extends React.HTMLAttributes<SVGElement> {
  size?: number;
  canAnimate?: boolean;
  x?: number;
  y?: number;
}

function PencilIcon({
  size = 60,
  canAnimate = true,
  ...props
}: PencilIconProps) {
  const ref = useRef(null);
  const timeline = useRef(null);

  useEffect(() => {
    timeline.current = anime.timeline({
      autoplay: true,
      loop: true,
    });

    const pencil = ref.current.querySelector('.pencil');
    const line = ref.current.querySelector('.line');
    timeline.current.add({
      targets: pencil,
      translateX: 50,
      rotate: [30, 0],
      easing: 'easeInOutQuart',
      duration: 800,
    });

    timeline.current.add(
      {
        targets: line,
        strokeDashoffset: [anime.setDashoffset, 0],
        easing: 'easeInOutQuart',
        duration: 800,
      },
      '-=800'
    );

    timeline.current.add({
      targets: pencil,
      translateX: 35,
      translateY: -15,
      rotate: 25,
      easing: 'easeInQuad',
      duration: 400,
    });

    timeline.current.add({
      targets: pencil,
      translateX: 0,
      translateY: 0,
      rotate: 30,
      easing: 'easeOutQuad',
      duration: 400,
    });

    timeline.current.add(
      {
        targets: line,
        strokeDashoffset: [0, anime.setDashoffset],
        easing: 'easeInOutQuart',
        duration: 900,
        delay: 200,
      },
      '-=800'
    );
  }, []);

  useEffect(() => {
    if (canAnimate) {
      timeline.current.play();
    } else {
      timeline.current.pause();
    }
  }, [canAnimate]);

  return (
    <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`} {...props}>
      <g
        fill="none"
        stroke="black"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
        ref={ref}
      >
        <g className="pencil" style={{ transformOrigin: 'bottom center' }}>
          <path d={`M5 10 L0 10 L0 5 C0 4 1 0 5 0 C9 0 10 4 10 5 L10 10`} />
          <path d={`M0 15 L0 50 L5 60 L10 50 L10 15`} />
        </g>
        <line className="line" x1={5} x2={55} y1={60} y2={60} />
      </g>
    </svg>
  );
}

type ModelNodeProps = {
  node: Node;
};

function ModelNode({ node }: ModelNodeProps) {
  return (
    <g transform={`translate(${node.position[0]},${node.position[1]})`}>
      <rect
        width={MODEL_WIDTH}
        height={MODEL_HEIGHT}
        rx={20}
        x={-MODEL_WIDTH / 2}
        y={-MODEL_HEIGHT / 2}
        fill={COLORS.brand.light.css}
        strokeWidth={NODE_STROKE}
        stroke={COLORS.white.css}
        className="shadow"
      />
      <rect
        width={50}
        height={6}
        rx={3}
        x={-NODE_WIDTH / 2 + AVATAR_RADIUS * 2 + NODE_PADDING / 2}
        y={-6}
        fill={String(COLORS.brand.light.mix(COLORS.black, 0.15))}
      />
      <rect
        width={100}
        height={6}
        rx={3}
        x={-NODE_WIDTH / 2 + AVATAR_RADIUS * 2 + NODE_PADDING / 2}
        y={6}
        fill={String(COLORS.brand.light.mix(COLORS.black, 0.15))}
      />
      <Loader
        radius={10}
        strokeWidth={2}
        transform={`translate(${MODEL_WIDTH / 2 - 45},${-10})`}
      />
      <g transform={`translate(${-MODEL_WIDTH / 2},${-MODEL_IMAGE_RECT / 2})`}>
        <rect
          width={MODEL_IMAGE_RECT}
          height={MODEL_IMAGE_RECT}
          rx={10}
          x={0}
          y={0}
          fill={COLORS.white.css}
          strokeWidth={NODE_STROKE}
          stroke={COLORS.brand.light.css}
        />
        <image
          href={node.image}
          x={(MODEL_IMAGE_RECT - MODEL_IMAGE_SIZE) / 2}
          y={(MODEL_IMAGE_RECT - MODEL_IMAGE_SIZE) / 2}
          height={MODEL_IMAGE_SIZE}
          width={MODEL_IMAGE_SIZE}
        />
      </g>
    </g>
  );
}

const CROSS = (size: number) =>
  `M0 ${size * 1.5} L0 ${size * 0.5} Q0 0 ${size * 0.5} 0 Q${size} 0 ${size} ${
    size * 0.5
  } L${size} ${size * 2.5}`;
const CROSS_LINE = (size: number) =>
  `M0 ${size * 1.5} L${size * 2} ${size * 1.5}`;

type RequirementNodeProps = {
  node: Node;
  id: string;
  editing: boolean;
  notification: boolean;
  canAnimate: boolean;
};

function RequirementNode({
  node,
  editing,
  notification,
  canAnimate,
  ...props
}: RequirementNodeProps) {
  const [textWidth, setTextWidth] = useState(180);

  useEffect(() => {
    if (node.role) {
      const textWidth =
        textUtils.measure(
          String(node.role).toUpperCase(),
          FONT_STRING,
          11,
          13,
          500
        ) + node.role.length;

      setTextWidth(textWidth || 180);
    }
  }, [node]);

  return (
    <g
      transform={`translate(${node.position[0]},${node.position[1]})`}
      {...props}
    >
      <rect
        width={NODE_WIDTH}
        height={NODE_HEIGHT}
        rx={NODE_HEIGHT / 2}
        x={-NODE_WIDTH / 2}
        y={-NODE_HEIGHT / 2}
        fill={COLORS.brand.light.css}
        strokeWidth={NODE_STROKE}
        stroke={COLORS.white.css}
        className="shadow"
      />
      <rect
        width={100}
        height={6}
        rx={3}
        x={-NODE_WIDTH / 2 + AVATAR_RADIUS * 2 + NODE_PADDING / 2}
        y={-3}
        fill={String(COLORS.brand.light.mix(COLORS.black, 0.15))}
      />

      <g
        transform={`translate(${NODE_WIDTH / 2 - REQ_WIDTH - NODE_PADDING}, ${
          -REQ_WIDTH / 2
        })`}
        className="requirementBox"
        data-success
      >
        <rect
          width={REQ_WIDTH}
          height={REQ_WIDTH}
          rx={3}
          x={0}
          y={0}
          strokeWidth={2}
          className="backgroundBox"
        />
        <g className="sign">
          <g transform={`translate(${REQ_WIDTH / 2},${REQ_WIDTH / 2})`}>
            <g transform={`translate(-9, -2)`}>
              <g transform={`rotate(-45)`}>
                <path
                  d={CROSS(6)}
                  fill="none"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeDasharray="6,28"
                  className="sign-moving"
                />
                <path
                  d={CROSS_LINE(6)}
                  fill="none"
                  strokeWidth={2}
                  strokeLinecap="round"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
      <g
        className="avatar"
        transform={`translate(${-NODE_WIDTH / 2},${-AVATAR_RADIUS})`}
      >
        <circle
          r={AVATAR_RADIUS}
          cx={AVATAR_RADIUS}
          cy={AVATAR_RADIUS}
          fill={String(COLORS.brand.regular.mix(COLORS.white, 0.7))}
          strokeWidth={NODE_STROKE}
          stroke={COLORS.white.css}
        />
        <g clipPath="url(#avatarClip)">
          <AnimatedAvatar
            width={AVATAR_RADIUS * 2}
            height={AVATAR_RADIUS * 2}
            notification={notification}
            editing={editing}
            key={props.id}
            avatar={node.avatar}
            canAnimate={canAnimate}
          />
        </g>
      </g>
      {node.role && (
        <g transform={`translate(-45,45)`} className="role">
          <rect
            width={textWidth + NODE_ROLE_PADDING * 2}
            height={NODE_ROLE_HEIGHT}
            x={0}
            y={0}
            fill={COLORS.white.css}
            className="shadow"
          />
          <circle r={7} cx={0} cy={0} fill={COLORS.brand.regular.css} />
          <text x={NODE_ROLE_PADDING} y={NODE_ROLE_HEIGHT / 2 + 4}>
            {node.role}
          </text>
        </g>
      )}

      <g className="balloon" style={{ transform: 'scale(0)' }}>
        <g transform={`translate(-10, -${BALLOON_RADIUS + 10})`}>
          <circle
            cx="0"
            cy="0"
            r={BALLOON_RADIUS}
            fill={COLORS.white.css}
            className="shadow"
          />
          <polygon
            points={`0,0 ${BALLOON_PIN_SIZE},0 ${
              BALLOON_PIN_SIZE / 2
            },${pythagorean(BALLOON_PIN_SIZE, BALLOON_PIN_SIZE / 2)}`}
            transform={`translate(-${BALLOON_PIN_SIZE / 2},${
              BALLOON_RADIUS - 5
            }) rotate(45, ${BALLOON_PIN_SIZE / 2}, ${-BALLOON_RADIUS - 5})`}
            fill={COLORS.white.css}
          />
          {notification && <Bell size={66} x={-32} y={-30} />}
          {editing && (
            <PencilIcon size={60} x={-30} y={-30} canAnimate={canAnimate} />
          )}
        </g>
      </g>
    </g>
  );
}

const offsetLink = (
  position: [number, number],
  direction: number
): [number, number] => {
  // makes the line start / end from the edge of the node
  return [position[0], position[1] + (NODE_HEIGHT / 2) * direction];
};

type HomeSplashDemoProps = React.HTMLAttributes<SVGElement> & {
  canAnimate: boolean;
};

function HomeSplashDemo({ canAnimate, ...props }: HomeSplashDemoProps) {
  const [current, setCurrent] = useState(null);

  const [editing, setEditing] = useState(null);
  const [notification, setNotification] = useState(null);

  const container = useRef(null);
  const animation = useRef(null);

  const links = useMemo(() => {
    return Object.entries(data.nodes).flatMap(([id, node]) => {
      return node.parent.map((p) => {
        const parentNode = data.nodes[p];
        return {
          id: `${id}-${parentNode.id}`,
          source: { node: id, position: offsetLink(node.position, -1) },
          target: {
            node: parentNode.id,
            position: offsetLink(parentNode.position, 1),
          },
        };
      });
    });
  }, []);

  const selectRandomNode = useCallback(() => {
    if (!current) {
      const keys = Object.keys(data.nodes).filter(
        (k) => data.nodes[k].type === NodeType.PERSON
      );
      const index = randomInt(0, keys.length - 1);

      setCurrent(keys[index]);
    }
  }, [current]);

  const createTimeline = useCallback(
    (id: string, timeline: anime.AnimeTimelineInstance) => {
      if (data.nodes[id].type !== NodeType.PERSON) {
        // sanity check
        return;
      }

      const node = `#node-${id}`;
      const balloon = container.current.querySelector(`#node-${id} .balloon`);

      // show balloon
      timeline.add(
        {
          targets: balloon,
          scale: 1,
          easing: 'easeOutElastic',
          duration: 1000,
          begin: () => {
            setEditing(id);
          },
        },
        '+=0'
      );

      // close balloon
      timeline.add(
        {
          targets: balloon,
          scale: 0,
          duration: 300,
          easing: 'easeInQuart',
          complete: () => {
            setEditing(null);
          },
        },
        '+=3000'
      );

      const requirementBox = container.current.querySelector(
        `${node} .requirementBox`
      );
      const status = requirementBox.getAttribute('data-success');

      // change requirement status
      const success = !(status === 'true');

      if (status === String(success)) {
        // requirement didn't change,
        // so the information shouldn't propagate to the next node
        return;
      }

      // change requirement box colour
      const reqSymbol = container.current.querySelector(
        `${node} .requirementBox .sign-moving`
      );

      const reqSymbolContainer = container.current.querySelector(
        `${node} .requirementBox .sign`
      );

      const reqBox = container.current.querySelector(
        `${node} .requirementBox .backgroundBox`
      );

      timeline.add({
        targets: reqSymbol,
        strokeDashoffset: success ? 0 : -16,
        strokeDasharray: success ? '6,28' : '13,21',
        easing: 'easeOutElastic',
        duration: 1000,
        begin: () => {
          requirementBox.setAttribute('data-success', String(success));
        },
      });
      timeline.add(
        {
          targets: reqSymbolContainer,
          translateX: success ? 0 : -2,
          easing: 'easeOutElastic',
          duration: 1000,
        },
        '-=1000'
      );
      timeline.add(
        {
          targets: reqBox,
          keyframes: [
            { scale: 1.2, easing: 'linear', duration: 100 },
            { scale: 1, easing: 'easeOutElastic', duration: 500 },
          ],
        },
        '-=1000'
      );

      const linksAvailable = links.filter((l) => l.source.node === id);
      const link =
        linksAvailable[
          linksAvailable.length === 1
            ? 0
            : randomInt(0, linksAvailable.length - 1)
        ];

      if (link) {
        const linkElement: SVGPathElement = document.querySelector(
          `.links #${link.id}`
        );
        if (linkElement) {
          const length = linkElement.getTotalLength();

          timeline.add(
            {
              targets: linkElement,
              strokeDasharray: `${length} ${length}`,
              opacity: 1,
              easing: 'linear',
              duration: 0,
            },
            '+=0'
          );

          // animate link between two nodes
          timeline.add(
            {
              targets: linkElement,
              strokeDashoffset: [length, -length],
              easing: 'easeInOutQuart',
              duration: 1500,
            },
            '+=0'
          );
        }

        // next node receives a notification
        const nextNodeBallon = container.current.querySelector(
          `#node-${link.target.node} .balloon`
        );

        timeline.add(
          {
            targets: nextNodeBallon,
            scale: 1,
            easing: 'easeOutElastic',
            duration: 1000,
            begin: () => {
              setNotification(link.target.node);
            },
          },
          '+=0'
        );

        // close balloon
        timeline.add(
          {
            targets: nextNodeBallon,
            scale: 0,
            duration: 300,
            easing: 'easeInQuart',
            complete: () => {
              setNotification(null);
            },
          },
          '+=1300'
        );

        // start again from the next node
        createTimeline(link.target.node, timeline);
      }
    },
    [links]
  );

  useEffect(() => {
    if (current) {
      if (animation.current && !animation.current.completed) {
        // if animation is still running, reset it
        // (this many occour in development)
        animation.current.pause();

        setNotification(null);
        setEditing(null);

        // reset all balloons
        const balloons = container.current.querySelectorAll(`.nodes .balloon`);
        anime.set(balloons, { scale: 0 });
      }

      animation.current = anime.timeline({
        autoplay: false,
        complete: () => {
          // callback completed
          setCurrent(null);
          setNotification(null);
          setEditing(null);
        },
      });

      createTimeline(current, animation.current);

      animation.current.play();
    } else {
      setTimeout(() => selectRandomNode(), 1000);
    }
  }, [current, selectRandomNode, createTimeline]);

  useEffect(() => {
    if (animation.current) {
      if (canAnimate) {
        animation.current.play();
      } else {
        animation.current.pause();
      }
    }
  }, [canAnimate]);

  useEffect(() => {
    return () => {
      // pause animation on component unmount
      animation.current.pause();
    };
  }, []);

  return (
    <SVG
      viewBox={`0,0,${data.settings.viewBox[0]},${data.settings.viewBox[1]}`}
      preserveAspectRatio="xMaxYMin meet"
      width="100%"
      height="100%"
      ref={container}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <defs>
        <linearGradient id="demo-mask-gradient" gradientTransform="rotate(90)">
          <stop offset="1%" stopColor="black" />
          <stop offset="20%" stopColor="white" />
          <stop offset="80%" stopColor="white" />
          <stop offset="99%" stopColor="black" />
        </linearGradient>

        <mask id="demo-mask">
          <rect
            x="0"
            y="0"
            width="100%"
            height="100%"
            fill="url(#demo-mask-gradient)"
          />
        </mask>

        <filter id="shadow" y="-500%" height="1000%" x="-500%" width="1000%">
          <feDropShadow
            dx="10"
            dy="10"
            stdDeviation="25"
            floodColor={COLORS.brand.regular.css}
            floodOpacity="0.1"
          />
        </filter>
        <pattern
          id="grid"
          x="0"
          y="0"
          width={data.settings.grid}
          height={data.settings.grid}
          patternUnits="userSpaceOnUse"
        >
          <circle
            cx={data.settings.grid / 2}
            cy={data.settings.grid / 2}
            r={data.settings.dotRadius}
            fill={COLORS.shades.s200.css}
          />
        </pattern>

        <clipPath id="avatarClip">
          <circle
            cx={AVATAR_RADIUS}
            cy={AVATAR_RADIUS}
            r={AVATAR_RADIUS - NODE_STROKE / 2}
          />
        </clipPath>

        <linearGradient id="fadeGradient" x1="0" x2="0" y1="0" y2="1">
          <stop offset="0%" stopColor={COLORS.white.css} stopOpacity="0" />
          <stop offset="49%" stopColor={COLORS.white.css} stopOpacity="1" />
        </linearGradient>
        <linearGradient id="fadeGradientInverse" x1="0" x2="0" y1="0" y2="1">
          <stop offset="51%" stopColor={COLORS.white.css} stopOpacity="1" />
          <stop offset="100%" stopColor={COLORS.white.css} stopOpacity="0" />
        </linearGradient>
      </defs>
      <rect x="0" y="0" width="100%" height="100%" fill="url(#grid)" />
      <ContentGroup>
        <g className="links" mask="url(#demo-mask)">
          {links.map((link) => {
            const d = getPathBezier(link.source.position, link.target.position);
            return (
              <g key={link.id}>
                <path
                  d={d}
                  stroke={COLORS.shades.s500.css}
                  fill="none"
                  strokeWidth={2}
                  strokeDasharray="5"
                />
                <path
                  id={link.id}
                  d={d}
                  stroke={COLORS.brand.regular.css}
                  fill="none"
                  strokeWidth={5}
                  style={{ opacity: 0 }}
                />
              </g>
            );
          })}
        </g>
        <g className="nodes">
          {Object.entries(data.nodes).map(([id, node]) => {
            if (node.type === NodeType.MODEL) {
              return <ModelNode node={node} key={id} />;
            }
            if (node.type === NodeType.PERSON) {
              return (
                <RequirementNode
                  node={node}
                  key={id}
                  id={`node-${id}`}
                  editing={editing === id}
                  notification={notification === id}
                  canAnimate={canAnimate}
                />
              );
            }
            return null;
          })}
        </g>
      </ContentGroup>
    </SVG>
  );
}

export default memo(HomeSplashDemo);
