import React, { memo } from 'react';
import styled from 'styled-components';

import type { BlockLogoAndTestimonialsFragment } from '../schemas/api';

import Heading from './Heading';

import useInview from '../hooks/useInview';

import TestimonialsCarousel from './TestimonialsCarousel';

import { Grid, columns } from '../styles/grid';

import { spacing } from '../styles/blocks';

type ContainerProps = {
  $spacing: string;
};

const Container = styled(Grid)<ContainerProps>`
  ${(props) => spacing(props.$spacing || '')}
`;

const TestimonialsContainer = styled.div`
  ${columns(1, 12)};
  display: flex;
  padding: 12px 0;

  @media (min-width: 768px) {
    ${columns(2, 10)};
  }
`;

type BlockTestimonialCarouselProps = {
  data: BlockLogoAndTestimonialsFragment;
};
function BlockTestimonialCarousel({ data }: BlockTestimonialCarouselProps) {
  const [inViewRef, inView] = useInview({});

  const testimonials = data.testimonials?.items;
  return (
    <Container $spacing={data.spacing}>
      <Heading
        heading={data.heading}
        show={inView}
        ref={inViewRef}
        headingLevel="h3"
      />
      {Array.isArray(testimonials) && !!testimonials.length && (
        <TestimonialsContainer>
          <TestimonialsCarousel items={testimonials} />
        </TestimonialsContainer>
      )}
    </Container>
  );
}

export default memo(BlockTestimonialCarousel);
