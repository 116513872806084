import React, { memo } from 'react';
import styled from 'styled-components';
import Image from './Image';

import type { BlockBenefitFragment, BenefitFragment } from '../schemas/api';

import useInview from '../hooks/useInview';

import { staggerChildrenFadeTranslateIn } from '../styles/animations';
import TYPO from '../styles/typography';
import COLORS from '../styles/colors';
import BREAKPOINTS from '../styles/breakpoints';
import { Grid, columns, grid } from '../styles/grid';

import * as textUtils from '../utils/text';

const Container = styled(Grid)`
  padding: 120px 12px;
  ${BREAKPOINTS.max.medium`
    padding: 72px 12px;
  `}
`;

const Inner = styled.div`
  ${columns(1, 12)}
  display: flex;
  flex-direction: column;
  gap: 36px;
  width: 100%;
`;

type ContentProps = { $show: boolean };
const Content = styled.div<ContentProps>`
  ${grid(12)}
  row-gap: 84px;
  ${BREAKPOINTS.max.medium`
    row-gap: 48px;
    `}
  ${BREAKPOINTS.max.small`
    row-gap: 16px;
    `}
  ${(props) => staggerChildrenFadeTranslateIn(props.$show, 0, undefined, 0.05)}
`;

const HeadingSection = styled.div<ContentProps>`
  text-align: left;
  display: flex;
  flex-direction: column;
  gap: 12px;
  ${(props) => staggerChildrenFadeTranslateIn(props.$show)}
`;

const Heading = styled.h1`
  ${TYPO.h2}
  display:flex;
`;

const Subheading = styled.div`
  ${TYPO.p1};
  color: ${COLORS.text.light.css};
  line-height: 148%;
  max-width: 820px;
  white-space: pre-wrap;
  & strong {
    color: ${COLORS.brand.regular.css};
    font-weight: inherit;
  }
`;

type BenefitProps = {
  $delay?: number;
  $show?: boolean;
};

const Benefit = styled.div<BenefitProps>`
  ${grid(4)}
  ${columns(null, 4)}
  opacity: ${(props) => (props.$show ? 1 : 0)};
  transition: opacity 0.33s ease;
  transition-delay: ${(props) => props.$delay * 0.1 + 0.4}s;

  ${BREAKPOINTS.max.medium`
    ${columns(null, 6)}
    `}
  ${BREAKPOINTS.max.small`
    ${columns(null, 12)}
    `}
`;

const BenefitIcon = styled.div`
  ${columns(null, 1)}
  display: flex;
  justify-content: flex-start;
  align-content: center;
  flex-direction: column;
  padding-top: 6px;
`;

const BenefitInner = styled.div`
  ${columns(null, 3)}
  display:flex;
  flex-direction: column;
  gap: 6px;
`;

const BenefitHeading = styled.div`
  ${TYPO.h3}
`;
const BenefitContent = styled.div`
  ${TYPO.p1}
  color:${COLORS.text.light.css};
  padding-right: 36px;
`;

type BlockImageGridLargeHeadingProps = {
  data: BlockBenefitFragment;
};

function CareersBenefits({ data }: BlockImageGridLargeHeadingProps) {
  const [inViewRef, inView] = useInview({});

  return (
    <Container>
      <Inner>
        <HeadingSection ref={inViewRef} $show={inView}>
          <Heading>{textUtils.highlight(data.heading)}</Heading>
          {data.byline && (
            <Subheading>{textUtils.highlight(data.byline)}</Subheading>
          )}
        </HeadingSection>
        <Content $show={inView}>
          {data.benefits.items.map((benefit: BenefitFragment) => (
            <Benefit key={benefit.heading}>
              <BenefitIcon>
                <Image
                  src={benefit.icon.url}
                  alt={benefit.heading}
                  height={benefit.icon.height}
                  width={benefit.icon.width}
                />
              </BenefitIcon>
              <BenefitInner>
                <BenefitHeading>{benefit.heading}</BenefitHeading>
                <BenefitContent>{benefit.content}</BenefitContent>
              </BenefitInner>
            </Benefit>
          ))}
        </Content>
      </Inner>
    </Container>
  );
}

export default memo(CareersBenefits);
