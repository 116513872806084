import React, { memo } from 'react';
import styled from 'styled-components';

import type { BlockBenefitFragment, BenefitFragment } from '../schemas/api';

import Icon, { IconName } from './Icon';
import Link from './Link';

import useInview from '../hooks/useInview';

import { staggerChildrenFadeTranslateIn } from '../styles/animations';
import TYPO from '../styles/typography';
import COLORS from '../styles/colors';
import BREAKPOINTS from '../styles/breakpoints';
import { Grid, columns } from '../styles/grid';

import * as textUtils from '../utils/text';

const Container = styled(Grid)`
  background: ${COLORS.shades.s500.opacity(0.04)};
  padding: 120px 0;
  ${BREAKPOINTS.max.medium`
    padding: 72px 0;
  `}
`;

const Content = styled.div`
  ${columns(1, 12)}
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
  ${BREAKPOINTS.max.extraSmall`
    flex-direction:column;
    align-items:center;
  `}
  padding: 0 24px;
`;

type HeadingSectionProps = {
  $show?: boolean;
};
const HeadingSection = styled.div<HeadingSectionProps>`
  ${columns(3, 8)}
  text-align: center;
  ${BREAKPOINTS.max.small`
  ${columns(1, 12)}
  `}
  margin-bottom: 28px;
  ${(props) => staggerChildrenFadeTranslateIn(props.$show)}
`;

const Heading = styled.h1`
  ${TYPO.h2}
`;

const Subheading = styled.div`
  ${TYPO.p1}
  text-align: center;
  max-width: 820px;
  color: ${COLORS.text.light.css};
`;

const Name = styled.div`
  width: 100%;
  position: absolute;
  bottom: 27px;
  text-align: center;
  ${TYPO.p1};
  font-size: 18px;
  color: ${COLORS.text.light.css};
`;

const IconInner = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const BenefitInner = styled.div`
  width: 190px;
  height: 190px;
  background: ${COLORS.white.css};
  border: 1px solid ${COLORS.shades.s250.css};
  border-radius: 5px;
  position: relative;
  transition: all 0.2s ease;
  transform: translateY(0px);
  ${BREAKPOINTS.max.extraSmall`
    width:100%;
    height:auto;
    display:flex;
    align-items: center;
    gap: 12px;
    & > div {
      position: static;
    }
    & ${Name} {
      text-align: left;
    }
    padding:18px;

  `}
`;

const IconColorsMapping = [
  COLORS.success,
  COLORS.brand,
  COLORS.error,
  COLORS.warn,
];

type BenefitProps = {
  $index: number;
  $show: boolean;
};

const Badge = styled.div<BenefitProps>`
  width: 18px;
  height: 18px;
  border-radius: 50%;
  position: absolute;
  right: -5px;
  bottom: -5px;
  background: red;
  border: 2px solid ${COLORS.white.css};
  display: flex;
  align-items: center;
  justify-content: center;
  ${TYPO.small};
  font-size: 8px;
  color: ${COLORS.white.css};

  transform: scale(${(props) => (props.$show ? 1 : 0)});
  background: ${(props) => IconColorsMapping[props.$index].regular.css};
  transition: transform 0.66s cubic-bezier(0.34, 1.56, 0.64, 1);
  transition-delay: ${(props) => props.$index * 0.1 + 0.8}s;
`;

const IconContainer = styled.div<BenefitProps>`
  position: absolute;
  right: 10px;
  top: 10px;
  width: 38px;
  height: 38px;
  border-style: solid;
  border-width: 2px;
  border-radius: 3px;

  background: ${(props) => IconColorsMapping[props.$index].light.css};
  border-color: ${(props) => IconColorsMapping[props.$index].regular.css};
  color: ${(props) => IconColorsMapping[props.$index].regular.css};

  opacity: ${(props) => (props.$show ? 1 : 0)};
  transform: scale(${(props) => (props.$show ? 1 : 0)});
  transition: all 0.33s ease;
  transition-delay: ${(props) => props.$index * 0.1 + 0.6}s;

  & svg {
    opacity: ${(props) => (props.$show ? 1 : 0)};
    transition: opacity 0.33s ease;
    transition-delay: ${(props) => props.$index * 0.1 + 0.8}s;
  }
`;

const Benefit = styled.div<BenefitProps>`
  opacity: ${(props) => (props.$show ? 1 : 0)};
  transition: opacity 0.33s ease;
  transition-delay: ${(props) => props.$index * 0.1 + 0.4}s;
  position: relative;

  & > a {
    width: 100%;
    height: 100%;
  }
  &:hover a ${BenefitInner} {
    border-color: ${COLORS.shades.s300.css};
    transform: translateY(-4px);
  }
  ${BREAKPOINTS.max.extraSmall`
    width:100%;
    max-width: 400px;
  `}
`;

type BenefitsIconsProps = {
  data: BlockBenefitFragment;
};
function BenefitsIcons({ data }: BenefitsIconsProps) {
  const [inViewRef, inView] = useInview({});
  const [inViewContentRef, inViewContent] = useInview({ once: false });
  return (
    <Container>
      <HeadingSection ref={inViewRef} $show={inView}>
        <Heading>{textUtils.highlight(data.heading)}</Heading>
        {data.byline && (
          <Subheading>{textUtils.highlight(data.byline)}</Subheading>
        )}
      </HeadingSection>
      <Content ref={inViewContentRef}>
        {data.benefits.items.map((benefit: BenefitFragment, i: number) => (
          <Benefit $show={inViewContent} $index={i} key={benefit.heading}>
            <Link link={benefit.link} key={benefit.heading}>
              <BenefitInner>
                <IconContainer $show={inViewContent} $index={i}>
                  <IconInner>
                    <Icon
                      name={benefit.iconName as IconName}
                      width={16}
                      height={16}
                    />
                    {typeof benefit.badge === 'number' && (
                      <Badge $show={inViewContent} $index={i}>
                        {benefit.badge > 0 && benefit.badge}
                      </Badge>
                    )}
                  </IconInner>
                </IconContainer>
                <Name>{benefit.heading}</Name>
              </BenefitInner>
            </Link>
          </Benefit>
        ))}
      </Content>
    </Container>
  );
}

export default memo(BenefitsIcons);
