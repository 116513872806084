/* eslint-disable react/no-array-index-key */
import React from 'react';
import styled, { css, keyframes } from 'styled-components';
import { easeOutQuad } from '../utils/easings';
import * as textUtils from '../utils/text';

import COLORS from '../styles/colors';

const animation = keyframes`
  0%{
    transform: translateY(150px);
    opacity: 0;
  }
  100%{
    transform: translateY(0);
    opacity: 1;
  }
`;

const Mask = styled.span`
  display: inline-block;
  clip-path: inset(0px 0px -10px 0px);
`;

type WordProps = {
  $highlight: boolean;
  $show: boolean;
};
const Word = styled.span<WordProps>`
  display: inline-block;
  opacity: 0;
  white-space: pre;
  color: ${(props) =>
    props.$highlight ? COLORS.brand.regular.css : 'inherit'};
  ${(props) =>
    props.$show &&
    css`
      animation: ${animation} 0.4s ease-out;
      animation-fill-mode: both;
    `};
`;

type TextAnimationProps = {
  text: string;
  show: boolean;
  duration?: number;
  splitBy?: string;
};

const TextAnimation = ({
  text,
  show,
  duration = 200,
  splitBy = ' ',
  ...props
}: TextAnimationProps) => {
  if (!text) {
    // sanity check
    return null;
  }

  const formatted = textUtils.replaceUnderscoreWithNBSP(text);
  const words = textUtils.findHighlightedWords(formatted, splitBy);

  return (
    <>
      {words.map((word, i) => (
        <Mask key={`${word}_${i}`} {...props}>
          <Word
            key={`${word}_${i}`}
            style={{
              animationDelay: `${duration * easeOutQuad(i / words.length)}ms`,
            }}
            $show={show}
            $highlight={word.highlighted}
          >
            {word.text}
            {splitBy}
          </Word>
          {i < words.length - 1 ? ' ' : null}
        </Mask>
      ))}
    </>
  );
};

export default TextAnimation;
