import styled, { keyframes } from 'styled-components';
import COLORS from './colors';

const SIZE = 12;
const STROKE_WIDTH = 1;
const COLOUR = COLORS.brand.regular.css;
const DURATION = 1;

const rotate = keyframes`
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
`;

type LoaderProps = {
  $size?: number;
  $color?: string;
  $strokeWidth?: number;
  $duration?: number;
};

const StyleOne = styled.div<LoaderProps>`
  width: ${(props) => props.$size || SIZE}px;
  height: ${(props) => props.$size || SIZE}px;
  border-width: ${(props) =>
    `${props.$strokeWidth || STROKE_WIDTH}px;
  border-style: solid;
  border-color: ${props.$color || COLOUR}`};
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  flex-shrink: 0;
  animation: ${rotate}
    ${(props) => (props.$duration ? props.$duration : DURATION)}s linear
    infinite;
`;

export default StyleOne;
