import React, { memo } from 'react';
import styled, { css } from 'styled-components';

import type { BlockContentFragment } from '../schemas/api';

import Markdown from './Markdown';

import useInview from '../hooks/useInview';

import { staggerChildrenFadeTranslateIn } from '../styles/animations';
import TYPO from '../styles/typography';
import BREAKPOINTS from '../styles/breakpoints';
import { Grid, columns } from '../styles/grid';

import * as textUtils from '../utils/text';
import { spacing } from '../styles/blocks';
import markdownStyles from '../styles/markdown';

type ContainerProps = {
  $spacing: string;
};

const Container = styled(Grid)<ContainerProps>`
  ${(props) => spacing(props.$spacing)};
`;

type ContentProps = {
  $align: string;
  $show: boolean;
};

const Content = styled.div<ContentProps>`
  padding: 0 12px;
  & p,
  & li {
    ${TYPO.p1}
  }
  & > div {
    ${markdownStyles}
  }

  ${(props) => staggerChildrenFadeTranslateIn(props.$show)};

  ${(props) => {
    if (props.$align?.toLowerCase() === 'left') {
      return css`
        ${columns(2, 8)}

        ${BREAKPOINTS.max.medium`
    ${columns(2, 10)}
    `}
  ${BREAKPOINTS.max.small`
    ${columns(1, 12)}
    `}
      `;
    }
    return css`
      ${columns(3, 8)}

      ${BREAKPOINTS.max.medium`
    ${columns(2, 10)}
    `}
  ${BREAKPOINTS.max.small`
    ${columns(1, 12)}
    `}
    `;
  }}
`;

const Heading = styled.h1`
  ${TYPO.h2}
  margin-bottom:24px;
`;

type ContentOneColumnProps = {
  data: BlockContentFragment;
};
function ContentOneColumn({ data }: ContentOneColumnProps) {
  const [inViewRef, inView] = useInview({});

  return (
    <Container $spacing={data.spacing}>
      <Content ref={inViewRef} $show={inView} $align={data.align}>
        {data.heading && <Heading>{textUtils.highlight(data.heading)}</Heading>}
        <Markdown content={data.content} />
      </Content>
    </Container>
  );
}

export default memo(ContentOneColumn);
