import React, { memo } from 'react';
import styled from 'styled-components';

import type { BlockCtaFragment } from '../schemas/api';

import Heading from './Heading';

import useInview from '../hooks/useInview';

import markdownStyles from '../styles/markdown';
import COLORS from '../styles/colors';
import BREAKPOINTS from '../styles/breakpoints';
import TYPO from '../styles/typography';

import { Grid, columns } from '../styles/grid';
import Markdown from './Markdown';
import CTAButtons from './CTAButtons';

const Container = styled(Grid)`
  margin-top: 60px;
  padding: 96px 12px;
  background: ${COLORS.section.background};
`;

const Content = styled.div`
  ${columns(3, 8)}
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  margin-bottom: 12px;

  ${markdownStyles}

  & p {
    color: ${COLORS.shades.s300.css};
    ${TYPO.p2}
  }

  ${BREAKPOINTS.max.small`
  ${columns(1, 12)};
  font-size:14px;
  `}
`;

type CtaDarkBlockProps = {
  className?: string;
  data: BlockCtaFragment;
};
function CtaDarkBlock({ className, data }: CtaDarkBlockProps) {
  const [inViewRef, inView] = useInview({});

  return (
    <Container className={className}>
      <Heading
        heading={data.heading}
        show={inView}
        ref={inViewRef}
        headingLevel="h2"
      />
      <Content>
        <Markdown content={data.byline} />
      </Content>
      <CTAButtons links={data.callToActions?.items} />
    </Container>
  );
}

export default memo(CtaDarkBlock);
