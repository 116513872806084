import React from 'react';
import styled from 'styled-components';
import { animated, useSpring } from '@react-spring/web';

import COLORS from '../colors';

type SVGProps = {
  $success?: boolean;
};
const SVG = styled.svg<SVGProps>`
  overflow: visible;
  & * {
    transform-box: fill-box;
  }

  & .backgroundBox {
    stroke: ${(props) =>
      props.$success ? COLORS.success.dark.css : COLORS.error.regular.css};
    fill: ${(props) =>
      props.$success ? COLORS.success.light.css : COLORS.error.light.css};
  }
  & .sign {
    stroke: ${(props) =>
      props.$success ? COLORS.success.dark.css : COLORS.error.regular.css};
  }
`;

interface RequirementBoxProps extends React.HTMLAttributes<SVGElement> {
  size?: number;
  x?: number;
  y?: number;
  strokeWidth?: number;
  success?: boolean;
}

const CROSS = (size: number) =>
  `M0 ${size * 1.5} L0 ${size * 0.5} Q0 0 ${size * 0.5} 0 Q${size} 0 ${size} ${
    size * 0.5
  } L${size} ${size * 2.5}`;
const CROSS_LINE = (size: number) =>
  `M0 ${size * 1.5} L${size * 2} ${size * 1.5}`;

const RequirementBox = ({
  size = 36,
  success,
  strokeWidth = 2,
  ...props
}: RequirementBoxProps) => {
  const { strokeDashoffset, strokeDasharray, x } = useSpring({
    strokeDashoffset: success ? 0 : -16,
    strokeDasharray: success ? '6,28' : '13,21',
    x: success ? 0 : -2,
    config: { tension: 210, friction: 20 },
  });

  return (
    <SVG
      width={size}
      height={size}
      className="requirementBox"
      viewBox={`0 0 28 28`}
      $success={success}
      {...props}
    >
      <rect
        width="28"
        height="28"
        rx={3}
        x={0}
        y={0}
        strokeWidth={strokeWidth}
        className="backgroundBox"
      />
      <animated.g className="sign" style={{ x }}>
        <g transform={`translate(14,14)`}>
          <g transform={`translate(-9, -2)`}>
            <g transform={`rotate(-45)`}>
              <animated.path
                style={{ strokeDashoffset, strokeDasharray }}
                d={CROSS(6)}
                fill="none"
                strokeWidth={strokeWidth}
                strokeLinecap="round"
                strokeDasharray="6,28"
                className="sign-moving"
              />
              <path
                d={CROSS_LINE(6)}
                fill="none"
                strokeWidth={strokeWidth}
                strokeLinecap="round"
              />
            </g>
          </g>
        </g>
      </animated.g>
    </SVG>
  );
};

export default RequirementBox;
