import React, { useRef, useCallback, useEffect, MutableRefObject } from 'react';

import useAnimationFrame from '../hooks/useAnimationFrame';
import COLORS, { Color } from '../styles/colors';
import { clamp, randomInt } from '../utils/math';

const SIZE = 700;

const WAIT = 1;

const PARTICLE_ALPHA = 1;
const FLEN = 320; // represents the distance from the viewer to z=0 depth.
// we will not draw coordinates if they have too large of a z-coordinate (which means they are very close to the observer).
const ZMAX = FLEN - 2;
const NUM_TO_ADD_EACH_FRAME = 8;

// random acceleration factors - causes some random motion
const RAND_ACCEL_X = 0.1;
const RAND_ACCEL_Y = 0.1;
const RAND_ACCEL_Z = 0.1;

// particle speed from center of sphere
const PARTICLE_SPEED_X = 0.2;
const PARTICLE_SPEED_Y = 0.2;
const PARTICLE_SPEED_Z = 0.2;

// try changing to a positive number (not too large, for example 0.3), or negative for floating upwards.
const GRAVITY = 0;

const PARTICLE_RADIUS = 1.5;

const SPHERE_RADIUS_MARGIN = 50;
const SPHERE_RADIUS_MARGIN_ACTIVE = 70;

// position of the sphere [0,1] relative to the canvas width/height
const SPHERE_POS_X = 0.5;
const SPHERE_POS_Y = 0.5;

const SPHERE_CENTER_X = 0;
const SPHERE_CENTER_Y = 0;
const SPHERE_CENTER_Z = -3;

// alpha values will lessen as particles move further back, causing depth-based darkening:
const ZERO_ALPHA_DEPTH = -800;

const TURN_SPEED = (2 * Math.PI) / 9000; // the sphere will rotate at this speed (one complete rotation every 1600 frames).
const TURN_SPEED_ACTIVE = (2 * Math.PI) / 600;

type Particle = {
  x: number;
  y: number;
  z: number;
  velX: number;
  velY: number;
  velZ: number;
  age: number;
  dead: boolean;
  color: Color;

  projX?: number;
  projY?: number;
  alpha?: number;

  attack?: number;
  hold?: number;
  decay?: number;
  initValue?: number;
  holdValue?: number;
  lastValue?: number;

  stuckTime?: number;
  accelX?: number;
  accelY?: number;
  accelZ?: number;

  prev?: Particle | null;
  next?: Particle | null;
};

type Controller = {
  particleList: { first?: Particle };
  recycleBin: { first?: Particle };
  turnAngle: number;
  count: number;
  outsideTest: boolean;
  nextParticle: Particle | undefined;
};

type AnimationAiProps = {
  active?: MutableRefObject<boolean>;
  background: Color;
};

export default function AnimationAi({ active, background }: AnimationAiProps) {
  const ref = useRef<null | HTMLCanvasElement>(null);
  const controller = useRef<Controller>({
    particleList: {},
    recycleBin: {},
    turnAngle: 0,
    count: WAIT - 1,
    outsideTest: false,
    nextParticle: undefined,
  });

  const addParticle = useCallback(
    (
      x0: number,
      y0: number,
      z0: number,
      vx0: number,
      vy0: number,
      vz0: number
    ): Particle => {
      // remove from particleList
      let newParticle: Particle | undefined;
      const color = COLORS.brand.regular;

      // check recycle bin for available drop:
      if (controller.current.recycleBin.first != null) {
        newParticle = controller.current.recycleBin.first;
        // remove from bin
        if (newParticle.next != null) {
          controller.current.recycleBin.first = newParticle.next;
          newParticle.next.prev = null;
        } else {
          controller.current.recycleBin.first = null;
        }
      }
      // if the recycle bin is empty, create a new particle (a new ampty object):
      else {
        newParticle = {
          x: x0,
          y: y0,
          z: z0,
          velX: vx0,
          velY: vy0,
          velZ: vz0,
          age: 0,
          dead: false,
          color,
        };
      }

      // add to beginning of particle list
      if (controller.current.particleList.first == null) {
        controller.current.particleList.first = newParticle;
        newParticle.prev = null;
        newParticle.next = null;
      } else {
        newParticle.next = controller.current.particleList.first;
        controller.current.particleList.first.prev = newParticle;
        controller.current.particleList.first = newParticle;
        newParticle.prev = null;
      }

      // initialize
      newParticle.x = x0;
      newParticle.y = y0;
      newParticle.z = z0;
      newParticle.velX = vx0;
      newParticle.velY = vy0;
      newParticle.velZ = vz0;
      newParticle.age = 0;
      newParticle.dead = false;
      newParticle.color = color;

      return newParticle;
    },
    []
  );

  /* eslint-disable no-param-reassign */
  const recycle = useCallback((p: Particle) => {
    // remove from particleList
    if (controller.current.particleList.first === p) {
      if (p.next != null) {
        p.next.prev = null;
        controller.current.particleList.first = p.next;
      } else {
        controller.current.particleList.first = null;
      }
    } else if (p.next == null) {
      p.prev.next = null;
    } else {
      p.prev.next = p.next;
      p.next.prev = p.prev;
    }
    // add to recycle bin
    if (controller.current.recycleBin.first == null) {
      controller.current.recycleBin.first = p;
      p.prev = null;
      p.next = null;
    } else {
      p.next = controller.current.recycleBin.first;
      controller.current.recycleBin.first.prev = p;
      controller.current.recycleBin.first = p;
      p.prev = null;
    }
  }, []);
  /* eslint-enable no-param-reassign */

  useAnimationFrame(() => {
    if (!ref.current) return;

    const { width, height } = ref.current;
    const context = ref.current?.getContext('2d');

    const margin = active.current
      ? SPHERE_RADIUS_MARGIN_ACTIVE
      : SPHERE_RADIUS_MARGIN;
    const sphereRadius = Math.min(width, height) - margin * 2;
    const sphereCenterZ = SPHERE_CENTER_Z - sphereRadius;

    // if enough time has elapsed, we will add new particles.
    controller.current.count += 1;
    if (controller.current.count >= WAIT) {
      controller.current.count = 0;

      for (let i = 0; i < NUM_TO_ADD_EACH_FRAME; i += 1) {
        const theta = Math.random() * 2 * Math.PI;
        const phi = Math.acos(Math.random() * 2 - 1);
        const x0 = sphereRadius * Math.sin(phi) * Math.cos(theta);
        const y0 = sphereRadius * Math.sin(phi) * Math.sin(theta);
        const z0 = sphereRadius * Math.cos(phi);

        // We use the addParticle function to add a new particle. The parameters set the position and velocity components.
        // Note that the velocity parameters will cause the particle to initially fly outwards away from the sphere center (after
        // it becomes unstuck).
        const p = addParticle(
          SPHERE_CENTER_X + x0,
          SPHERE_CENTER_Y + y0,
          sphereCenterZ + z0,
          0.002 * x0,
          0.002 * y0,
          0.002 * z0
        );

        // we set some "envelope" parameters which will control the evolving alpha of the particles.
        p.attack = 50;
        p.hold = 50;
        p.decay = 160;
        p.initValue = 0;
        p.holdValue = PARTICLE_ALPHA;
        p.lastValue = 0;

        // the particle will be stuck in one place until this time has elapsed:
        p.stuckTime = 80 + Math.random() * 20;

        p.accelX = 0;
        p.accelY = GRAVITY;
        p.accelZ = 0;
      }
    }

    const speed = active.current ? TURN_SPEED_ACTIVE : TURN_SPEED;
    // update viewing angle
    controller.current.turnAngle =
      (controller.current.turnAngle + speed) % (2 * Math.PI);
    const sinAngle = Math.sin(controller.current.turnAngle);
    const cosAngle = Math.cos(controller.current.turnAngle);

    // Draw over the whole canvas to create the trail effect
    context.fillStyle = active.current
      ? background.opacity(0.8)
      : background.rgb;

    context.fillRect(0, 0, width, height);

    // update and draw particles
    let p = controller.current.particleList.first;
    while (p != null) {
      // before list is altered record next particle
      controller.current.nextParticle = p.next;

      // update age
      p.age += 1;

      // if the particle is past its "stuck" time, it will begin to move.
      if (p.age > p.stuckTime) {
        const r = 0.5;
        p.velX += p.accelX + RAND_ACCEL_X * randomInt(-r, r);
        p.velY += p.accelY + RAND_ACCEL_Y * randomInt(-r, r);
        p.velZ += p.accelZ + RAND_ACCEL_Z * randomInt(-r, r);

        const multiplier = active.current ? 2 : 1;
        p.x += p.velX * PARTICLE_SPEED_X * multiplier;
        p.y += p.velY * PARTICLE_SPEED_Y * multiplier;
        p.z += p.velZ * PARTICLE_SPEED_Z * multiplier;
      }

      /*
			We are doing two things here to calculate display coordinates.
			The whole display is being rotated around a vertical axis, so we first calculate rotated coordinates for
			x and z (but the y coordinate will not change).
			Then, we take the new coordinates (rotX, y, rotZ), and project these onto the 2D view plane.
			*/
      const rotX = cosAngle * p.x + sinAngle * (p.z - sphereCenterZ);
      const rotZ =
        -sinAngle * p.x + cosAngle * (p.z - sphereCenterZ) + sphereCenterZ;

      const m = FLEN / (FLEN - rotZ);

      p.projX = rotX * m + width * SPHERE_POS_X;
      p.projY = p.y * m + height * SPHERE_POS_Y;

      // update alpha according to envelope parameters.
      if (p.age < p.attack + p.hold + p.decay) {
        if (p.age < p.attack) {
          p.alpha =
            ((p.holdValue - p.initValue) / p.attack) * p.age + p.initValue;
        } else if (p.age < p.attack + p.hold) {
          p.alpha = p.holdValue;
        } else if (p.age < p.attack + p.hold + p.decay) {
          p.alpha =
            ((p.lastValue - p.holdValue) / p.decay) *
              (p.age - p.attack - p.hold) +
            p.holdValue;
        }
      } else {
        p.dead = true;
      }

      // see if the particle is still within the viewable range.
      if (
        p.projX > width ||
        p.projX < 0 ||
        p.projY < 0 ||
        p.projY > height ||
        rotZ > ZMAX
      ) {
        controller.current.outsideTest = true;
      } else {
        controller.current.outsideTest = false;
      }

      if (controller.current.outsideTest || p.dead) {
        recycle(p);
      } else {
        // depth-dependent darkening
        const depthAlphaFactor = clamp(1 - rotZ / ZERO_ALPHA_DEPTH, 0, 1);
        context.fillStyle = p.color.opacity(depthAlphaFactor * p.alpha);

        // draw
        context.beginPath();
        context.arc(
          p.projX,
          p.projY,
          m * PARTICLE_RADIUS,
          0,
          2 * Math.PI,
          false
        );
        context.closePath();
        context.fill();
      }

      p = controller.current.nextParticle;
    }
  });

  useEffect(() => {
    const listener = () => {
      const { offsetWidth, offsetHeight } = ref.current;

      ref.current.width =
        offsetWidth >= offsetHeight
          ? SIZE
          : (offsetWidth * SIZE) / offsetHeight;
      ref.current.height =
        offsetWidth >= offsetHeight
          ? (offsetHeight * SIZE) / offsetWidth
          : SIZE;
    };
    listener();
    window.addEventListener('resize', listener);
    return () => window.removeEventListener('resize', listener);
  }, []);

  return <canvas ref={ref} style={{ width: '100%', height: '100%' }} />;
}
