import React, { memo } from 'react';
import styled from 'styled-components';

import useInview from '../hooks/useInview';

import CtaButton from './CTAButton';

import { staggerChildrenFadeTranslateIn } from '../styles/animations';
import TYPO from '../styles/typography';
import COLORS from '../styles/colors';
import BREAKPOINTS from '../styles/breakpoints';

import HomeSplashDemo from './HomeSplashDemo';

import { Grid, columns } from '../styles/grid';
import * as textUtils from '../utils/text';

const Container = styled.div`
  position: relative;
  height: 100vh;
  width: 100%;
  min-height: 700px;
  overflow: hidden;

  ${BREAKPOINTS.max.ipadPro`
    position: relative;
    width: 100%;
    height: 100%;
    display:flex;
    flex-direction: column;
  `}
`;

const GridContainer = styled(Grid)`
  display: flex;
  height: 100%;
`;

type ContentProps = { $show: boolean };

const Content = styled.div<ContentProps>`
  ${columns(1, 12)}
  display: flex;
  padding-top: 18%;
  flex-direction: column;
  max-width: 33vw;
  ${(props) => staggerChildrenFadeTranslateIn(props.$show)}
  ${BREAKPOINTS.max.ipadPro`
    position: relative;
    padding-top: 60px;
    order:0;
    ${columns(1, 7)}
    max-width: none;
  `}
  ${BREAKPOINTS.max.ipad`
    ${columns(1, 9)}
  `}
  ${BREAKPOINTS.max.small`
    ${columns(1, 12)}
  `}
`;

const Subtitle = styled.div`
  ${TYPO.subheading}
  font-size: 16px;
  color: ${COLORS.black.css};
  margin-top: 18px;
  max-width: 450px;
`;

const Title = styled.h1`
  ${TYPO.h1}
`;

const ButtonRow = styled.div`
  margin-top: 30px;
`;

const DemoContainer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  ${BREAKPOINTS.max.ipadPro`
    position: relative;
    height: 80vw;
    order:1
  `}

  ${BREAKPOINTS.max.ipad`
    height: 80vw;
  `}
`;

type HomeSplashRequirementsProps = {
  copy: Record<string, string>;
};
function HomeSplashRequirements({ copy }: HomeSplashRequirementsProps) {
  const [inViewRef, inView] = useInview({});
  const [inViewDemoRef, inViewDemo] = useInview({ once: false });

  return (
    <Container ref={inViewRef}>
      <DemoContainer ref={inViewDemoRef}>
        <HomeSplashDemo canAnimate={inViewDemo} />
      </DemoContainer>
      <GridContainer>
        <Content $show={inView}>
          <Title>{textUtils.highlight(copy.title)}</Title>
          <Subtitle>{copy.subtitle}</Subtitle>
          <ButtonRow>
            <CtaButton />
          </ButtonRow>
        </Content>
      </GridContainer>
    </Container>
  );
}

export default memo(HomeSplashRequirements);
