import React, { ForwardedRef, forwardRef } from 'react';
import styled from 'styled-components';
import { format } from 'date-fns';
import ReactMarkdown from 'react-markdown';

import Link, { LinkProps } from 'next/link';
import { BlogPostFragment } from '../schemas/api';

import MarkdownStyle from '../styles/markdown';
import BREAKPOINTS from '../styles/breakpoints';
import { columns } from '../styles/grid';
import COLORS from '../styles/colors';
import TYPO from '../styles/typography';

const PostInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const Metadata = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  max-width: 800px;
`;

const Whitepaper = styled.div`
  width: 100%;
  height: 250px;
  position: relative;
  background: ${COLORS.shades.s200.css};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
  background: radial-gradient(rgba(0, 0, 0, 0.2) 1px, transparent 1px),
    ${COLORS.shades.s200.css};
  background-size: 24px 24px;
  & > img {
    height: 100%;
    margin: 0 auto;
    border: 1px solid ${COLORS.white.css};
    border-radius: 3px;
    box-shadow: 6px 6px 8px ${COLORS.black.opacity(0.1)};
  }
`;

type ImagePreviewProps = {
  $src: string;
};

const ImagePreview = styled.div<ImagePreviewProps>`
  background-image: ${(props) => `url('${props.$src}')`};
  width: 100%;
  height: 250px;
  border-radius: 6px;
  background-size: cover;
  background-position: center;
  position: relative;
`;

const Title = styled.div`
  ${TYPO.h3}
`;

const Text = styled.div`
  ${TYPO.p1};
  font-size: 14px;

  ${MarkdownStyle}
  & > p {
    line-height: 140%;
  }
`;

const Footer = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
`;

const Author = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
  ${TYPO.p2};
  font-weight: 600;
`;

const Picture = styled.div<ImagePreviewProps>`
  background-image: ${(props) => `url('${props.$src}')`};
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-size: cover;
`;

const DateContainer = styled.div`
  color: ${COLORS.shades.s300.css};
  ${TYPO.footer}
`;

const Tag = styled.div`
  ${TYPO.strong};
  font-size: 12px;
  padding: 1px 6px;
  align-self: flex-start;
  border-radius: 2px;
  background: ${COLORS.brand.light.css};
`;

const Item = styled.a`
  ${columns(null, 6)};
  display: flex;
  flex-direction: column;
  gap: 12px;
  cursor: pointer;
  box-shadow: 0 0 20px ${COLORS.brand.regular.opacity(0.05)};
  ${BREAKPOINTS.max.medium`
     ${columns(1, 12)};
  `}

  border-width:1px;
  border-style: solid;
  border-color: transparent;
  border-radius: 6px;
  padding: 12px;
  &:hover {
    border-color: ${COLORS.brand.light.css};
    ${ImagePreview} {
      opacity: 0.9;
    }
    ${Whitepaper} {
      opacity: 0.9;
    }
  }
`;

const parseTag = (type?: string) => {
  if (!type) return type;
  switch (type.toLowerCase()) {
    case 'post':
      return 'Article';
    case 'whitepaper':
      return 'Guide';
    default:
      return type;
  }
};

type BlogPostPreviewProps = {
  post: BlogPostFragment;
  href: LinkProps['href'];
};

function BlogPostPreview(
  { post, href }: BlogPostPreviewProps,
  ref: ForwardedRef<HTMLAnchorElement>
) {
  return (
    <Link href={href} passHref legacyBehavior>
      <Item key={post.sys.id} ref={ref}>
        <ImagePreview $src={post.previewImage?.url || post.image.url} />
        <PostInfo>
          <Metadata>
            <Tag>{parseTag(post.type)}</Tag>
            <Title>{post.title}</Title>
            <Text>
              <ReactMarkdown linkTarget="_blank">{post.short}</ReactMarkdown>
            </Text>
          </Metadata>
          <Footer>
            {post.author && (
              <Author>
                <Picture $src={post.author.picture.url} />
                <span>{post.author.name}</span>
              </Author>
            )}{' '}
            <DateContainer>
              {format(
                new Date(post.publicationDate || post.sys.publishedAt),
                'MMM dd, yyyy'
              )}
            </DateContainer>
          </Footer>
        </PostInfo>
      </Item>
    </Link>
  );
}

export default forwardRef<HTMLAnchorElement, BlogPostPreviewProps>(
  BlogPostPreview
);
