import Excel from '../assets/softwares/excel-logo.svg';
import Python from '../assets/softwares/python.svg';

import User1 from '../assets/images/user-1.jpg';
import User2 from '../assets/images/user-2.jpg';
import User3 from '../assets/images/user-3.jpg';
import User4 from '../assets/images/user-4.jpg';
import User5 from '../assets/images/user-5.jpg';
import User6 from '../assets/images/user-6.jpg';
import User7 from '../assets/images/user-7.jpg';

type Strong = { type: 'strong'; data: string };

type Text = { type: 'text'; data: string };

type Integration = { type: 'integration'; logo: string; data: string };

type Requirement = {
  type: 'requirement';
  logo: string;
  success: boolean;
  id: string;
  data: string;
};

type Content = Requirement | Integration | Text | Strong;

type Item = { id: number; user: string; content: Content[]; comment?: string };

type Props = {
  size: [number, number];
  items: Item[];
};

const data: Props = {
  size: [480, 500],
  items: [
    {
      id: 0,
      user: User1.src,
      content: [
        { type: 'strong', data: 'Tom Burns' },
        { type: 'text', data: 'added a comment' },
      ],
      comment: `Can we revise the test spec **@Peter** to include tensile testing of a material sample to help with final sign-off?`,
    },
    {
      id: 1,
      user: User2.src,
      content: [
        { type: 'strong', data: 'Tiffany Gonzales' },
        { type: 'text', data: ' assigned ' },
        { type: 'strong', data: 'Paul Smith' },
        { type: 'text', data: ' as the owner of a requirement' },
      ],
    },
    {
      id: 2,
      user: User4.src,
      content: [
        { type: 'strong', data: 'Hallie Serrano' },
        { type: 'text', data: ' created v3 of ' },
        { type: 'integration', logo: Excel, data: 'Deflection Calcs.xls' },
      ],
    },
    {
      id: 3,
      user: User5.src,
      content: [
        { type: 'strong', data: 'Hector White' },
        { type: 'text', data: ' verified ' },
        {
          type: 'requirement',
          logo: Python,
          success: true,
          id: 'REQ-101',
          data: 'Total Mass',
        },
        { type: 'text', data: ' by linking CAD data ' },
      ],
    },
    {
      id: 4,
      user: User1.src,
      content: [
        { type: 'strong', data: 'Tom Burns' },
        { type: 'text', data: ' approved a change to a requirement ' },
      ],
      comment: `The torsional rigidity shall be at least **27,000** Nm/deg`,
    },
    {
      id: 5,
      user: User6.src,
      content: [
        { type: 'strong', data: 'Osman Merritt' },
        { type: 'text', data: 'added a comment' },
      ],
      comment: `**@Emma** are the sub-system requirements fully decomposed from this level downwards?`,
    },
    {
      id: 6,
      user: User3.src,
      content: [
        { type: 'strong', data: 'Emma Smith' },
        { type: 'text', data: ' assigned ' },
        { type: 'strong', data: 'Paul Smith' },
        { type: 'text', data: ' as the owner of a requirement' },
      ],
    },
    {
      id: 7,
      user: User7.src,
      content: [
        { type: 'strong', data: 'Peter Ford' },
        { type: 'text', data: ' created v2 of ' },
        { type: 'integration', logo: Python, data: 'Deflection Calcs.xls' },
      ],
    },
    {
      id: 8,
      user: User3.src,
      content: [
        { type: 'strong', data: 'Emma Smith' },
        { type: 'text', data: ' verified ' },
        {
          type: 'requirement',
          logo: Excel,
          success: true,
          id: 'REQ-120',
          data: 'Max Deflection',
        },
        { type: 'text', data: ' by linking Simulation data' },
      ],
    },
    {
      id: 9,
      user: User6.src,
      content: [
        { type: 'strong', data: 'Osman Merritt' },
        { type: 'text', data: ' reviewed a change to a requirement' },
      ],
      comment: `The mass flow rate of coolant shall be at least **3.2** kg/sec`,
    },
  ],
};

export default data;
