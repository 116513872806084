import React, { memo, useState } from 'react';
import styled, { css } from 'styled-components';
import { useTrail, animated, useSpring } from '@react-spring/web';
import type { BlockWithWithoutFlowFragment } from '../schemas/api';

import Heading from './Heading';

import useInview from '../hooks/useInview';

import TYPO from '../styles/typography';
import BREAKPOINTS from '../styles/breakpoints';
import { Grid, columns } from '../styles/grid';
import COLORS from '../styles/colors';

import Icon from './Icon';
import Image from './Image';

const Container = styled(Grid)`
  margin-top: 48px;
  padding: 108px 12px;
  ${BREAKPOINTS.max.medium`
  padding: 80px 12px;
`}
  position: relative;
`;

const Content = styled(Grid)`
  ${columns(1, 12)}
  margin-top: 24px;

  & > div {
    align-content: center;
  }
`;

const List = styled.div`
  ${columns(1, 4)};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 12px;

  ${BREAKPOINTS.max.small`
  ${columns(1, 12)};
  align-items: center;
`}

  ${BREAKPOINTS.max.extraSmall`
gap:9px;
`}
`;

const ListItem = styled(animated.div)`
  display: flex;
  gap: 12px;
  padding: 12px;
  ${TYPO.p2}
  font-weight:500;
  border: 1px solid ${COLORS.shades.s200.css};
  border-radius: 9px;
  align-items: center;
  ${BREAKPOINTS.max.extraSmall`
   font-size:12px;
   padding:9px;
`}
`;

const ImageContainer = styled(animated.div)`
  ${columns(5, 8)};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 24px 0;
  & img {
    width: 100%;
    height: auto;
  }
  ${BREAKPOINTS.max.small`
  ${columns(1, 12)};
`}
`;

const ToggleContainer = styled.div`
  ${columns(1, 12)};
  display: flex;
  justify-content: center;
`;

const Toggle = styled.div`
  border: 1px solid ${COLORS.brand.regular.css};
  border-radius: 3px;
  display: flex;
  gap: 3px;
  padding: 3px;
`;

type ToggleItemProps = {
  $active: boolean;
};

const ToggleItem = styled.button<ToggleItemProps>`
  display: flex;
  gap: 6px;
  border-radius: 3px;
  padding: 9px 18px;
  border: 1px solid transparent;
  ${(props) => {
    if (props.$active) {
      return css`
        background: ${COLORS.brand.regular.css};
        color: white;
      `;
    }
    return css`
      background: transparent;
      color: ${COLORS.black.css};
      &:hover {
        background: ${COLORS.shades.s100.css};
        border-color: ${COLORS.shades.s200.css};
      }
    `;
  }}
`;

type DisplayContentProps = {
  list: string[];
  image: { url?: string; width?: number; height?: number; title?: string };
  success: boolean;
};

const DisplayContent = ({ list, image, success }: DisplayContentProps) => {
  const [trails] = useTrail(
    list.length,
    () => ({
      from: { x: -50, opacity: 0 },
      to: { x: 0, opacity: 1 },
      reset: true,
    }),
    [list]
  );

  const [props] = useSpring(
    () => ({
      from: { x: 50, opacity: 0 },
      to: { x: 0, opacity: 1 },
      reset: true,
    }),
    [image]
  );

  return (
    <Content>
      <List>
        {trails.map((props, i) => {
          const d = list[i];
          return (
            <ListItem key={d} style={props}>
              <Icon
                name={success ? 'Check' : 'X'}
                width={12}
                height={12}
                style={{
                  color: success
                    ? COLORS.success.regular.css
                    : COLORS.error.regular.css,
                }}
              />
              <label>{d}</label>
            </ListItem>
          );
        })}
      </List>
      <ImageContainer style={props}>
        <Image
          src={image.url}
          alt={image.title}
          width={image.width}
          height={image.height}
          priority={true}
        />
      </ImageContainer>
    </Content>
  );
};

type ContentOneColumnProps = {
  data: BlockWithWithoutFlowFragment;
};
function BlockWithWithoutFlow({ data }: ContentOneColumnProps) {
  const [withFlow, setWithFlow] = useState(false);
  const [inViewRef, inView] = useInview({});

  return (
    <Container>
      <Heading heading={data.heading} show={inView} ref={inViewRef} line />
      <ToggleContainer>
        <Toggle>
          <ToggleItem $active={!withFlow} onClick={() => setWithFlow(false)}>
            {data.withoutFlowLabel}
          </ToggleItem>
          <ToggleItem $active={withFlow} onClick={() => setWithFlow(true)}>
            {data.withFlowLabel}
          </ToggleItem>
        </Toggle>
      </ToggleContainer>
      <DisplayContent
        image={withFlow ? data.withFlowVisual : data.withoutFlowVisual}
        list={withFlow ? data.withFlowContent : data.withoutFlowContent}
        success={withFlow}
        key={withFlow.toString()}
      />
    </Container>
  );
}

export default memo(BlockWithWithoutFlow);
