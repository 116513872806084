export class ErrorApi extends Error {
  api: boolean;

  constructor(message: string) {
    super(message);
    this.api = true;

    // Set the prototype explicitly.
    Object.setPrototypeOf(this, ErrorApi.prototype);
  }
}
