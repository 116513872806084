import ENDPOINTS from '../../endpoints.json';

export const scrollIntoViewWithOffset = (selector: string, offset: number) => {
  const element = document.querySelector(selector);
  if (!element) return;

  window.scrollTo({
    behavior: 'smooth',
    top:
      element.getBoundingClientRect().top -
      document.body.getBoundingClientRect().top -
      offset,
  });
};

// ts-unused-exports:disable-next-line
export const downloadFile = (blob: Blob, filename: string) => {
  const blobURL =
    window.URL && window.URL.createObjectURL
      ? window.URL.createObjectURL(blob)
      : window.webkitURL.createObjectURL(blob);

  const tempLink = document.createElement('a');
  tempLink.style.display = 'none';
  tempLink.href = blobURL;
  tempLink.setAttribute('download', filename);

  // Safari thinks _blank anchor are pop ups. We only want to set _blank
  // target if the browser does not support the HTML5 download attribute.
  // This allows you to download files in desktop safari if pop up blocking
  // is enabled.
  if (typeof tempLink.download === 'undefined') {
    tempLink.setAttribute('target', '_blank');
  }

  document.body.appendChild(tempLink);
  tempLink.click();

  // Fixes "webkit blob resource error 1"
  setTimeout(() => {
    document.body.removeChild(tempLink);
    window.URL.revokeObjectURL(blobURL);
  }, 200);
};

export const getEnvironment = (): 'production' | 'staging' | 'local' => {
  if (window.location.href.includes(ENDPOINTS.website.production)) {
    return 'production';
  }
  if (window.location.href.includes(ENDPOINTS.website.staging)) {
    return 'staging';
  }
  return 'local';
};
