import React, { memo, useEffect, useState } from 'react';
import styled from 'styled-components';

import type { BlockJobOpeningsFragment } from '../schemas/api';

import Link from './Link';
import Icon from './Icon';

import useInview from '../hooks/useInview';

import { staggerChildrenFadeTranslateIn } from '../styles/animations';
import TYPO from '../styles/typography';
import COLORS from '../styles/colors';
import { Grid, columns } from '../styles/grid';
import BREAKPOINTS from '../styles/breakpoints';

import * as textUtils from '../utils/text';
import getJobs, { Job } from '../utils/jobs';

const Container = styled(Grid)`
  padding: 120px 12px;
  ${BREAKPOINTS.max.medium`
    padding: 72px 12px;
  `}
`;

const Inner = styled.div`
  ${columns(1, 12)}
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
`;

const HeadingSection = styled.div<ContentProps>`
  display: flex;
  flex-direction: column;
  gap: 12px;
  text-align: left;
  ${(props) => staggerChildrenFadeTranslateIn(props.$show)}
`;

const Heading = styled.h1`
  ${TYPO.h2}
  display:flex;
`;

const Subheading = styled.div`
  ${TYPO.subheading};
  color: ${COLORS.text.light.css};
  line-height: 148%;
  max-width: 820px;
  white-space: pre-wrap;
  & strong {
    color: ${COLORS.brand.regular.css};
    font-weight: inherit;
  }
`;
type ContentProps = { $show: boolean };
const Content = styled.ul<ContentProps>`
  ${columns(1, 12)};
  display: flex;
  flex-direction: column;
  opacity: ${(props) => (props.$show ? 1 : 0)};
  transition: opacity 0.2s ease;
`;

const Position = styled.span`
  ${TYPO.p1};
  font-size: 16px;
  color: ${COLORS.shades.s400.css};
  display: flex;
  max-width: 600px;
  width: 100%;
`;

const Info = styled.span`
  ${TYPO.p2};
  color: ${COLORS.shades.s300.css};
  flex: 1;
`;

const IconContainer = styled.div`
  border-radius: 3px;
  height: 36px;
  width: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${COLORS.brand.regular.css};
  color: ${COLORS.white.css};
  opacity: 0;
  transition: opacity 0.2s ease;
  position: absolute;
  top: 50%;
  right: 9px;
  transform: translateY(-50%);
`;

const Row = styled.li`
  width: 100%;
  border: solid 1px ${COLORS.shades.s400.css};
  border-bottom-width: 0px;
  background-color: ${COLORS.shades.s500.opacity(0.04)};
  &:first-of-type {
    border-radius: 5px 5px 0 0;
  }
  &:last-of-type {
    border-radius: 0 0 5px 5px;
    border-bottom-width: 1px;
  }
  &:only-child {
    border-radius: 5px;
  }
  & a {
    width: inherit;
    display: flex;
    align-items: center;
    padding: 18px 24px;
  }
  &:hover {
    ${IconContainer} {
      opacity: 1;
    }
  }
  ${BREAKPOINTS.max.small`
    & a {
      flex-direction:column;
      align-items:flex-start;
    }
    `}
`;

type CareersOpeningsProps = {
  data: BlockJobOpeningsFragment;
  jobs: Job[];
};

function CareersOpenings({
  data,
  jobs: initialJobs = [],
}: CareersOpeningsProps) {
  const [inViewRef, inView] = useInview({});
  const [jobs, setJobs] = useState(initialJobs);

  useEffect(() => {
    // on loading of page ensure jobs are fetched again, so they're always up to date
    // even if the static version of the page hasn't been updated manually...
    const reloadJobs = async () => {
      const newJobs = await getJobs();
      setJobs(newJobs);
    };

    reloadJobs();
  }, []);

  return (
    <Container>
      <Inner>
        <HeadingSection ref={inViewRef} $show={inView}>
          <Heading>{textUtils.highlight(data.heading)}</Heading>
          {data.byline && (
            <Subheading>{textUtils.highlight(data.byline)}</Subheading>
          )}
        </HeadingSection>
        <Content $show={inView}>
          {jobs.map((job) => (
            <Row key={job.id}>
              <Link
                link={{
                  type: 'external',
                  label: job.title,
                  url: job.url,
                }}
              >
                <Position>{job.title}</Position>
                {job.location && <Info>{job.location.name}</Info>}
                <IconContainer>
                  <Icon name="ExternalLink" width={12} height={12} />
                </IconContainer>
              </Link>
            </Row>
          ))}
        </Content>
      </Inner>
    </Container>
  );
}

export default memo(CareersOpenings);
