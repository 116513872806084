import React from 'react';
import styled from 'styled-components';

import { BlockBlogPostFragment } from '../schemas/api';

import BREAKPOINTS from '../styles/breakpoints';
import { Grid, columns } from '../styles/grid';
import HandbookPostPreview from './HandbookPostPreview';
import Heading from './Heading';

const Container = styled(Grid)`
  padding: 60px 12px 60px;
`;

const VolumeContainer = styled.div`
  ${columns(2, 10)};
  display: flex;
  flex-direction: column;
  gap: 3px;

  margin-bottom: 120px;
  ${BREAKPOINTS.max.medium`
      ${columns(1, 12)};
        margin-bottom: 120px;
  `}
`;

const Posts = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 36px;
`;

type BlogCollectionProps = {
  data: BlockBlogPostFragment;
};

export default function HandbookCollection({ data }: BlogCollectionProps) {
  return (
    <Container>
      {data.list.items.map((post) => {
        return (
          <VolumeContainer key={post.sys.id}>
            <Heading
              show
              heading={post.title}
              byline={post.short}
              headingLevel="h3"
              style={{ marginBottom: 18 }}
            />
            <Posts>
              {post.chapters.items.map((d, i) => {
                return (
                  <HandbookPostPreview
                    post={d}
                    href={i === 0 ? post.slug : `${post.slug}#${d.slug}`}
                    key={d.sys.id}
                  />
                );
              })}
            </Posts>
          </VolumeContainer>
        );
      })}
    </Container>
  );
}
