import React, { memo, useState, useEffect, useRef } from 'react';
import styled from 'styled-components';

import type { BlockScrollingFeaturesFragment } from '../schemas/api';

import FeaturesChart from './FeaturesChart';
import useInview from '../hooks/useInview';

import * as textUtils from '../utils/text';

import { staggerChildrenFadeTranslateIn } from '../styles/animations';
import TYPO from '../styles/typography';
import COLORS from '../styles/colors';
import BREAKPOINTS from '../styles/breakpoints';
import { Grid, columns } from '../styles/grid';

const Container = styled(Grid)`
  background: ${COLORS.shades.s500.opacity(0.04)};

  padding: 180px 24px;
  ${BREAKPOINTS.max.medium`
    padding: 120px 12px;
  `}
`;

const Content = styled(Grid)`
  ${columns(1, 12)}
  margin-top: 72px;
  display: block;
  ${BREAKPOINTS.max.small`
    display:none;
    `}
`;

const MobileContent = styled.div`
  ${columns(1, 12)}
  display: none;
  padding: 0 24px;
  ${BREAKPOINTS.max.small`
    display:block;
  `}
`;

const RowContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 36px 0;
`;

const Row = styled.div`
  flex: 1;
  padding: 12px 0;
  text-align: center;
`;

type ContentProps = { $show: boolean };
const HeadingSection = styled.div<ContentProps>`
  ${columns(1, 12)};
  text-align: center;
  ${(props) => staggerChildrenFadeTranslateIn(props.$show)}
`;

const Heading = styled.h1`
  ${TYPO.h2};
  max-width: 700px;
  margin: 0 auto;
`;

const LeftColumn = styled.div`
  ${columns(1, 7)}
  height: 100vh;
  max-height: 500px;
  display: flex;
  align-items: center;
  position: sticky;
  top: calc(50% - 250px);
`;

const RightColumn = styled.div`
  ${columns(8, 4)}
  position:relative;
  height: 100%;
`;

const Item = styled.div`
  height: 95vh;
  max-height: 500px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  gap: 6px;
`;

const Title = styled.div`
  ${TYPO.h3}
`;

const Description = styled.div`
  ${TYPO.p1};
  color: ${COLORS.text.light.css};
`;

type BlockScrollingFeaturesProps = {
  data: BlockScrollingFeaturesFragment;
};

function BlockScrollingFeatures({ data }: BlockScrollingFeaturesProps) {
  const [inViewRef, inView] = useInview({});
  const [inViewChartRef, inViewChart] = useInview({ once: false });
  const ref = useRef(null);
  const [current, setCurrent] = useState(0);

  useEffect(() => {
    const listener = () => {
      if (ref.current && inView) {
        const { innerHeight } = window;
        const { children } = ref.current;
        for (let i = 0; i < children.length; i += 1) {
          const { top, bottom } = children[i].getBoundingClientRect();

          if (
            top > 0 &&
            top < innerHeight &&
            bottom < innerHeight &&
            bottom > 0
          ) {
            setCurrent(i);
          }
        }
      }
    };
    window.addEventListener('scroll', listener, false);

    return function unbind() {
      window.removeEventListener('scroll', listener, false);
    };
  }, [inView]);

  return (
    <Container ref={inViewRef}>
      <HeadingSection $show={inView}>
        <Heading>{textUtils.highlight(data.heading)}</Heading>
      </HeadingSection>
      <MobileContent>
        {data.list.items.map((item, index) => (
          <RowContainer key={item.value}>
            <Row>
              <FeaturesChart progress={index} canAnimate={true} />
            </Row>
            <Row>
              <Title>{item.value}</Title>
              <Description>{item.longValue}</Description>
            </Row>
          </RowContainer>
        ))}
      </MobileContent>
      <Content>
        <LeftColumn ref={inViewChartRef}>
          <FeaturesChart progress={current} canAnimate={inViewChart} />
        </LeftColumn>
        <RightColumn ref={ref}>
          {data.list.items.map((item) => (
            <Item key={item.value}>
              <Title>{item.value}</Title>
              <Description>{item.longValue}</Description>
            </Item>
          ))}
        </RightColumn>
      </Content>
    </Container>
  );
}

export default memo(BlockScrollingFeatures);
