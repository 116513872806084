import COLORS from '../styles/colors';
import Harry from '../assets/images/harry.png';
import Neil from '../assets/images/neil.jpg';
import Euchian from '../assets/images/euchian.png';
import Testimonial1 from '../assets/images/testimonial-4.jpeg';
import Testimonial2 from '../assets/images/testimonial-5.png';

export type Circle = {
  id: string;
  position: { start: number; end: number };
  label?: { start?: string; end?: string };
  avatar?: string;
};

export type Branch = {
  id: string;
  name: string;
  description: string;
  color: string;
  action: string;
  path: {
    id: string;
    startingPoint: [number, number];
    length: number;
    height: number;
  };
  circles: Circle[];
};

export type DataType = {
  title: string;
  width: number;
  height: number;
  actionPosY: number;
  avatarSize: number;
  borderRadius: number;
  branches: Branch[];
};

export const desktop: DataType = {
  title: 'Baseline',
  width: 1280,
  height: 500,
  actionPosY: 300,
  avatarSize: 40,
  borderRadius: 20,
  branches: [
    {
      id: 'yellow-branch',
      name: 'Functional Baseline',
      description: 'Major architecture requirements defined',
      color: COLORS.rainbow.yellow.regular.css,
      action: 'Submit for System Definition Review',
      path: {
        id: 'yellow-path',
        startingPoint: [160, 100],
        length: 320,
        height: 80,
      },
      circles: [
        {
          id: 'yellow-circle-1',
          position: { start: 10, end: 40 },
          label: { start: 'System Architecture v1.0', end: 'New Spec Updated' },
        },
        {
          id: 'yellow-circle-2',
          position: { start: 30, end: 50 },
          avatar: Harry.src,
          label: { start: 'UPDATED AGAIN V4', end: 'Fuel Drain Rate Change' },
        },
        {
          id: 'yellow-circle-3',
          position: { start: 40, end: 60 },
          label: {
            start: 'David Version DO NOT EDIT',
            end: 'System Cost Updated',
          },
        },
        {
          id: 'yellow-circle-4',
          position: { start: 100, end: 100 },
        },
      ],
    },
    {
      id: 'red-branch',
      name: 'Allocated Baseline',
      description: 'Implementation design aspects completed',
      color: COLORS.rainbow.red.regular.css,
      action: 'Submit for Preliminary Design Review',
      path: {
        id: 'red-path',
        startingPoint: [100, 100],
        length: 700,
        height: 160,
      },
      circles: [
        {
          id: 'red-circle-1',
          position: { start: 20, end: 25 },
          avatar: Testimonial1.src,
          label: {
            start: 'System Architecture UPDATED',
            end: 'Fuel Mass Update',
          },
        },
        {
          id: 'red-circle-2',
          position: { start: 25, end: 35 },
          label: { start: 'System v4', end: 'System Thrust Update' },
        },
        {
          id: 'red-circle-3',
          position: { start: 40, end: 45 },
          avatar: Neil.src,
          label: { start: 'Finished Version', end: 'Combustion Temp Change' },
        },
        {
          id: 'red-circle-4',
          position: { start: 70, end: 65 },
          label: {
            start: 'Finished Version v2.4',
            end: 'Chamber Creep Analysis',
          },
        },
        {
          id: 'red-circle-5',
          position: { start: 80, end: 75 },
          label: { start: 'Reset Update', end: 'Wall Cooling Added' },
        },
        {
          id: 'red-circle-6',
          position: { start: 100, end: 100 },
        },
      ],
    },
    {
      id: 'blue-branch',
      name: 'Product Baseline',
      description: 'Realisation and fabrication aspects completed',
      color: COLORS.rainbow.cyan.regular.css,
      action: 'Submit for Critical Design Review',
      path: {
        id: 'blue-path',
        startingPoint: [520, 100],
        length: 610,
        height: 200,
      },
      circles: [
        {
          id: 'blue-circle-1',
          position: { start: 10, end: 30 },
          label: { start: 'NEILS VERSION', end: 'Material Changed' },
        },
        {
          id: 'blue-circle-2',
          position: { start: 30, end: 40 },
          avatar: Euchian.src,
          label: { start: 'DO NOT OPEN', end: 'Buckling Assessment' },
        },
        {
          id: 'blue-circle-3',
          position: { start: 55, end: 50 },
          label: { start: 'Breaking Version', end: 'Thickness Assessment' },
        },
        {
          id: 'blue-circle-4',
          position: { start: 70, end: 60 },
          avatar: Testimonial2.src,
          label: { start: 'FINALFINAL', end: 'FEA Simulation' },
        },
        {
          id: 'blue-circle-5',
          position: { start: 90, end: 70 },
          label: { start: 'FINALFINALFINAL', end: 'Chief Engineer Approval' },
        },
        {
          id: 'blue-circle-6',
          position: { start: 100, end: 100 },
        },
      ],
    },
  ],
};

export const mobile: DataType = {
  title: 'Baseline',
  width: 400,
  height: 400,
  actionPosY: 250,
  avatarSize: 30,
  borderRadius: 10,
  branches: [
    {
      id: 'yellow-branch-mobile',
      name: 'Functional Baseline',
      description: 'Major architecture\nrequirements defined',
      color: COLORS.rainbow.yellow.regular.css,
      action: 'Submit for System\nDefinition Review',
      path: {
        id: 'red-path-mobile',
        startingPoint: [40, 100],
        length: 120,
        height: 150,
      },
      circles: [
        {
          id: 'red-circle-1-mobile',
          position: { start: 25, end: 40 },
          avatar: Testimonial1.src,
          label: {
            start: 'System Architecture UPDATED',
            end: 'Fuel Mass Update',
          },
        },
        {
          id: 'red-circle-4-mobile',
          position: { start: 70, end: 55 },
          avatar: Neil.src,
          label: {
            start: 'Finished Version v2.4',
            end: 'Chamber Creep Analysis',
          },
        },
        {
          id: 'red-circle-6-mobile',
          position: { start: 100, end: 100 },
        },
      ],
    },
    {
      id: 'red-branch-mobile',
      name: 'Allocated Baseline',
      description: 'Implementation design\naspects completed',
      color: COLORS.rainbow.red.regular.css,
      action: 'Submit for Preliminary\nDesign Review',
      path: {
        id: 'yellow-path-mobile',
        startingPoint: [85, 100],
        length: 245,
        height: 90,
      },
      circles: [
        {
          id: 'yellow-circle-1-mobile',
          position: { start: 10, end: 30 },
          label: { start: 'System Architecture v1.0', end: 'New Spec Updated' },
        },
        {
          id: 'yellow-circle-2-mobile',
          position: { start: 50, end: 50 },
          label: { start: 'UPDATED AGAIN V4', end: 'Fuel Drain Rate Change' },
        },
        {
          id: 'yellow-circle-3-mobile',
          position: { start: 80, end: 65 },
          avatar: Harry.src,
          label: {
            start: 'David Version DO NOT EDIT',
            end: 'System Cost Updated',
          },
        },
        {
          id: 'yellow-circle-4',
          position: { start: 100, end: 100 },
        },
      ],
    },
  ],
};
