import React, { MutableRefObject, forwardRef, memo } from 'react';
import styled from 'styled-components';

import { SubmitHandler, useForm } from 'react-hook-form';

import Button from '../styles/button';
import Icon from './Icon';
import { TextArea, Label, Form, ErrorMessage, LabelRow } from '../styles/form';

import ButtonProgressBar from './ButtonProgressBar';
import Floating from './Floating';

import TYPO from '../styles/typography';
import COLORS from '../styles/colors';

const Examples = styled.div`
  padding: 12px 0;
  display: flex;
  flex-direction: column;
  max-width: 500px;
  max-height: 400px;
  overflow: auto;
`;

const Example = styled.div`
  ${TYPO.p2};
  padding: 12px 24px;
  border-bottom: 1px solid ${COLORS.shades.s200.css};
  cursor: pointer;
  &:hover {
    background: ${COLORS.shades.s100.opacity(0.5)};
  }
  & h3 {
    ${TYPO.strong}
    margin:0 0 6px;
  }
`;

type PromptInputs = {
  prompt: string;
};

type FormAiProps = {
  placeholder?: string;
  onSubmit: SubmitHandler<PromptInputs>;
  examples: { title: string; value: string }[];
};

function FormAiPrompt(
  { placeholder, examples, onSubmit }: FormAiProps,
  ref: MutableRefObject<boolean>
) {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting, isValid },
    setError,
    setValue,
    reset,
    trigger,
  } = useForm<PromptInputs>();

  return (
    <Form
      onSubmit={async (event) => {
        /* eslint-disable no-param-reassign */
        ref.current = true;
        handleSubmit(onSubmit)(event)
          .catch((e) => {
            setError('prompt', { message: e.message });
            reset(undefined, {
              keepValues: true,
              keepErrors: true,
            });
          })
          .then(() => {
            reset(undefined, {
              keepValues: true,
              keepErrors: true,
            });
          })
          .finally(() => {
            ref.current = false;
          });
        /* eslint-enable no-param-reassign */
      }}
    >
      <Label as="div">
        <LabelRow>
          Enter your description or{' '}
          <Floating
            placement="bottom-start"
            render={(toggle) => (
              <Examples>
                {examples.map((d) => (
                  <Example
                    key={d.title}
                    onClick={() => {
                      setValue('prompt', d.value);
                      trigger('prompt');
                      toggle();
                    }}
                  >
                    <h3>{d.title}</h3>
                    <span>{`"${d.value}"`}</span>
                  </Example>
                ))}
              </Examples>
            )}
          >
            <Button
              type="button"
              $variant="secondary"
              $size="small"
              style={{ padding: 6 }}
            >
              try these examples
              <Icon name="ChevronDown" width={12} height={12} />
            </Button>
          </Floating>
        </LabelRow>
        <TextArea
          $variant="secondary"
          placeholder={placeholder}
          data-error={!!errors.prompt}
          {...register('prompt', { required: true })}
        />
        {errors.prompt && (
          <ErrorMessage>
            <Icon name="AlertTriangle" width={12} height={12} />
            <span>{errors.prompt.message}</span>
          </ErrorMessage>
        )}
      </Label>
      <ButtonProgressBar
        type="submit"
        icon="Check"
        loading={isSubmitting}
        disabled={!isValid}
        style={{ alignSelf: 'flex-end' }}
        duration={45}
      >
        {isSubmitting ? 'Generating...' : 'Generate'}
      </ButtonProgressBar>
    </Form>
  );
}

export default memo(forwardRef<boolean, FormAiProps>(FormAiPrompt));
