import React, { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import anime from 'animejs';

import COLORS, { Color } from '../styles/colors';

import AnimatedAvatar from './AnimatedAvatar';
import RequirementBox from '../styles/animations/RequirementBox';
import { FlowLogo } from './AnimationCollaboration';

import { continuousIntegration as data } from '../data/featuresAnimationsData';

const WIDTH = 600;
const HEIGHT = 400;

const SVG = styled.svg`
  height: 100%;
  width: 100%;
  overflow: visible;

  .links {
    transform-origin: ${WIDTH / 2}px ${HEIGHT / 2}px;
  }
`;

type CircleContainerProps = {
  $shadowColor: string;
};
const CircleContainer = styled.circle<CircleContainerProps>`
  filter: drop-shadow(
    3px 3px 6px ${(props) => new Color(props.$shadowColor).opacity(0.2)}
  );
`;

type AnimationContinuousIntegrationProps = React.HTMLAttributes<SVGElement> & {
  canAnimate: boolean;
};

export default function AnimationContinuousIntegration({
  canAnimate,
  ...props
}: AnimationContinuousIntegrationProps) {
  const refs = useRef([]);
  const requirement = useRef(null);
  const chiefLink = useRef(null);
  const [success, setSuccess] = useState(false);

  const timelines = useRef([]);

  useEffect(() => {
    const duration = 2000;

    refs.current.forEach((node, index) => {
      timelines.current[index] = anime.timeline({
        autoplay: false,
        loop: true,
        delay: index * (duration / refs.current.length),
        duration,
      });

      const path = anime.path(node.link);

      timelines.current[index].add(
        {
          targets: node.circle,
          translateX: path('x'),
          translateY: path('y'),
          easing: 'linear',
        },
        '-=0'
      );
    });

    timelines.current[refs.current.length] = anime.timeline({
      autoplay: false,
      loop: true,
      delay: 0,
      loopBegin: () => {
        setSuccess(Math.random() > 0.5);
      },
      duration,
    });

    const path = anime.path(chiefLink.current);

    timelines.current[refs.current.length].add(
      {
        targets: requirement.current,
        translateX: path('x'),
        translateY: path('y'),
        easing: 'linear',
      },
      '-=0'
    );

    const timelineList = timelines.current;

    return () => {
      // pause animations on component unmount
      timelineList.forEach((timeline) => {
        timeline.pause();
      });
    };
  }, []);

  useEffect(() => {
    timelines.current.forEach((timeline) => {
      if (canAnimate) {
        timeline.play();
      } else {
        timeline.pause();
      }
    });
  }, [canAnimate]);

  return (
    <SVG viewBox={`0 0 ${WIDTH} ${HEIGHT}`} {...props}>
      <defs>
        <pattern
          id="grid"
          x="0"
          y="0"
          width={30}
          height={30}
          patternUnits="userSpaceOnUse"
        >
          <circle cx={15} cy={15} r={1.5} fill={COLORS.shades.s200.css} />
        </pattern>
      </defs>

      <rect x="0" y="25%" width="100%" height="50%" fill="url(#grid)" />
      {data.people.map((person, i) => {
        const angle = Math.PI / (data.people.length + 1);
        const value = angle * (i + 1) - Math.PI / 2;
        const x = WIDTH / 2 + data.radius * Math.sin(value);
        const y = HEIGHT / 2 + data.radius * Math.cos(value);

        const x1 = WIDTH / 2 + (data.radius - 24) * Math.sin(value);
        const y1 = HEIGHT / 2 + (data.radius - 24) * Math.cos(value);
        const x2 = WIDTH / 2 + 34 * Math.sin(value);
        const y2 = HEIGHT / 2 + 34 * Math.cos(value);

        return (
          <g key={person.id}>
            <line
              ref={(el) => {
                if (!refs.current[i]) {
                  refs.current[i] = {};
                }
                refs.current[i].link = el;
              }}
              x2={x2}
              y2={y2}
              x1={x1}
              y1={y1}
              stroke={COLORS.black.css}
              strokeWidth={2}
            />
            <circle
              r={5}
              transform={`translate(${x1},${y1})`}
              cx={0}
              cy={0}
              fill="black"
              stroke="black"
              strokeWidth={2}
              ref={(el) => {
                if (!refs.current[i]) {
                  refs.current[i] = {};
                }
                refs.current[i].circle = el;
              }}
            />
            <g transform={`translate(${x},${y})`}>
              <CircleContainer
                $shadowColor={person.avatar.clothing.color}
                r={30}
                cx={0}
                cy={0}
                fill={COLORS.white.css}
              />
              <AnimatedAvatar
                avatar={person.avatar}
                width={52}
                height={52}
                notification={false}
                editing={false}
                x={-26}
                y={-26}
                circle
                canAnimate={canAnimate}
              />
            </g>
          </g>
        );
      })}
      <g>
        <line
          ref={chiefLink}
          x2={WIDTH / 2 + (data.radiusChief - 24) * Math.sin(Math.PI)}
          y2={HEIGHT / 2 + (data.radiusChief - 24) * Math.cos(Math.PI)}
          x1={WIDTH / 2 + 34 * Math.sin(Math.PI)}
          y1={HEIGHT / 2 + 34 * Math.cos(Math.PI)}
          stroke={COLORS.black.css}
          strokeWidth={2}
        />
        <g
          ref={requirement}
          transform={`translate(${
            WIDTH / 2 + data.radiusChief * Math.sin(Math.PI)
          },${HEIGHT / 2 + data.radiusChief * Math.cos(Math.PI)})`}
        >
          <g transform={`translate(-12,-12)`}>
            <RequirementBox size={24} success={success} />
          </g>
        </g>
        <g
          transform={`translate(${
            WIDTH / 2 + data.radiusChief * Math.sin(Math.PI)
          },${HEIGHT / 2 + data.radiusChief * Math.cos(Math.PI)})`}
        >
          <CircleContainer
            $shadowColor={data.chief.avatar.clothing.color}
            r={30}
            cx={0}
            cy={0}
            fill={COLORS.white.css}
          />
          <AnimatedAvatar
            avatar={data.chief.avatar}
            width={52}
            height={52}
            notification={false}
            editing={false}
            x={-26}
            y={-26}
            circle
          />
        </g>
      </g>
      {<FlowLogo transform={`translate(${WIDTH / 2},${HEIGHT / 2})`} />}
    </SVG>
  );
}
