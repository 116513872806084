import React, { memo } from 'react';
import styled from 'styled-components';
import Image from './Image';

import type {
  BlockMediaCollectionFragment,
  MediaItemFragment,
} from '../schemas/api';

import useInview from '../hooks/useInview';

import { staggerChildrenFadeTranslateIn } from '../styles/animations';
import TYPO from '../styles/typography';
import COLORS from '../styles/colors';
import BREAKPOINTS from '../styles/breakpoints';
import { Grid, columns } from '../styles/grid';

import MarkdownInline from './MarkdownInline';
import { spacing } from '../styles/blocks';

type ContainerProps = {
  $spacing: string;
};

const Container = styled(Grid)<ContainerProps>`
  ${(props) => spacing(props.$spacing || '')};
`;

const Inner = styled.div`
  ${columns(1, 12)}
  display: flex;
  flex-direction: column;
  gap: 60px;
  width: 100%;
`;

type ContentProps = { $show: boolean };
const Content = styled.div<ContentProps>`
  padding-top: 24px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  ${(props) => staggerChildrenFadeTranslateIn(props.$show, 0, undefined, 0.05)}
`;

const HeadingSection = styled.div<ContentProps>`
  width: 100%;
  text-align: center;
  justify-content: center;
  display: flex;
  gap: 4px;
  ${(props) => staggerChildrenFadeTranslateIn(props.$show)}
`;

const Heading = styled.h1`
  ${TYPO.h2}
`;

const Subheading = styled.div`
  ${TYPO.subheading}
  white-space: pre-wrap;
  & strong {
    color: ${COLORS.brand.regular.css};
    font-weight: inherit;
  }
`;

const Value = styled.div`
  width: 33%;
  margin-bottom: 108px;
  display: flex;
  flex-direction: column;
  padding: 0 18px;
  align-items: center;
  ${BREAKPOINTS.min.medium`
  &:nth-last-child(1),
  &:nth-last-child(2),
  &:nth-last-child(3) {
    margin-bottom: 0;
  }
  `}
  ${BREAKPOINTS.max.medium`
    width:50%;
    margin-bottom: 86px;
    `}
  ${BREAKPOINTS.max.extraSmall`
    width:100%;
    &:last-child {
      margin-bottom: 0;
    }
    `}
`;

const ValueIcon = styled.div`
  display: flex;
  justify-content: center;
  align-content: flex-start;
  width: 86px;
  height: 86px;
`;

const ValueInner = styled.div`
  margin-top: 18px;
  text-align: center;
`;

const ValueHeading = styled.div`
  ${TYPO.h3}
`;
const ValueContent = styled.div`
  ${TYPO.p2}
  margin-top: 6px;
  color: ${COLORS.shades.s400.css};
  padding: 0 12px;
`;

type ValuesWithIconsProps = {
  data: BlockMediaCollectionFragment;
};

function ValuesWithIcons({ data }: ValuesWithIconsProps) {
  const [inViewRef, inView] = useInview({});
  return (
    <Container $spacing={data.spacing}>
      <Inner ref={inViewRef}>
        {(data.heading || data.byline) && (
          <HeadingSection $show={inView}>
            <Heading>
              <MarkdownInline>{data.heading}</MarkdownInline>
            </Heading>
            {data.byline && (
              <Subheading>
                <MarkdownInline>{data.byline}</MarkdownInline>
              </Subheading>
            )}
          </HeadingSection>
        )}
        <Content $show={inView}>
          {data.mediaCollection.items.map((value: MediaItemFragment) => (
            <Value key={value.heading}>
              <ValueIcon>
                <Image
                  src={value.media.url}
                  alt={value.heading}
                  height={value.media.height}
                  width={value.media.width}
                  style={{ width: '100%', height: 'auto' }}
                />
              </ValueIcon>
              <ValueInner>
                <ValueHeading>{value.heading}</ValueHeading>
                <ValueContent>{value.content}</ValueContent>
              </ValueInner>
            </Value>
          ))}
        </Content>
      </Inner>
    </Container>
  );
}

export default memo(ValuesWithIcons);
