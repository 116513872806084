import { css } from 'styled-components';

import BREAKPOINTS from './breakpoints';

export const spacing = (size = '') => {
  switch (size?.toLowerCase()) {
    case 'none':
      return css`
        margin-top: 0;
        padding: 0 12px 48px;
        ${BREAKPOINTS.max.medium`
        padding: 0 12px 36px;
      `}
      `;
    case 'small':
      return css`
        margin-top: 24px;
        padding: 48px 12px;
        ${BREAKPOINTS.max.medium`
          padding: 36px 12px;
        `}
      `;
    case 'default':
    default:
      return css`
        margin-top: 48px;
        padding: 64px 12px;
        ${BREAKPOINTS.max.medium`
          padding: 48px 12px;
        `}
      `;
  }
};
