import React, { memo } from 'react';
import styled, { css } from 'styled-components';

import type { BlockCtaFragment } from '../schemas/api';

import Image from './Image';
import CtaButton from './CTAButton';

import useInview from '../hooks/useInview';

import * as textUtils from '../utils/text';

import {
  staggerChildrenFadeTranslateIn,
  fadeTranslateIn,
} from '../styles/animations';
import COLORS from '../styles/colors';
import TYPO from '../styles/typography';
import BREAKPOINTS from '../styles/breakpoints';

import { Grid, columns } from '../styles/grid';

const Container = styled(Grid)`
  padding: 180px 12px;
  ${BREAKPOINTS.max.medium`
    padding: 120px 12px;
  `}
`;

const HeadingSection = styled.div<SubheadingProps>`
  ${columns(3, 8)}
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  ${BREAKPOINTS.max.small`
    ${columns(1, 12)}
    `}
  ${(props) => staggerChildrenFadeTranslateIn(props.$show)}
`;

const Heading = styled.h1`
  ${TYPO.h2}
`;

const ButtonContainer = styled.div`
  display: flex;
  padding: 12px 0;
`;

type SubheadingProps = {
  $show: boolean;
};
const Subheading = styled.div`
  ${TYPO.p1}
`;

const ImageContainer = styled.div<SubheadingProps>`
  ${columns(1, 12)}
  position: relative;
  margin-bottom: 50px;
  opacity: 0;
  ${(props) =>
    props.$show &&
    css`
      animation: ${fadeTranslateIn(0, -50)} 1s ease-out forwards;
      animation-fill-mode: both;
    `}
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
  height: 100%;
  width: 100%;
  background: linear-gradient(
    180deg,
    ${COLORS.white.opacity(0)} 50%,
    ${COLORS.white.opacity(1)} 100%
  );
`;

type BlockCtaProps = {
  className?: string;
  data: BlockCtaFragment;
};
function BlockCtaJSX({ className, data }: BlockCtaProps) {
  const [inViewRef, inView] = useInview({});
  const [imageInViewRef, imageInView] = useInview({});

  return (
    <Container className={className}>
      {data.image && (
        <ImageContainer ref={imageInViewRef} $show={imageInView}>
          <Image
            src={data.image.url}
            alt={data.heading || data.byline}
            height={data.image.height}
            width={data.image.width}
            style={{ width: '100%', height: 'auto' }}
          />
          <Overlay />
        </ImageContainer>
      )}
      <HeadingSection ref={inViewRef} $show={inView}>
        {data.heading && <Heading>{textUtils.highlight(data.heading)}</Heading>}
        {data.byline && <Subheading>{data.byline}</Subheading>}
        <ButtonContainer>
          <CtaButton />
        </ButtonContainer>
      </HeadingSection>
    </Container>
  );
}

export default memo(BlockCtaJSX);
