import React, { memo } from 'react';
import styled from 'styled-components';
import Image from './Image';

import type { BlockImageFragment } from '../schemas/api';

import useInview from '../hooks/useInview';

import { staggerChildrenFadeTranslateIn } from '../styles/animations';
import TYPO from '../styles/typography';
import COLORS from '../styles/colors';
import BREAKPOINTS from '../styles/breakpoints';
import { Grid, columns } from '../styles/grid';

import * as textUtils from '../utils/text';

const Container = styled(Grid)`
  position: relative;
  padding: 120px 0;
  ${BREAKPOINTS.max.medium`
    padding: 72px 0;
  `}
`;

const Inner = styled.div`
  ${columns(1, 12)}
  display: flex;
  flex-direction: column;
  gap: 60px;
  width: 100%;
`;
type ContentProps = {
  $show?: boolean;
};

const HeadingSection = styled.div<ContentProps>`
  display: flex;
  flex-direction: column;
  text-align: left;
  gap: 12px;
  padding: 0 12px;
  ${(props) => staggerChildrenFadeTranslateIn(props.$show)}
`;

const Heading = styled.h1`
  width: 100%;
  ${TYPO.h1}
`;

const ImageContainer = styled.div<ContentProps>`
  width: 100%;
  height: auto;
  opacity: ${(props) => (props.$show ? 1 : 0)};
  transition: opacity 0.2s ease;
  ${columns(1, 12)}
`;

const Subheading = styled.div`
  ${TYPO.p1};
  color: ${COLORS.text.light.css};
  line-height: 148%;
  max-width: 715px;
  white-space: pre-wrap;
  & strong {
    color: ${COLORS.brand.regular.css};
    font-weight: inherit;
  }
`;

type BlockImageGridLargeHeadingProps = {
  className?: string;
  data: BlockImageFragment;
};

function BlockImageGridLargeHeading({
  className,
  data,
}: BlockImageGridLargeHeadingProps) {
  const [inViewRef, inView] = useInview({});
  return (
    <Container className={className}>
      <Inner>
        <HeadingSection ref={inViewRef} $show={inView}>
          <Heading>{textUtils.highlight(data.heading)}</Heading>
          {data.byline && (
            <Subheading>{textUtils.highlight(data.byline)}</Subheading>
          )}
        </HeadingSection>
        <ImageContainer $show={inView}>
          <Image
            src={data.image.url}
            alt={data.heading}
            width={data.image.width}
            height={data.image.height}
            style={{ width: '100%', height: 'auto' }}
          />
        </ImageContainer>
      </Inner>
    </Container>
  );
}

export default memo(BlockImageGridLargeHeading);
