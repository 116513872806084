import { css } from 'styled-components';
import COLORS, { Color } from './colors';

const dividerColor = COLORS.brand.regular.mix(COLORS.white, 0.6, true) as Color;

export const divider = (align: 'center' | 'left' | 'right') => css`
  position: relative;
  &:before,
  &:after {
    position: absolute;
    content: '';
    pointer-events: none;
    top: 0;
  }
  &:before {
    ${() => {
      switch (align) {
        case 'left':
        case 'right':
          return css`
            background: linear-gradient(
              ${align === 'left' ? '90' : '270'}deg,
              ${dividerColor.opacity(0)} -70%,
              ${dividerColor.opacity(1)} 0%,
              ${dividerColor.opacity(1)} 20%,
              ${dividerColor.opacity(0)} 50%
            );
          `;
        case 'center':
        default:
          return css`
            background: linear-gradient(
              270deg,
              ${dividerColor.opacity(0)} 30%,
              ${dividerColor.opacity(1)} 45%,
              ${dividerColor.opacity(1)} 55%,
              ${dividerColor.opacity(0)} 70%
            );
          `;
      }
    }}
    top: 0;

    height: 1px;
    left: 0;

    width: 100%;
  }
  &:after {
    content: '';
    height: 100%;

    max-width: 1280px;

    ${() => {
      switch (align) {
        case 'left':
          return css`
            left: 0%;
            transform: translate(-50%);
          `;
        case 'right':
          return css`
            right: 0%;
            transform: translate(50%);
          `;
        case 'center':
        default:
          return css`
            left: 50%;
            transform: translate(-50%);
          `;
      }
    }}

    width: 100vw;
    z-index: 9;
    background: radial-gradient(
      40% 40% at 50% 0%,
      ${dividerColor.opacity(0.1)},
      ${dividerColor.opacity(0)} 100%,
      ${dividerColor.opacity(0)}
    );
    z-index: 3;
  }
`;
