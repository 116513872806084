import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';
import anime from 'animejs';

import COLORS, { Color } from '../styles/colors';

import AnimatedAvatar from './AnimatedAvatar';
import { FlowLogo } from './AnimationCollaboration';

import { sync as data } from '../data/featuresAnimationsData';

const WIDTH = 600;
const HEIGHT = 400;

const SVG = styled.svg`
  height: 100%;
  width: 100%;
  overflow: visible;

  .links {
    transform-origin: ${WIDTH / 2}px ${HEIGHT / 2}px;
  }
`;

type CircleContainerProps = {
  $shadowColor: string;
};
const CircleContainer = styled.circle<CircleContainerProps>`
  filter: drop-shadow(
    3px 3px 6px ${(props) => new Color(props.$shadowColor).opacity(0.2)}
  );
`;

type AnimationCollaborationProps = React.HTMLAttributes<SVGElement> & {
  canAnimate: boolean;
};

export default function AnimationCollaboration({
  canAnimate,
  ...props
}: AnimationCollaborationProps) {
  const animation = useRef({
    offset: 0,
    timeline: null,
    nodes: null,
  });
  const refs = useRef({ links: [], reversedLinks: [], nodes: [], points: [] });
  const links = useRef(null);

  useEffect(() => {
    animation.current.nodes = anime({
      targets: animation.current,
      offset: Math.PI * 2,
      duration: 60000,
      loop: true,
      easing: 'linear',
      autoplay: false,
      update: () => {
        refs.current.nodes.forEach((person, i) => {
          const angle = (Math.PI * 2) / data.people.length;
          const value = angle * i + animation.current.offset;
          anime.set(person, {
            translateX: WIDTH / 2 + data.radius * Math.sin(value),
            translateY: HEIGHT / 2 + data.radius * Math.cos(value),
          });
        });

        anime.set(links.current, {
          rotate: -(animation.current.offset * 180) / Math.PI,
        });
      },
    });

    const duration = 2000;
    animation.current.timeline = anime.timeline({
      autoplay: false,
      loop: true,
      delay: 0,
      duration,
    });

    data.people.forEach((_, index) => {
      const source = {
        point: refs.current.points[index],
        link: refs.current.links[index],
      };

      const targets = {
        points: refs.current.points.filter((_, i) => i !== index),
        links: refs.current.reversedLinks.filter((_, i) => i !== index),
      };

      const path = anime.path(source.link);

      animation.current.timeline.add(
        {
          targets: source.point,
          translateX: path('x'),
          translateY: path('y'),
          easing: 'easeInExpo',
          delay: 0,
        },
        '-=1000'
      );

      targets.points.forEach((point, i) => {
        const path = anime.path(targets.links[i]);

        animation.current.timeline.add(
          {
            targets: point,
            translateX: path('x'),
            translateY: path('y'),
            easing: 'easeOutExpo',
            delay: 0,
            changeBegin: () => {
              anime.set(point, { fill: COLORS.white.css });
            },
            changeComplete: () => {
              anime.set(point, { fill: COLORS.black.css });
            },
          },
          i === 0 ? '+=0' : `-=${duration}`
        );
      });
    });

    const { timeline, nodes } = animation.current;
    return () => {
      // pause animations on component unmount
      timeline.pause();
      nodes.pause();
    };
  }, []);

  useEffect(() => {
    if (canAnimate) {
      animation.current.timeline.play();
      animation.current.nodes.play();
    } else {
      animation.current.timeline.pause();
      animation.current.nodes.pause();
    }
  }, [canAnimate]);

  return (
    <SVG viewBox={`0 0 ${WIDTH} ${HEIGHT}`} {...props}>
      <defs>
        <pattern
          id="grid"
          x="0"
          y="0"
          width={30}
          height={30}
          patternUnits="userSpaceOnUse"
        >
          <circle cx={15} cy={15} r={1.5} fill={COLORS.shades.s200.css} />
        </pattern>
      </defs>

      <rect x="0" y="25%" width="100%" height="50%" fill="url(#grid)" />
      <g ref={links} className="links">
        {data.people.map((person, i) => {
          const angle = (Math.PI * 2) / data.people.length;
          const value = angle * i;
          const x1 = WIDTH / 2 + (data.radius - 24) * Math.sin(value);
          const y1 = HEIGHT / 2 + (data.radius - 24) * Math.cos(value);
          const x2 = WIDTH / 2 + 34 * Math.sin(value);
          const y2 = HEIGHT / 2 + 34 * Math.cos(value);

          return (
            <React.Fragment key={person.id}>
              <line
                ref={(el) => {
                  refs.current.links[i] = el;
                }}
                x2={x2}
                y2={y2}
                x1={x1}
                y1={y1}
                stroke={COLORS.black.css}
                strokeWidth={2}
              />
              <line
                ref={(el) => {
                  refs.current.reversedLinks[i] = el;
                }}
                x2={x1}
                y2={y1}
                x1={x2}
                y1={y2}
                stroke="none"
              />
              <circle
                r={5}
                cx={0}
                cy={0}
                fill="black"
                stroke="black"
                strokeWidth={2}
                ref={(el) => {
                  refs.current.points[i] = el;
                }}
              />
            </React.Fragment>
          );
        })}
      </g>
      {data.people.map((person, i) => {
        const angle = (Math.PI * 2) / data.people.length;
        const value = angle * i + animation.current.offset;
        return (
          <g
            ref={(el) => {
              refs.current.nodes[i] = el;
            }}
            key={person.id}
            transform={`translate(${
              WIDTH / 2 + data.radius * Math.sin(value)
            }, ${HEIGHT / 2 + data.radius * Math.cos(value)})`}
          >
            <CircleContainer
              $shadowColor={person.avatar.clothing.color}
              r={30}
              cx={0}
              cy={0}
              fill={COLORS.white.css}
            />
            <AnimatedAvatar
              avatar={person.avatar}
              width={52}
              height={52}
              notification={false}
              editing={false}
              x={-26}
              y={-26}
              circle
              canAnimate={canAnimate}
            />
          </g>
        );
      })}
      {<FlowLogo transform={`translate(${WIDTH / 2},${HEIGHT / 2})`} />}
    </SVG>
  );
}
