import React, { ForwardedRef, forwardRef } from 'react';
import styled from 'styled-components';

import COLORS from '../styles/colors';
import TYPO from '../styles/typography';
import { usePageContext } from './PageProvider';
import Button from '../styles/button';
import Icon from './Icon';

const Input = styled.input`
  ${TYPO.p2}
  height: 100%;
  border: none;
  outline: none;
  flex: 1;
  height: 36px;
  width: 100%;
  border-width: 1px;
  border-style: solid;
  border-color: ${COLORS.shades.s300.css};
  border-radius: 3px;
  padding: 6px 12px;

  &::placeholder {
    color: ${COLORS.shades.s300.css};
  }

  &:focus {
    border-color: ${COLORS.brand.regular.css};
  }
`;
const HiddenInput = styled.input`
  position: absolute;
  left: -5000px;
  display: none;
`;

const Form = styled.form`
  display: flex;
  position: relative;
  align-self: flex-start;
  & > ${Input} {
    border-radius: 3px 0 0 3px;
    border-right: none;
  }

  & > ${Button} {
    border-radius: 0 3px 3px 0;
  }
`;

type HandbookMailchimpFormProps = {
  title: string;
};

function HandbookMailchimpForm({ title }, ref: ForwardedRef<HTMLFormElement>) {
  const { generic } = usePageContext();

  return (
    <Form
      action={generic.mailchimpHandbookForm}
      method="post"
      id="mc-embedded-subscribe-form"
      name="mc-embedded-subscribe-form"
      target="_blank"
      ref={ref}
    >
      <Input type="email" name="EMAIL" placeholder="Your email" required />
      <HiddenInput
        type="text"
        name="b_45a1792cb1793f0fa826db560_784f919fa9"
        aria-hidden="true"
      />
      <HiddenInput type="text" name="FROM" value={title} aria-hidden="true" />
      <Button type="submit" $variant="default">
        <Icon name="Bell" width={12} height={12} />
        Get Notified
      </Button>
    </Form>
  );
}

export default forwardRef<HTMLFormElement, HandbookMailchimpFormProps>(
  HandbookMailchimpForm
);
