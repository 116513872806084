import styled, { css } from 'styled-components';

import { useInView } from 'react-intersection-observer';
import ELEVATION from '../styles/elevation';

type ShadowPosition = 'top' | 'bottom' | 'left' | 'right';

type ShadowProps = {
  $inView: boolean;
  $position: ShadowPosition;
};

const Shadow = styled.div<ShadowProps>`
  position:relative;
  &:after {
    display:block;
    ${(props) => {
      if (props.$inView) {
        return '';
      }
      if (props.$position === 'bottom') {
        return css`
          content: '';
          ${ELEVATION.s100}
          top: -1px;
          width: 100%;
          height: 10px;
        `;
      }
      if (props.$position === 'top') {
        return css`
          content: '';
          ${ELEVATION.s100}
          bottom: -1px;
          width: 100%;
          height: 10px;
        `;
      }
      if (props.$position === 'left') {
        return css`
          content: '';
          ${ELEVATION.s100}
          right: -1px;
          height: 100%;
          width: 10px;
        `;
      }
      if (props.$position === 'right') {
        return css`
          content: '';
          ${ELEVATION.s100}
          left: -1px;
          height: 100%;
          width: 10px;
        `;
      }
      return css``;
    }};
    position:absolute;
    
    }
  }
`;

type ContainerProps = {
  $position: ShadowPosition;
};
const Container = styled.div<ContainerProps>`
  position: sticky;
  ${(props) => {
    switch (props.$position) {
      case 'bottom':
        return css`
          bottom: -10px;
          width: 100%;
          height: 0px;
        `;
      case 'left':
        return css`
          left: -1px;
          height: 100%;
          width: 0px;
        `;
      case 'right':
        return css`
          right: -10px;
          height: 100%;
          width: 0px;
        `;
      case 'top':
      default:
        return css`
          top: -1px;
          width: 100%;
          height: 0px;
        `;
    }
  }}
  flex: 0 0 auto;
  z-index: 10;
`;

type ScrollShadowProps = {
  position?: ShadowPosition;
};

const ScrollShadow = ({ position = 'top' }: ScrollShadowProps) => {
  const [ref, inView] = useInView({});

  return (
    <Container ref={ref} $position={position}>
      <Shadow $inView={inView} $position={position} />
    </Container>
  );
};

export default ScrollShadow;
