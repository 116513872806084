import React from 'react';
import styled, { keyframes } from 'styled-components';

import COLORS from '../styles/colors';

import Button from '../styles/button';
import Spinner from '../styles/spinner';
import Icon, { IconName } from './Icon';

const progress = () => keyframes`
  0% {width:0%}
  10% {width:30%}
  20% {width:35%}
  30% {width:50%}
  40% {width:70%}
  50% {width:75%}
  60% {width:80%}
  70% {width:83%}
  80% {width:88%}
  90% {width:90%}
  100% {width:95%}
`;

const Progress = styled.span`
  height: 100%;
  background: ${COLORS.brand.regular.opacity(0.3)};
`;

type ProgressBarProps = {
  $duration: number;
};
const ProgressBar = styled.div<ProgressBarProps>`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 6px;
  transition: transform 0.2s ease;
  opacity: 0;
  background: ${COLORS.brand.mid.css};
  overflow: hidden;
  display: flex;
  & ${Progress} {
    animation: ${progress} ${(props) => props.$duration}s ease 0s 1 forwards;
  }
`;

const StyledButton = styled(Button)`
  position: relative;
  transition: border-radius 0.2s ease;

  &[aria-busy='true'] {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    ${ProgressBar} {
      transform: translateY(100%);
      opacity: 1;
      border-bottom-left-radius: 3px;
      border-bottom-right-radius: 3px;
    }
  }
`;

type ButtonProgressBarProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  loading: boolean;
  duration: number;
  icon: IconName;
  children?: React.ReactNode;
};

export default function ButtonProgressBar({
  icon,
  loading,
  duration,
  children,
  ...props
}: ButtonProgressBarProps) {
  return (
    <StyledButton {...props} aria-busy={loading}>
      {loading ? (
        <Spinner $color={COLORS.white.css} />
      ) : (
        <Icon name={icon} width={12} height={12} />
      )}
      {children}
      <ProgressBar aria-expanded={loading} $duration={duration}>
        {loading && <Progress />}
      </ProgressBar>
    </StyledButton>
  );
}
