import React, { memo } from 'react';
import styled from 'styled-components';

import type { BlockImageFragment } from '../schemas/api';

import useInview from '../hooks/useInview';

import { staggerChildrenFadeTranslateIn } from '../styles/animations';
import TYPO from '../styles/typography';
import COLORS from '../styles/colors';
import BREAKPOINTS from '../styles/breakpoints';
import { Grid, columns } from '../styles/grid';

import * as textUtils from '../utils/text';
import { spacing } from '../styles/blocks';
import Image from './Image';

type ContainerProps = {
  $spacing: string;
};
const Container = styled.div<ContainerProps>`
  position: relative;
  min-height: 280px;
  height: auto;
  max-height: 100vh;
  width: 100%;
  margin-top: 164px;
  ${(props) => spacing(props.$spacing || '')};
`;

const Content = styled(Grid)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 11;
`;

const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  pointer-events: none;
  user-select: none;
`;

type ContentProps = { $show: boolean };

const HeadingSection = styled.div<ContentProps>`
  ${columns(1, 5)}
  color: ${COLORS.white.css};
  margin-top: 56px;

  ${BREAKPOINTS.max.medium`
    ${columns(1, 12)}
    `}
  ${(props) => staggerChildrenFadeTranslateIn(props.$show)}
`;

const Heading = styled.h1`
  ${TYPO.h2}
`;

const Subheading = styled.div`
  ${TYPO.subheading}
  margin-top:4px;
`;

type BlockImageFullscreenProps = {
  className?: string;
  data: BlockImageFragment;
};

function BlockImageFullscreen({ className, data }: BlockImageFullscreenProps) {
  const [inViewRef, inView] = useInview({});
  return (
    <Container
      className={className}
      $spacing={data.spacing}
      style={{ aspectRatio: `${data.image.width} / ${data.image.height}` }}
    >
      <Background>
        <Image
          src={data.image.url}
          width={data.image.width}
          height={data.image.height}
          alt={data.heading || 'Background Section'}
          priority
          placeholder="blur"
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            pointerEvents: 'none',
          }}
        />
      </Background>
      {data.showHeading && (
        <Content>
          <HeadingSection ref={inViewRef} $show={inView}>
            <Heading>{textUtils.highlight(data.heading)}</Heading>
            {data.byline && (
              <Subheading>{textUtils.highlight(data.byline)}</Subheading>
            )}
          </HeadingSection>
        </Content>
      )}
    </Container>
  );
}

export default memo(BlockImageFullscreen);
