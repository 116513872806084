import React from 'react';
import styled, { css } from 'styled-components';

import { staggerChildrenFadeTranslateIn } from '../styles/animations';
import TYPO from '../styles/typography';
import BREAKPOINTS from '../styles/breakpoints';
import { columns } from '../styles/grid';
import COLORS from '../styles/colors';
import MarkdownInline from './MarkdownInline';

export type HeadingLevel = 'h1' | 'h2' | 'h3';
type Alignment = 'left' | 'center';

type ContainerProps = {
  $show: boolean;
  $headingLevel: HeadingLevel;
  $align: Alignment;
  $spacing: 'default' | 'small' | 'none';
};

const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;

  gap: 12px;
  ${BREAKPOINTS.max.small`
  ${columns(1, 12)}
  `}

  ${(props) => {
    switch (props.$align) {
      case 'left':
        return css`
          ${columns(1, 8)};
          text-align: left;
          align-items: flex-start;
        `;
      default:
        return css`
          ${columns(3, 8)};
          text-align: center;
          align-items: center;
        `;
    }
  }}

  & > h1 {
    ${(props) => {
      switch (props.$headingLevel) {
        case 'h3':
          return TYPO.h3;
        case 'h2':
          return TYPO.h2;
        default:
          return TYPO.h1;
      }
    }}
  }

  & > div {
    ${TYPO.p1};
    color: ${COLORS.text.light.css};
    line-height: 148%;
    max-width: 780px;
    white-space: pre-wrap;
    & strong {
      color: ${COLORS.brand.regular.css};
      font-weight: inherit;
    }
    ${BREAKPOINTS.max.small`
    ${TYPO.p2};
    `}
  }

  & > span {
    ${TYPO.preheading};
    color: ${COLORS.brand.regular.css};
  }

  ${(props) => {
    switch (props.$spacing) {
      case 'none':
        return css`
          margin-bottom: 0;
        `;
      case 'small':
        return css`
          margin-bottom: 12px;
        `;
      case 'default':
      default:
        return css`
          margin-bottom: 36px;
        `;
    }
  }}

  ${(props) => staggerChildrenFadeTranslateIn(props.$show)}
`;

const Line = styled.div`
  display: flex;
  height: 2px;
  width: 72px;
  background: ${COLORS.brand.regular.css};
  margin-bottom: 12px;
`;

type HeadingSectionProps = {
  preHeading?: string;
  heading?: string;
  byline?: string;
  show: boolean;
  headingLevel?: HeadingLevel;
  align?: Alignment;
  line?: boolean;
  spacing?: 'default' | 'small' | 'none';
  style?: React.CSSProperties;
  className?: string;
};

function HeadingSection(
  {
    heading,
    byline,
    show,
    headingLevel = 'h1',
    align = 'center',
    preHeading,
    line,
    spacing = 'default',
    style,
    className,
  }: HeadingSectionProps,
  ref: React.MutableRefObject<HTMLDivElement>
) {
  if (!heading && !byline && !preHeading) return null;
  return (
    <Container
      $show={show}
      ref={ref}
      $headingLevel={headingLevel}
      $align={align}
      $spacing={spacing}
      style={style}
      className={className}
    >
      {preHeading && <span>{preHeading}</span>}
      {line && <Line />}
      {heading && (
        <h1>
          <MarkdownInline>{heading}</MarkdownInline>
        </h1>
      )}
      {byline && (
        <div className="byline">
          <MarkdownInline>{byline}</MarkdownInline>
        </div>
      )}
    </Container>
  );
}

export default React.forwardRef<Element, HeadingSectionProps>(HeadingSection);
