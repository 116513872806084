import React from 'react';
import ReactMarkdown from 'react-markdown';
import styled from 'styled-components';
import rehypeRaw from 'rehype-raw';
import {
  PluggableList,
  ReactMarkdownOptions,
} from 'react-markdown/lib/react-markdown';
import COLORS from '../styles/colors';
import Icon, { IconName } from './Icon';

const StyledStrong = styled.strong`
  color: ${COLORS.brand.regular.css};
  font-weight: inherit;
`;

const MarkdownInline = ({
  children,
  ...props
}: ReactMarkdownOptions & { children: string }) => {
  return (
    <ReactMarkdown
      rehypePlugins={[rehypeRaw] as PluggableList}
      components={{
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        p: ({ node, ...props }) => <React.Fragment {...props} />,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        strong: ({ node, ...props }) => {
          return <StyledStrong {...props} />;
        },
        a: ({ node, ...props }) => <a {...props} target="_blank" />,
        i: ({ node, ...props }) => {
          return (
            <Icon
              name={props['data-name'] as IconName}
              width={12}
              height={12}
            />
          );
        },
      }}
      {...props}
    >
      {children}
    </ReactMarkdown>
  );
};

export default MarkdownInline;
