import React, { memo } from 'react';
import styled, { css } from 'styled-components';

import type { BlockPricingFragment } from '../schemas/api';

import useInview from '../hooks/useInview';

import TYPO from '../styles/typography';
import BREAKPOINTS from '../styles/breakpoints';
import { Grid, columns } from '../styles/grid';
import Heading from './Heading';
import COLORS from '../styles/colors';
import Icon from './Icon';
import Button from '../styles/button';
import LinkJSX from './Link';

const HIGHLIGHT_OFFSET = 24;
const ROW_HEIGHT = 60;
const ROW_HEIGHT_MOBILE = 60;

const Container = styled(Grid)`
  margin-top: 180px;
`;

const Table = styled.div`
  ${columns(1, 12)};
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const CellStyle = css`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  padding: 6px;

  margin: 0;
`;

type CellProps = {
  $highlight?: boolean;
  $first?: boolean;
  $last?: boolean;
};

const Cell = styled.div<CellProps>`
  ${CellStyle}
  flex:1;
  color: ${COLORS.shades.s300.css};

  overflow: hidden;
  position: relative;
  z-index: 0;
  ${(props) => {
    if (props.$highlight) {
      return css`
        border-left: 1px solid ${COLORS.brand.regular.css};
        border-right: 1px solid ${COLORS.brand.regular.css};
        ${props.$first &&
        css`
          margin-top: -${HIGHLIGHT_OFFSET}px;
          height: calc(100% + ${HIGHLIGHT_OFFSET}px);
          border-radius: 9px 9px 0 0;
          padding-top: ${HIGHLIGHT_OFFSET}px;
          border-top: 1px solid ${COLORS.brand.regular.css};
        `}
        ${props.$last &&
        css`
          height: calc(100% + ${HIGHLIGHT_OFFSET}px);
          border-radius: 0 0 9px 9px;
          border-bottom: 1px solid ${COLORS.brand.regular.css};
          padding-bottom: ${HIGHLIGHT_OFFSET}px;
        `}
        background: ${COLORS.white.css};
      `;
    }
    return css``;
  }}

  ${TYPO.p3}
  ${BREAKPOINTS.max.small`
  font-size:11px;
`}
`;

const HeaderCell = styled.div`
  ${CellStyle}
  justify-content:flex-start;
  gap: 6px;
  width: 250px;
  flex: 0 0 auto;
  text-align: left;
  padding: 6px 12px;
  ${TYPO.strong}

  ${BREAKPOINTS.max.small`
  width: 120px;
`}
`;

const CheckIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: ${COLORS.success.bright.css};
  color: ${COLORS.white.css};
`;

const TableHeader = styled.div`
  display: flex;
  ${TYPO.h3};
  font-size: 13px;
  color: ${COLORS.brand.regular.css};
  padding: 3px 6px;

  ${BREAKPOINTS.max.small`
  font-size:14px
`}
`;

const TableBody = styled.div`
  display: flex;
  flex-direction: column;
`;

const Row = styled.div`
  display: flex;
  position: relative;
  margin: 0;
  border: 1px solid ${COLORS.shades.s200.css};
  border-bottom: 0;
  background: ${COLORS.white.css};
  height: ${ROW_HEIGHT}px;
  &:first-child {
    border-radius: 9px 9px 0 0;
  }
  &:last-child {
    border-radius: 0 0 9px 9px;
    border-bottom: 1px solid ${COLORS.shades.s200.css};
  }
  &:only-child {
    border-radius: 9px;
  }
  &:hover {
    background: ${COLORS.shades.s100.mix(COLORS.white, 0.7) as string};
    & ${Cell} {
      background: ${COLORS.shades.s100.mix(COLORS.white, 0.7) as string};
    }
  }

  ${BREAKPOINTS.max.small`
  height: ${ROW_HEIGHT_MOBILE}px;
`}
`;

const Plans = styled.div`
  display: flex;
  ${columns(3, 8)};
  margin-bottom: 24px;
  ${BREAKPOINTS.max.small`
  ${columns(1, 12)};
`}
`;

const Tables = styled.div`
  display: flex;
  flex-direction: column;
  ${columns(3, 8)};
  ${BREAKPOINTS.max.small`
  ${columns(1, 12)};
`}
  gap: 48px;
`;

const Plan = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6px;
  & > a {
    margin-top: 12px;
    cursor: pointer;
    pointer-events: all;
  }
`;

const PlanName = styled.div`
  width: 100%;
  ${TYPO.p1};
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  text-align: center;
  border-bottom: 1px solid ${COLORS.shades.s200.css};
  padding: 3px;
  ${BREAKPOINTS.max.small`
font-size:12px;
`}
`;

const PlanPrice = styled.div`
  padding: 6px 3px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  & > div {
    ${TYPO.h2};
    font-weight: 500;
  }
  & > span {
    ${TYPO.h3};
    font-size: 14px;
    font-weight: 500;
    color: ${COLORS.shades.s300.css};
  }
  letter-spacing: 0.05em;
  text-align: center;
  ${BREAKPOINTS.max.small`
  & > span {
    font-size:14px;
  }
  `}
`;

type Column = {
  name: string;
  description?: string;
  price?: { prefix?: string; value: number; suffix?: string };
  cta?: { label: string; link: string };
};

type SubTable = {
  name: string;
  rows: string[];
  content: Record<string, string | boolean | null>;
};

type TablePricing = {
  columns: Column[];
  content: SubTable[];
};
type BlockPricingProps = {
  data: BlockPricingFragment;
};
function BlockPricing({ data }: BlockPricingProps) {
  const [inViewRef, inView] = useInview({});
  const table: TablePricing = data.data;

  if (!table) return null;

  return (
    <Container>
      <Heading
        heading={data.heading}
        byline={data.byline}
        show={inView}
        ref={inViewRef}
      />
      <Plans>
        <HeaderCell />
        {Array.isArray(table.columns) &&
          table.columns.map((d) => {
            return (
              <Plan key={d.name}>
                <PlanName>{d.name}</PlanName>
                {d.price && (
                  <PlanPrice>
                    <div>{`${d.price.prefix}${d.price.value}`}</div>
                    <span>{d.price.suffix}</span>
                  </PlanPrice>
                )}
                {d.cta && (
                  <LinkJSX
                    link={{
                      label: d.cta.label,
                      url: d.cta.link,
                      type: 'external',
                    }}
                  >
                    <Button $variant="secondary" $shape="rounded">
                      {d.cta.label}
                    </Button>
                  </LinkJSX>
                )}
              </Plan>
            );
          })}
      </Plans>
      <Tables>
        {Array.isArray(table.content) &&
          table.content.map((item) => {
            return (
              <Table key={item.name}>
                <TableHeader>{item.name}</TableHeader>
                <TableBody>
                  {Array.isArray(item.rows) &&
                    item.rows.map((row, index) => {
                      return (
                        <Row key={row}>
                          <HeaderCell>{row}</HeaderCell>
                          {table.columns.map((col) => {
                            const cell = item.content[col.name]?.[index];

                            const key = `item-${col.name}-${row}-${cell}`;

                            if (cell === false) {
                              return (
                                <Cell key={key}>
                                  <Icon
                                    name="X"
                                    width={18}
                                    height={18}
                                    style={{ color: COLORS.error.regular.css }}
                                  />
                                </Cell>
                              );
                            }
                            if (cell === true) {
                              return (
                                <Cell key={key}>
                                  <CheckIconWrapper>
                                    <Icon name="Check" width={12} height={12} />
                                  </CheckIconWrapper>
                                </Cell>
                              );
                            }

                            if (cell === null) {
                              return (
                                <Cell key={key}>
                                  <Icon name="Minus" width={12} height={12} />
                                </Cell>
                              );
                            }
                            return (
                              <Cell key={key}>
                                <span>{cell}</span>
                              </Cell>
                            );
                          })}
                        </Row>
                      );
                    })}
                </TableBody>
              </Table>
            );
          })}
      </Tables>
    </Container>
  );
}

export default memo(BlockPricing);
