import React, { memo } from 'react';
import styled from 'styled-components';

import type { BlockQuoteFragment } from '../schemas/api';

import Image from './Image';

import useInview from '../hooks/useInview';

import TYPO from '../styles/typography';
import COLORS from '../styles/colors';
import BREAKPOINTS from '../styles/breakpoints';
import { Grid, columns } from '../styles/grid';

const Container = styled(Grid)`
  margin-top: 180px;
`;

const Inner = styled.div`
  ${columns(3, 8)}
  text-align: left;
  display: flex;
  gap: 24px;
  ${BREAKPOINTS.max.medium`
    ${columns(1, 12)}
    `}
`;

const QuoteText = styled.div`
  ${TYPO.subheading}
  color:${COLORS.shades.s500.css};
  &:before {
    content: '“';
  }
  &:after {
    content: '”';
  }
`;

type PictureColumnProps = {
  $show: boolean;
};

const PictureColumn = styled.div<PictureColumnProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  width: 190px;

  opacity: ${(props) => (props.$show ? 1 : 0)};
  transition: opacity 0.33s ease;
  transition-delay: 0.5s;

  ${BREAKPOINTS.max.small`
      width: 100px;
    `}
`;

const PictureContainer = styled.div`
  width: 100%;
  border-radius: 5px;
  overflow: hidden;
`;

type QuoteContainerProps = {
  $show: boolean;
};

const QuoteContainer = styled.div<QuoteContainerProps>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  flex: 1;
  gap: 18px;

  opacity: ${(props) => (props.$show ? 1 : 0)};
  transition: opacity 0.33s ease;
  transition-delay: 0.5s;
`;

const CompanyLogoContainer = styled.div`
  max-width: 100%;
  width: 120px;
`;

const Source = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const Name = styled.div`
  ${TYPO.p1}
  font-weight: bold;
  color: ${COLORS.black.css};
`;

const Role = styled.div`
  ${TYPO.subheading}
  color: ${COLORS.shades.s400.css}
`;

type QuoteProps = {
  data: BlockQuoteFragment;
};
function Quote({ data }: QuoteProps) {
  const [inViewRef, inView] = useInview({});

  return (
    <Container ref={inViewRef}>
      <Inner>
        <PictureColumn $show={inView}>
          <PictureContainer>
            <Image
              src={data.picture.url}
              alt={data.source}
              height={data.picture.height}
              width={data.picture.width}
            />
          </PictureContainer>
          {data.companyLogo && (
            <CompanyLogoContainer>
              <Image
                src={data.companyLogo.url}
                alt={data.source}
                height={data.companyLogo.height}
                width={data.companyLogo.width}
                style={{ width: '100%', height: 'auto' }}
              />
            </CompanyLogoContainer>
          )}
        </PictureColumn>
        <QuoteContainer $show={inView}>
          <QuoteText>{data.quote}</QuoteText>
          <Source>
            <Name>{data.source}</Name>
            {data.role && <Role>{data.role}</Role>}
          </Source>
        </QuoteContainer>
      </Inner>
    </Container>
  );
}

export default memo(Quote);
