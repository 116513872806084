import React, { memo } from 'react';
import styled, { css } from 'styled-components';

import type { BlockPreFooterFragment } from '../schemas/api';

import useInview from '../hooks/useInview';

import { staggerChildrenFadeTranslateIn } from '../styles/animations';
import COLORS from '../styles/colors';
import TYPO from '../styles/typography';
import BREAKPOINTS from '../styles/breakpoints';
import { Grid, columns, grid } from '../styles/grid';

import Button from '../styles/button';
import LinkJSX from './Link';
import Icon, { IconName } from './Icon';

const Container = styled(Grid)`
  margin-top: 80px;
`;

type ContentProps = { $show: boolean };
const Content = styled.div<ContentProps>`
  ${columns(1, 12)};
  ${grid(12)};
  gap: 24px;
  width: 100%;
  ${(props) => staggerChildrenFadeTranslateIn(props.$show)}
`;

type ItemProps = {
  $variant: string;
};

const Item = styled.div<ItemProps>`
  ${columns(null, 6)}

  ${BREAKPOINTS.max.small`
    ${columns(null, 12)}
  `}
  display: flex;
  flex-direction: column;
  gap: 24px;
  min-height: 200px;
  border: 1px solid ${COLORS.shades.s200.css};
  padding: 24px;
  border-radius: 9px;

  ${(props) => {
    switch (props.$variant) {
      case 'Dark':
        return css`
          color: ${COLORS.white.css};
          background-color: ${COLORS.shades.s400.css};
          & button {
            border-color: ${COLORS.white.css};
            color: ${COLORS.white.css};
            &:hover {
              background-color: ${COLORS.black.opacity(0.2)};
            }
          }
          & p {
            color: ${COLORS.white.css};
          }
        `;
      default:
        return css`
          color: ${COLORS.black.css};
          background-color: ${COLORS.shades.s100.mix(
            COLORS.white,
            0.7
          ) as string};
        `;
    }
  }}
`;

const ButtonContainer = styled.div`
  flex: 0 0 auto;
  margin-top: auto;
  display: flex;
  gap: 0px;
`;

const Name = styled.div`
  ${TYPO.h2};
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3px;
`;

const Description = styled.p`
  margin: 0;
  max-width: 80%;
  ${TYPO.p2}
  ${BREAKPOINTS.max.extraSmall`
  ${TYPO.p2};
  max-width: 100%;
  `}
`;

type BlockPreFooterProps = {
  data: BlockPreFooterFragment;
};

function BlockPreFooter({ data }: BlockPreFooterProps) {
  const [inViewRef, inView] = useInview({});
  return (
    <Container>
      <Content $show={inView} ref={inViewRef}>
        {data.items?.items.map((d) => {
          return (
            <Item key={d.sys.id} $variant={d.theme}>
              <Info>
                <Name>{d.label}</Name>
                {d.description && <Description>{d.description}</Description>}
              </Info>
              <ButtonContainer>
                <LinkJSX link={d.link}>
                  <Button $variant="secondary" $shape="rounded">
                    <Icon
                      name={d.link.icon as IconName}
                      width={12}
                      height={12}
                    />
                    {d.link.label}
                  </Button>
                </LinkJSX>
              </ButtonContainer>
            </Item>
          );
        })}
      </Content>
    </Container>
  );
}

export default memo(BlockPreFooter);
