import React, { memo } from 'react';
import styled from 'styled-components';

import type { BlockLogoFragment, CompanyFragment } from '../schemas/api';

import useInview from '../hooks/useInview';
import Image from './Image';

import * as textUtils from '../utils/text';

import { staggerChildrenFadeTranslateIn } from '../styles/animations';
import TYPO from '../styles/typography';
import BREAKPOINTS from '../styles/breakpoints';
import COLORS from '../styles/colors';
import { Grid, columns } from '../styles/grid';

const Container = styled(Grid)`
  padding: 180px 12px 24px;
  ${BREAKPOINTS.max.medium`
    padding: 72px 12px 24px;
  `}
`;

type HeadingSectionProps = { $show: boolean };
const HeadingSection = styled.div<HeadingSectionProps>`
  ${columns(3, 8)}
  text-align: center;
  ${BREAKPOINTS.max.small`
    ${columns(1, 12)}
    `}

  ${(props) => staggerChildrenFadeTranslateIn(props.$show)}
`;

const Heading = styled.h1`
  ${TYPO.h2}
`;

const Subheading = styled.div`
  ${TYPO.subheading}
  margin-top: 4px;
`;

const Integrations = styled.div`
  margin-top: 36px;
  ${columns(1, 12)};
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const List = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  position: relative;
  padding-bottom: 18px;
  gap: 24px;
`;

const ImageContainer = styled.div`
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Item = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  min-width: 80px;
  ${TYPO.caption};
  color: ${COLORS.text.regular.css};
  gap: 12px;
  padding: 12px 6px;
  & img {
    width: 100%;
  }
`;

type HomeIntegrationsProps = {
  data: BlockLogoFragment;
};

function HomeIntegrations({ data }: HomeIntegrationsProps) {
  const [inViewRef, inView] = useInview({});

  return (
    <Container>
      <HeadingSection ref={inViewRef} $show={inView}>
        <Heading>{textUtils.highlight(data.heading)}</Heading>
        {data.byline && <Subheading>{data.byline}</Subheading>}
      </HeadingSection>
      <Integrations>
        <List>
          {data.logos.items.map((company: CompanyFragment) => {
            return (
              <Item key={company.name}>
                <ImageContainer>
                  <Image
                    src={company.logo.url}
                    alt={company.name}
                    width={company.logo.width}
                    height={company.logo.height}
                    style={{ width: '100%', height: 'auto' }}
                  />
                </ImageContainer>
                <span>{company.name}</span>
              </Item>
            );
          })}
        </List>
      </Integrations>
    </Container>
  );
}

export default memo(HomeIntegrations);
