const FREEMAIL_DOMAINS = [
  'gmail.com',
  'icloud.com',
  'hotmail.com',
  'yahoo.com',
  'aol.com',
  'outlook.com',
  'live.com',
  'protonmail.com',
];

export const isFreeEmail = (email: string) => {
  return FREEMAIL_DOMAINS.some((domain) => email.endsWith(`@${domain}`));
};
