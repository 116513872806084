import COLORS from '../styles/colors';

import { Avatar, DEFAULT_AVATAR_SETTINGS } from '../components/AnimatedAvatar';

import Excel from '../assets/softwares/excel-logo.svg';
import Matlab from '../assets/softwares/matlab-logo.png';
import Python from '../assets/softwares/python.svg';

export enum NodeType {
  COMMENT = 'comment',
  AVATAR = 'avatar',
  DIAMOND = 'diamond',
  CIRCLE = 'circle',
  GENERIC = 'generic',
  IMAGE = 'image',
}

export type Node = {
  type: NodeType;
  id: string;
  avatar?: Avatar;
  image?: string;
  color?: string;
};

type CollaborationData = {
  circles: {
    id: string;
    radius: number;
    nodes: Node[];
  }[];
};

export const collaboration: CollaborationData = {
  circles: [
    {
      id: 'inner',
      radius: 125,
      nodes: [
        { type: NodeType.GENERIC, id: 'A' },
        {
          type: NodeType.IMAGE,
          id: 'B',
          image: Excel,
          color: COLORS.rainbow.green.regular.css,
        },
        { type: NodeType.GENERIC, id: 'C' },
        {
          type: NodeType.IMAGE,
          id: 'D',
          image: Matlab.src,
          color: COLORS.rainbow.orange.light.css,
        },
        {
          type: NodeType.IMAGE,
          id: 'E',
          image: Python,
          color: COLORS.rainbow.yellow.light.css,
        },
      ],
    },
    {
      id: 'outer',
      radius: 200,
      nodes: [
        { type: NodeType.GENERIC, id: 'F' },
        {
          type: NodeType.AVATAR,
          id: 'G',
          avatar: {
            ...DEFAULT_AVATAR_SETTINGS,
            clothing: { style: 2, color: COLORS.rainbow.yellow.light.css },
            lips: true,
            hair: { style: 1, ponytail: true },
          },
        },
        { type: NodeType.GENERIC, id: 'H' },
        {
          type: NodeType.AVATAR,
          id: 'M',
          avatar: {
            ...DEFAULT_AVATAR_SETTINGS,
            clothing: { style: 5, color: COLORS.rainbow.purple.light.css },
            hair: { style: 5 },
            eyebrows: { ...DEFAULT_AVATAR_SETTINGS.eyebrows, width: 4 },
          },
        },
        { type: NodeType.GENERIC, id: 'I' },
        {
          type: NodeType.AVATAR,
          id: 'J',
          avatar: {
            ...DEFAULT_AVATAR_SETTINGS,
            clothing: { style: 3, color: COLORS.rainbow.green.regular.css },
            lips: true,
            skin: String(COLORS.warn.dark.mix(COLORS.white, 0.5)),
            hair: { style: 3 },
            glasses: true,
            earrings: true,
          },
        },
        {
          type: NodeType.AVATAR,
          id: 'K',
          avatar: {
            ...DEFAULT_AVATAR_SETTINGS,
            clothing: { style: 1, color: COLORS.rainbow.cyan.regular.css },
            hair: { style: 4 },
          },
        },
      ],
    },
  ],
};

type SyncData = {
  radius: number;
  people: { id: string; avatar: Avatar }[];
};

export const sync: SyncData = {
  radius: 200,
  people: [
    {
      id: 'one',
      avatar: {
        ...DEFAULT_AVATAR_SETTINGS,
        clothing: { style: 1, color: COLORS.rainbow.purple.light.css },
        hair: { style: 4 },
      },
    },
    {
      id: 'two',
      avatar: {
        ...DEFAULT_AVATAR_SETTINGS,
        clothing: { style: 3, color: COLORS.rainbow.yellow.light.css },
        lips: true,
        skin: String(COLORS.warn.dark.mix(COLORS.white, 0.5)),
        hair: { style: 3 },
        glasses: true,
        earrings: true,
      },
    },
    {
      id: 'three',
      avatar: {
        ...DEFAULT_AVATAR_SETTINGS,
        clothing: { style: 2, color: COLORS.rainbow.green.regular.css },
        lips: true,
        hair: { style: 1, ponytail: true },
      },
    },
  ],
};

type ContinuousIntegrationData = {
  radius: number;
  radiusChief: number;
  chief: { id: string; avatar: Avatar };
  people: { id: string; avatar: Avatar }[];
};

export const continuousIntegration: ContinuousIntegrationData = {
  radius: 200,
  radiusChief: 150,
  chief: {
    id: 'chief',
    avatar: {
      ...DEFAULT_AVATAR_SETTINGS,
      clothing: { style: 1, color: COLORS.rainbow.cyan.regular.css },
      hair: { style: 4 },
    },
  },
  people: [
    {
      id: 'one',

      avatar: {
        ...DEFAULT_AVATAR_SETTINGS,
        clothing: { style: 5, color: COLORS.rainbow.purple.light.css },
        hair: { style: 5 },
        eyebrows: { ...DEFAULT_AVATAR_SETTINGS.eyebrows, width: 4 },
      },
    },
    {
      id: 'two',
      avatar: {
        ...DEFAULT_AVATAR_SETTINGS,
        clothing: { style: 3, color: COLORS.rainbow.yellow.light.css },
        lips: true,
        skin: String(COLORS.warn.dark.mix(COLORS.white, 0.5)),
        hair: { style: 3 },
        glasses: true,
        earrings: true,
      },
    },
    {
      id: 'three',
      avatar: {
        ...DEFAULT_AVATAR_SETTINGS,
        clothing: { style: 2, color: COLORS.rainbow.green.regular.css },
        lips: true,
        hair: { style: 1, ponytail: true },
      },
    },
  ],
};
