import React, {
  ForwardedRef,
  forwardRef,
  memo,
  useCallback,
  useRef,
  useState,
} from 'react';
import styled, { css } from 'styled-components';

import { useInView } from 'react-intersection-observer';

import { useEvent } from 'react-use';

import { mergeRefs } from 'react-merge-refs';

import { useMediaQuery } from 'usehooks-ts';
import type {
  BlockMediaCollectionFragment,
  MediaItemFragment,
} from '../schemas/api';

import { Grid, columns } from '../styles/grid';

import MarkdownInline from './MarkdownInline';

import COLORS from '../styles/colors';
import Heading from './Heading';
import { clamp, lerp } from '../utils/math';
import ELEVATION from '../styles/elevation';
import Image from './Image';

const NAVBAR_HEIGHT = 60;
const ITEM_SPEED = 600;
const THRESHOLD = 0.01;

type ContainerProps = {
  $height: number;
};

const Container = styled(Grid)<ContainerProps>`
  width: 100%;
  @media (min-width: 768px) {
    height: ${(props) => props.$height}px;
  }
  height: auto;
  & > div {
    width: 100%;
    padding: 36px 0;
    position: sticky;
    height: fit-content;
    background: ${COLORS.brand.light.mix(COLORS.white, 0.5) as string};
    overflow: hidden;
    border-radius: 9px;
  }
`;

const StyledHeading = styled(Heading)`
  padding: 0 24px;
  & > h1 {
    font-weight: 400;
    & strong {
      font-weight: 600;
    }
  }

  @media (min-width: 380px) {
    padding: 0 36px;
  }
`;

const CarouselContainer = styled.div`
  ${columns(1, 12)};
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

type PillProps = {
  $active: boolean;
};

const Pill = styled.button<PillProps>`
  padding: 3px 6px;
  color: white;
  border-radius: 3px;
  position: relative;
  z-index: 2;
  border: 1px solid ${COLORS.black.css};
  ${(props) => {
    if (props.$active) {
      return css`
        background: ${COLORS.black.css};
        color: ${COLORS.white.css};
        &:hover {
          background: ${COLORS.shades.s400.css};
        }
        &:active {
          background: ${COLORS.shades.s400.mix(COLORS.white, 0.05) as string};
        }
        border-color: ${COLORS.black.css};
      `;
    }
    return css`
      background: ${COLORS.brand.light.mix(COLORS.white, 0.5) as string};
      color: ${COLORS.black.css};
      &:hover {
        background: ${COLORS.brand.light.mix(COLORS.white, 0.04) as string};
      }
      &:active {
        background: ${COLORS.brand.light.mix(COLORS.white, 0.3) as string};
      }
      border-color: ${COLORS.black.opacity(0.1) as string};
    `;
  }}
`;

const ProgressBarContainer = styled.div`
  display: none;

  justify-content: space-between;
  position: relative;
  margin: 0 24px;

  @media (min-width: 420px) {
    margin: 0 36px;
    display: flex;
  }
`;

const ProgressBar = styled.div`
  height: 2px;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto 0;
  & > span {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    &:first-child {
      background: black;
    }
    &:last-child {
      background: rgba(0, 0, 0, 0.1);
    }
  }
`;

const Items = styled.div`
  position: relative;
  width: 100%;

  display: flex;
  gap: 24px;

  padding: 0 24px;

  @media (min-width: 380px) {
    padding: 0 36px;
  }

  @media (min-width: 768px) {
    overflow: visible;
  }

  scroll-snap-type: x mandatory;
  overflow: auto;
`;

const Item = styled.div`
  scroll-snap-align: center;

  background: white;

  border-radius: 6px;
  ${ELEVATION.s200};
  display: flex;
  flex-flow: column;
  gap: 24px;

  max-width: 800px;
  overflow: hidden;

  position: relative;
  & > h4 {
    min-height: 42px;
    margin: 0;
    padding-right: 40px;
    max-width: 620px;
    width: 100%;
    & strong {
      font-weight: 700;
      color: black;
    }

    @media (min-width: 768px) {
      font-size: 16px;
    }
  }

  flex: 0 0 95%;
  min-height: 300px;
  padding: 12px 0px 0px 12px;
  aspect-ratio: 1 / 1;

  @media (min-width: 380px) {
    flex: 0 0 80%;
    min-height: 300px;
    padding: 24px 0px 0px 24px;
    aspect-ratio: 1 / 1;
  }

  @media (min-width: 768px) {
    flex: 0 0 70%;
    padding: 35px 0px 0px 40px;
    aspect-ratio: 16 / 9;
  }
`;

const ItemImage = styled(Image)`
  aspect-ratio: 40 / 17;
  border-top-left-radius: 12px;
  display: block;
  object-fit: cover;
  object-position: right top;
  width: 100%;
  flex: 1;
  border: 1px solid ${COLORS.brand.light.css};
`;
type CarouselProps = {
  items: MediaItemFragment[];
  scrollTo: (target: number) => void;
  progress: number;
};

const Carousel = forwardRef(
  (
    { items, progress, scrollTo }: CarouselProps,
    forwardedRef: ForwardedRef<HTMLDivElement>
  ) => {
    return (
      <CarouselContainer>
        <ProgressBarContainer>
          {items.map((d, i) => {
            const position = i / (items.length - 1);
            return (
              <Pill
                key={d.sys.id}
                $active={progress + THRESHOLD >= position}
                onClick={() => scrollTo(position)}
              >
                {d.heading}
              </Pill>
            );
          })}
          <ProgressBar>
            <span style={{ width: `${progress * 100}%` }} />
            <span />
          </ProgressBar>
        </ProgressBarContainer>
        <Items ref={forwardedRef}>
          {items.map((d) => {
            return (
              <Item key={d.sys.id}>
                <h4>
                  <MarkdownInline>{d.content}</MarkdownInline>
                </h4>
                <ItemImage
                  src={d.media.url}
                  alt={d.media.title}
                  width={d.media.width}
                  height={d.media.height}
                />
              </Item>
            );
          })}
        </Items>
      </CarouselContainer>
    );
  }
);

Carousel.displayName = 'Carousel';

const getStickyElement = (
  element: HTMLDivElement
): HTMLDivElement | null | undefined => {
  return element.children[0] as HTMLDivElement | null | undefined;
};

const verticallyCenter = (element: HTMLDivElement): number => {
  return (NAVBAR_HEIGHT + (window.innerHeight - element.offsetHeight)) / 2;
};

type BlockHorizontalScrollCarouselProps = {
  data: BlockMediaCollectionFragment;
};

function BlockHorizontalScrollCarousel({
  data,
}: BlockHorizontalScrollCarouselProps) {
  const isDesktop = useMediaQuery('(min-width: 768px)', {
    initializeWithValue: false,
    defaultValue: true,
  });

  const ref = useRef<HTMLDivElement | null>(null);
  const progressRef = useRef<HTMLDivElement>(null);
  const [progress, setProgress] = useState(0);

  const { ref: inViewRef } = useInView({ threshold: 0.1 });

  const scrollToPercentage = useCallback(
    (target: number) => {
      if (isDesktop) {
        if (!ref.current) return;
        const stickyElement = getStickyElement(ref.current);
        if (!stickyElement) return;

        const middlePosition = verticallyCenter(stickyElement);

        const { offsetTop, offsetHeight } = ref.current;
        const start = offsetTop - middlePosition;
        const end = start + offsetHeight - stickyElement.offsetHeight;
        const position = lerp(start, end, target);

        window.scrollTo({ top: position, behavior: 'smooth' });
      } else {
        if (!progressRef.current) return;

        const { offsetWidth, scrollWidth } = progressRef.current;
        const end = scrollWidth - offsetWidth;
        const position = lerp(0, end, target);
        progressRef.current.scrollTo({ left: position, behavior: 'smooth' });
      }
    },
    [isDesktop]
  );

  const onWindowScroll = useCallback(() => {
    if (!isDesktop) return;
    if (!ref.current) return;
    if (!progressRef.current) return;

    const stickyElement = getStickyElement(ref.current);
    if (!stickyElement) return;

    const middlePosition = verticallyCenter(stickyElement);
    stickyElement.style.top = `${Math.floor(middlePosition)}px`;

    const { top, height } = ref.current.getBoundingClientRect();

    const scrollTop = Math.max(0, middlePosition - top);
    const scroll = scrollTop / (height - stickyElement.offsetHeight);
    const progress = clamp(scroll, 0, 1);

    const { scrollWidth = 0, offsetWidth = 0 } = progressRef.current;
    const { paddingRight = '0' } = getComputedStyle(progressRef.current);

    const translate =
      (scrollWidth - offsetWidth + parseFloat(paddingRight)) * progress;

    progressRef.current.style.transform = `translateX(-${translate}px)`;

    setProgress(progress);
  }, [isDesktop]);

  const onSectionScroll = useCallback(() => {
    if (isDesktop) return;
    if (!progressRef.current) return;
    const { offsetWidth, scrollWidth, scrollLeft } = progressRef.current;
    const progress = scrollLeft / (scrollWidth - offsetWidth);
    progressRef.current.style.transform = `translateX(0px)`;
    setProgress(clamp(progress, 0, 1));
  }, [isDesktop]);

  const onResize = useCallback(() => {
    if (isDesktop) onWindowScroll();
    else onSectionScroll();
  }, [isDesktop, onWindowScroll, onSectionScroll]);

  useEvent('scroll', onWindowScroll);
  useEvent('scroll', onSectionScroll, progressRef.current);
  useEvent('resize', onResize);

  return (
    <Container
      ref={mergeRefs([ref, inViewRef])}
      $height={data.mediaCollection.items.length * ITEM_SPEED}
      data-progress={isDesktop ? progress : 0}
    >
      <StyledHeading
        heading={data.heading}
        show
        align="left"
        headingLevel="h2"
      />
      <Carousel
        items={data.mediaCollection.items}
        ref={progressRef}
        progress={progress}
        scrollTo={scrollToPercentage}
      />
    </Container>
  );
}

export default memo(BlockHorizontalScrollCarousel);
