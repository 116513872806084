import React, { memo } from 'react';
import styled from 'styled-components';

import ReactPlayer from 'react-player';
import COLORS from '../styles/colors';

import { Grid, columns } from '../styles/grid';
import BREAKPOINTS from '../styles/breakpoints';
import Heading from './Heading';
import useInview from '../hooks/useInview';
import { BlockDemoFormFragment } from '../schemas/api';
import { spacing } from '../styles/blocks';
import useTypeformScript from '../hooks/useTypeformScript';

type ContainerProps = {
  $spacing: string;
};

const Container = styled(Grid)<ContainerProps>`
  ${(props) => spacing(props.$spacing || '')};
  padding: 0 12px;
  min-height: 100vh;
`;

const Left = styled.div`
  ${columns(1, 8)};
  display: flex;
  flex-direction: column;

  ${BREAKPOINTS.max.medium`
  ${columns(3, 8)}
  `}

  ${BREAKPOINTS.max.small`
  ${columns(1, 12)}
  padding: 12px;
  `}
`;

const MediaContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  & > div {
    margin: 0;
    padding: 0;
    display: flex;
    border-radius: 6px;
    overflow: hidden;
    border: 1px solid ${COLORS.shades.s200.css};
    box-shadow: 0px 0px 1px ${COLORS.brand.regular.opacity(0.2)},
      0px 4px 100px ${COLORS.brand.regular.opacity(0.1)};
    & > video {
      margin: 0;
      padding: 0;
    }
  }

  margin-bottom: 24px;
`;

const FormContainer = styled.div`
  ${columns(9, 4)};
  display: flex;
  flex-direction: column;

  background: ${COLORS.white.css};
  border: 1px solid ${COLORS.shades.s200.css};
  border-radius: 9px;
  height: fit-content;

  box-shadow: 0 0 200px ${COLORS.brand.regular.opacity(0.03)};
  ${BREAKPOINTS.max.medium`
  ${columns(1, 12)};
  `}

  ${BREAKPOINTS.max.small`
  margin-top: 6px;
  `}
`;

type BlockDemoFormProps = {
  data: BlockDemoFormFragment;
};

function BlockDemoForm({ data }: BlockDemoFormProps) {
  useTypeformScript();
  const [inViewRef, inView] = useInview({});

  const { media } = data;
  return (
    <Container $spacing={data.spacing}>
      <Heading
        show={inView}
        ref={inViewRef}
        heading={data.heading}
        byline={data.byline}
      />
      <Left>
        {media && (
          <MediaContainer>
            {media.contentType.startsWith('video/') && (
              <ReactPlayer
                width={media.width}
                height={media.height}
                url={media.url}
                className="videoContainer"
                playing={true}
                controls={false}
                loop
                playsinline
                muted={true}
              />
            )}
          </MediaContainer>
        )}
      </Left>

      <FormContainer>
        <div data-tf-live={data.typeformFormId}></div>
      </FormContainer>
    </Container>
  );
}

export default memo(BlockDemoForm);
