import Papa from 'papaparse';
import { memo, useContext, useRef, useState } from 'react';
import styled from 'styled-components';

import { SubmitHandler, useForm } from 'react-hook-form';
import type { BlockAiFragment } from '../schemas/api';

import useInview from '../hooks/useInview';

import BREAKPOINTS from '../styles/breakpoints';
import COLORS from '../styles/colors';
import { Grid, columns } from '../styles/grid';
import Spinner from '../styles/spinner';
import TYPO from '../styles/typography';

import Button from '../styles/button';
import { ErrorMessage, Form, Input, Label } from '../styles/form';
import Heading from './Heading';
import Icon, { IconName } from './Icon';

import { spacing } from '../styles/blocks';
import API from '../utils/aiGen';
import { emailRegex } from '../utils/form';
import { getEnvironment, scrollIntoViewWithOffset } from '../utils/window';
import { AnalyticsContext } from './AnalyticsProvider';
import DisplayTable from './DisplayTable';

import ENDPOINTS from '../../endpoints.json';
import FormAiPrompt from './FormAiPrompt';
import AnimationAi from './AnimationAi';
import { ErrorApi } from '../utils/errors';
import MarkdownInline from './MarkdownInline';
import markdown from '../styles/markdown';
import { isFreeEmail } from '../utils/account';

type ContainerProps = {
  $spacing: string;
};

const Container = styled(Grid)<ContainerProps>`
  ${(props) => spacing(props.$spacing || '')};
  position: relative;
`;

const FormAiContainer = styled.div`
  ${columns(1, 6)};
  ${BREAKPOINTS.max.medium`
  ${columns(1, 12)};
  order:2;
  `}
`;

const Visual = styled.div`
  height: 100%;
  max-height: 500px;
  margin: 0 auto;
  width: 100%;
  ${columns(7, 6)};
  ${BREAKPOINTS.max.medium`
    height: 500px;
    order:1;
    ${columns(4, 6)};
`}
  ${BREAKPOINTS.max.small`
    margin-bottom:48px;
    height: 80vw;
    order:1;
  ${columns(2, 10)};
`}
${BREAKPOINTS.max.extraSmall`
  ${columns(1, 12)};
`}
`;

const Response = styled(Grid)`
  margin-top: 120px;
`;

const Content = styled.div`
  ${columns(1, 8)};
  ${BREAKPOINTS.max.small`
  ${columns(1, 12)};
  `}
`;

const FormContainer = styled.div`
  ${columns(9, 4)};
  display: flex;
  flex-direction: column;
  padding: 24px 24px;
  background: ${COLORS.white.css};
  border: 1px solid ${COLORS.shades.s200.css};
  border-radius: 9px;
  align-self: flex-start;
  position: sticky;
  top: 180px;
  box-shadow: 0 0 200px ${COLORS.brand.regular.opacity(0.03)};
  ${BREAKPOINTS.max.small`
  ${columns(1, 12)};
  `}

  ${BREAKPOINTS.max.small`
  margin-top: 6px;
  `}
`;

const Item = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 24px 0;
  &:last-child {
    padding-bottom: 0;
  }
  & h3 {
    ${TYPO.h3}
  }
  & h2 {
    ${TYPO.h2}
  }
`;

const Disclaimer = styled.p`
  ${TYPO.caption};
  ${markdown};
  color: ${COLORS.blocks.outline.css};
  margin: 24px 0 0;
`;

type FormUserInput = {
  email: string;
};

type FormUserProps = {
  onSubmit: SubmitHandler<FormUserInput>;
  submitLabel: string;
  submitIcon: IconName;
};

function FormUser({ onSubmit, submitIcon, submitLabel }: FormUserProps) {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting, isValid },
    setError,
    reset,
  } = useForm<FormUserInput>();

  return (
    <Form
      onSubmit={async (event) => {
        handleSubmit(onSubmit)(event)
          .catch((e) => {
            setError('email', { message: e.message });
            reset(undefined, {
              keepValues: true,
              keepErrors: true,
            });
          })
          .then(() => {
            setTimeout(() => {
              reset();
            }, 2000);
          });
      }}
    >
      <Label>
        <span>Email</span>
        <Input
          data-error={!!errors.email}
          placeholder="Your email"
          {...register('email', { required: true })}
        />
        {errors.email && (
          <ErrorMessage>
            <Icon name="AlertTriangle" width={12} height={12} />
            <span>{errors.email.message}</span>
          </ErrorMessage>
        )}
      </Label>
      <Button type="submit" disabled={!isValid}>
        {isSubmitting ? (
          <Spinner $color={COLORS.white.css} />
        ) : (
          <Icon name={submitIcon} width={12} height={12} />
        )}
        {submitLabel}
      </Button>
    </Form>
  );
}

type BlockAiProps = {
  data: BlockAiFragment;
};
function BlockAi({ data }: BlockAiProps) {
  const [inViewRef, inView] = useInview({});
  const { identify, track } = useContext(AnalyticsContext);
  const generating = useRef(false);
  const [response, setResponse] = useState<null | {
    fileId: string;
    projectName: string;
    requirementsCsv: Papa.ParseResult<unknown>;
    valuesCsv: Papa.ParseResult<unknown>;
    requirementsRaw: string;
    valuesRaw: string;
  }>(null);

  return (
    <>
      <Container $spacing={data.spacing}>
        <FormAiContainer>
          <Heading
            heading={data.heading}
            byline={data.byline}
            align="left"
            headingLevel="h2"
            show={inView}
            ref={inViewRef}
          />
          <FormAiPrompt
            placeholder={data.placeholder}
            examples={Array.isArray(data.examples) ? data.examples : []}
            ref={generating}
            onSubmit={async (data) => {
              const start = performance.now();
              try {
                setResponse(null);

                const response = await API(data.prompt);
                const end = performance.now();

                if (!response?.aiGenerateDemo) {
                  throw new Error(
                    'Unexpected error while generating the response. Please Try again'
                  );
                }

                const {
                  requirements: requirementsRaw,
                  values: valuesRaw,
                  fileId,
                  projectName,
                } = response.aiGenerateDemo;

                const requirementsCsv = Papa.parse(requirementsRaw, {
                  header: true,
                });
                const valuesCsv = Papa.parse(valuesRaw, { header: true });

                if (!requirementsCsv?.data?.length) {
                  throw new Error(
                    "Your prompt didn't produce any requirements. Please try again with a different prompt"
                  );
                }

                if (!valuesCsv?.data?.length) {
                  throw new Error(
                    "Your prompt didn't produce any values. Please try again with a different prompt"
                  );
                }

                // track
                track('AI Project Generated', {
                  prompt: data.prompt,
                  genDurationSeconds: (end - start) / 1000,
                  fileId,
                  requirementsCount: requirementsCsv?.data?.length,
                  valuesCount: valuesCsv?.data?.length,
                  projectName,
                });

                setResponse({
                  fileId,
                  projectName,
                  requirementsCsv,
                  valuesCsv,
                  requirementsRaw,
                  valuesRaw,
                });

                setTimeout(() => {
                  scrollIntoViewWithOffset("[data-response='true'", 120);
                }, 100);
              } catch (error) {
                const end = performance.now();

                // track
                track('AI Project Failed', {
                  prompt: data.prompt,
                  genDurationSeconds: (end - start) / 1000,
                  errorMessage: error.message,
                });

                throw error instanceof ErrorApi
                  ? new Error(
                      'The AI gods did not look upon you kindly this time, and there was an issue generating your system design breakdown. Try again.'
                    )
                  : error;
              }
            }}
          />
        </FormAiContainer>
        <Visual>
          <AnimationAi active={generating} background={COLORS.blocks.body} />
        </Visual>
      </Container>

      {response && (
        <Response data-response>
          <Content>
            <Heading
              preHeading={data.successPreHeading}
              heading={data.successHeading}
              byline={data.successDescription}
              align="left"
              headingLevel="h3"
              show
            />
            <Item>
              <h3>Project</h3>
              <DisplayTable
                data={[{ Name: response.projectName }]}
                columns={[
                  {
                    accessorKey: 'Name',
                    header: 'Name',
                  },
                ]}
              />
            </Item>
            <Item>
              <h3>Requirements</h3>
              <DisplayTable
                fade
                caption={data.tableFooterMessage}
                data={response.requirementsCsv.data.slice(0, 5)}
                columns={[
                  {
                    accessorKey: 'Name',
                    header: 'Name',
                    size: 180,
                  },
                  {
                    accessorKey: 'Description',
                    header: 'Requirement Statement',
                    minSize: 240,
                    meta: {
                      width: 'auto',
                    },
                  },
                  {
                    accessorKey: 'System',
                    header: 'System',
                    size: 180,
                  },
                ]}
              />
            </Item>
            <Item>
              <h3>Values</h3>
              <DisplayTable
                fade
                caption={data.tableFooterMessage}
                data={response.valuesCsv.data.slice(0, 5)}
                columns={[
                  {
                    accessorKey: 'Name',
                    header: 'Name',
                    size: 180,
                  },
                  {
                    accessorKey: 'Description',
                    header: 'Description',
                    minSize: 240,
                    meta: {
                      width: 'auto',
                    },
                  },
                  {
                    accessorKey: 'Value',
                    header: 'Value',
                    size: 90,
                  },
                  {
                    accessorKey: 'Unit',
                    header: 'Unit',
                    size: 90,
                  },
                ]}
              />
            </Item>
          </Content>
          <FormContainer>
            <Heading
              heading={data.callToActionHeading}
              byline={data.callToActionDescription}
              align="left"
              headingLevel="h3"
              show
            />
            <FormUser
              submitLabel={data.callToActionButton}
              submitIcon={data.callToActionIcon as IconName}
              onSubmit={async (data) => {
                if (!data.email.match(emailRegex)) {
                  throw new Error('Email not valid');
                }
                if (isFreeEmail(data.email)) {
                  throw new Error('You must use your work email');
                }

                track('AI Project Viewed in Flow', { email: data.email });
                await identify({ email: data.email, from: 'Ai page' });

                const env = getEnvironment();

                const params = {
                  id: response.fileId,
                  email: data.email,
                  aiProjectName: response.projectName,
                };

                const searchParams = new URLSearchParams(params).toString();
                const url = `${ENDPOINTS.app.url[env]}/ai-project?${searchParams}`;
                window.open(url);
              }}
            />
            {data.callToActionDisclaimer && (
              <Disclaimer>
                <MarkdownInline>{data.callToActionDisclaimer}</MarkdownInline>
              </Disclaimer>
            )}
          </FormContainer>
        </Response>
      )}
    </>
  );
}

export default memo(BlockAi);
