import ENDPOINTS from '../../endpoints.json';
import { ErrorApi } from './errors';
import { getEnvironment } from './window';

const QUERY = `mutation MyMutation($input: String!) {
  aiGenerateDemo(input: {description: $input}) {
    fileId
    projectName
    requirements
    values
  }
}`;

type AiDemo = {
  aiGenerateDemo?: {
    fileId: string;
    projectName: string;
    requirements: string;
    values: string;
  };
};

type JSONResponse = {
  data?: AiDemo;
  errors?: Array<{ message: string }>;
};

export default async function API(prompt: string) {
  const env = getEnvironment();

  const url = ENDPOINTS.app.api[env];

  // do request
  const request = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'X-Hasura-Role': 'anonymous',
    },
    body: JSON.stringify({
      query: QUERY,
      variables: { input: prompt },
    }),
  });

  const response: JSONResponse = await request.json();

  if (response.errors) {
    console.error(response.errors);
    throw new ErrorApi(response.errors[0]?.message);
  }

  return response.data;
}
