import React, { memo } from 'react';
import styled from 'styled-components';
import CountUp from 'react-countup';

import type {
  BlockStatsCounterFragment,
  StatCounterFragment,
} from '../schemas/api';

import TYPO from '../styles/typography';
import { Grid, columns } from '../styles/grid';

const Container = styled(Grid)`
  margin-top: 108px;
`;

const Content = styled.div`
  ${columns(1, 12)};
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 24px;
`;
const Counter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 230px;
  padding: 12px 0;
`;
const Value = styled.div`
  display: flex;
  ${TYPO.h1}
`;

const Label = styled.div`
  ${TYPO.p1}
`;

type StatCounterProps = {
  data: StatCounterFragment;
};

const StatCounter = ({ data }: StatCounterProps) => {
  const [loading, setLoading] = React.useState(false);
  const containerProps = { 'aria-busy': loading };

  return (
    <Counter>
      <Value>
        <CountUp
          start={0}
          end={data.value}
          prefix={data.prefix || undefined}
          suffix={data.suffix || undefined}
          duration={3}
          enableScrollSpy
          scrollSpyOnce
          preserveValue
          onStart={() => setLoading(true)}
          onEnd={() => setLoading(false)}
          containerProps={containerProps}
        />
      </Value>
      <Label {...containerProps}>{data.label}</Label>
    </Counter>
  );
};

type BlockStatsCounterProps = {
  data: BlockStatsCounterFragment;
};
function BlockStatsCounter({ data }: BlockStatsCounterProps) {
  return (
    <Container>
      <Content>
        {data.stats.items.map((d) => (
          <StatCounter data={d} key={d.label} />
        ))}
      </Content>
    </Container>
  );
}

export default memo(BlockStatsCounter);
