import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import anime from 'animejs';

import TextAnimation from './TextAnimation';

import useInview from '../hooks/useInview';

import { mapLinear } from '../utils/math';
import { getLine, getPath } from '../utils/path';

import { fadeElement } from '../styles/animations';
import { Grid, columns } from '../styles/grid';
import TYPO from '../styles/typography';
import { FONT_STRING } from '../styles/fonts';
import BREAKPOINTS, { breakpointSizes } from '../styles/breakpoints';
import COLORS, { Color } from '../styles/colors';

import * as chartData from '../data/versionControlChartData';
import * as textUtils from '../utils/text';

const AVATAR_BORDER_SIZE = 1;
const AVATAR_BORDER_RADIUS = 3;

const LABEL_RECT_SIZE = 20;
const LABEL_RECT_BORDER_RADIUS = 3;

const BUTTON_PADDING_V = 9;
const BUTTON_PADDING_H = 15;
const BUTTON_BORDER_RADIUS = 3;
const BUTTON_FONT_SIZE = 12;
const BUTTON_LINE_HEIGHT = 14;

const BRANCH_CIRCLE_OUTER_RADIUS = 8;
const BRANCH_CIRCLE_INNER_RADIUS = 3;

const BRANCH_DESC_LINE_HEIGHT = 12;
const BRANCH_DESC_OFFSET = 4;
const BRANCH_NAME_HEIGHT = 22;

const BRANCH_ACTION_HEIGHT_STARTING_OFFSET = 48;
const BRANCH_ACTION_LINE_DASH_LENGTH = 2;

const LABEL_AUTOPLAY_DURATION = 2000;
const ANIMATION_DURATION = 500;
const ANIMATION_STAGGER_DELAY = 50;

const ANIMATION_SCROLL_DISTANCE = 200;

const Container = styled(Grid)`
  position: relative;
  z-index: 1;
  box-shadow: 0 0 17px 1px rgb(0 0 0 / 11%);
  margin-top: 224px;
  padding: 180px 30px;
  background-color: ${COLORS.shades.s100.css};
  background-image: radial-gradient(rgba(0, 0, 0, 0.2) 1px, transparent 1px);
  background-size: 24px 24px;
  position: relative;
  background-attachment: fixed;
  ${BREAKPOINTS.max.small`
    padding: 90px 40px;
    margin-top: 180px;
    `}
`;

const ScrollContainer = styled.div`
  height: 700px; /* it will change dynamically */
  display: flex;
  align-items: flex-start;
  ${columns(1, 12)}
  position:relative;
`;

const HeadingSection = styled.div`
  ${columns(4, 6)}
  text-align: center;
  margin: auto;
  ${BREAKPOINTS.max.medium`
    ${columns(1, 12)}
    `}
`;

const Heading = styled.h1`
  ${TYPO.h2}
`;

type SubheadingProps = {
  $show: boolean;
};
const Subheading = styled.div<SubheadingProps>`
  ${TYPO.subheading}
  ${(props) => props.$show && fadeElement()}
  opacity:0;
  margin-top: 4px;
  white-space: pre-wrap;
  & strong {
    color: ${COLORS.brand.regular.css};
    font-weight: inherit;
  }
`;

const Content = styled(Grid)`
  position: sticky;
  top: 25%; /* it will change dynamically */
  width: 100%;
  padding: 0;
`;

type LabelProps = {
  $active: boolean;
};
const Label = styled.g<LabelProps>`
  opacity: ${(props) => (props.$active ? 1 : 0)};
  transform: translateY(${(props) => (props.$active ? '-20px' : ' -10px')});
  transition: 0.2s ease all;
`;

const SVG = styled.svg`
  width: 100%;
  height: auto;
  ${columns(1, 12)}
  & text {
    user-select: none;
  }
  .button {
    & text {
      text-anchor: middle;
      ${TYPO.subheading};
      font-size: ${BUTTON_FONT_SIZE}px;
      fill: ${COLORS.brand.regular.css};
    }
    & rect {
      fill: ${COLORS.brand.light.css};
      stroke: ${COLORS.brand.regular.css};
      stroke-width: 1px;
    }
  }

  & #project-info {
    & text {
      text-anchor: middle;
      ${TYPO.subheading};
      font-size: 12px;
    }
  }
  & .branch path {
    stroke-width: 1px;
  }

  & .node {
    & .label {
      pointer-events: none;
      & text {
        text-anchor: middle;
        ${TYPO.subheading};
        font-size: 12px;
      }
    }

    & .avatar {
      pointer-events: none;
    }

    & .outerCircle {
      stroke-width: 0px;
    }

    &:last-child .outerCircle {
      stroke-width: 1px;
    }
  }
  & .branch .info {
    & .branchName {
      ${TYPO.subheading};
      font-size: 12px;
    }
    & .branchDescription {
      ${TYPO.small};
    }
    & text {
      text-anchor: middle;
    }
  }

  & .branch .action {
    & line {
      stroke: ${COLORS.shades.s300.css};
    }
  }
`;

interface CircleProps extends React.HTMLAttributes<SVGElement> {
  color: string;
  circle: chartData.Circle;
  active: boolean;
  label: string;
  avatarSize: number;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
}

const Circle = ({
  color,
  circle,
  active,
  label,
  avatarSize,
  ...props
}: CircleProps) => {
  const [width, setWidth] = useState(0);

  useEffect(() => {
    if (label) {
      const length = textUtils.measure(String(label), FONT_STRING, 12, 13, 500);
      const padding = 10;
      setWidth(length + padding * 2);
    }
  }, [label]);

  return (
    <g className="node" {...props}>
      <circle r="30" fill="transparent" />
      <circle
        r={BRANCH_CIRCLE_OUTER_RADIUS}
        fill={String(new Color(color).mix(COLORS.white, 0.8))}
        stroke={color}
        className="outerCircle"
      />
      <circle
        r={BRANCH_CIRCLE_INNER_RADIUS}
        stroke={color}
        fill={COLORS.white.css}
        strokeWidth={1}
        className="innerCircle"
      />

      {circle.avatar && (
        <g
          transform={`translate(-${avatarSize / 2}, ${avatarSize / 2})`}
          className="avatar"
          opacity="0"
        >
          <rect
            fill={COLORS.white.css}
            height={avatarSize}
            width={avatarSize}
            x="0"
            y="0"
            rx={BUTTON_BORDER_RADIUS}
            filter="url(#dropshadow)"
          />
          <image
            href={circle.avatar}
            width={avatarSize}
            height={avatarSize}
            clipPath="url(#avatar-image-clip)"
          />
          <polygon
            points={`${avatarSize / 2},-4 ${avatarSize / 2 - 3},0 ${
              avatarSize / 2 + 3
            },0`}
            fill={COLORS.white.css}
          />
        </g>
      )}

      {circle.label && (
        <Label className="label" $active={active}>
          <g transform="translate(0, -13)">
            <rect
              fill={String(new Color(color).mix(COLORS.white, 0.8))}
              height={LABEL_RECT_SIZE}
              x="0"
              y="0"
              rx={LABEL_RECT_BORDER_RADIUS}
              width={width}
              transform={`translate(-${width / 2}, 0)`}
            />
            <polygon
              points={`0,${
                LABEL_RECT_SIZE + 4
              } -3,${LABEL_RECT_SIZE} 3,${LABEL_RECT_SIZE}`}
              fill={String(new Color(color).mix(COLORS.white, 0.8))}
            />
          </g>
          <text fill={color}>{label}</text>
        </Label>
      )}
    </g>
  );
};

const adaptRectToText = (length: number, query: string, padding: number) => {
  const width = length + padding * 2;
  const rect: SVGRectElement = document.querySelector(query);

  rect.style.width = String(width);
  rect.style.transform = `translate(-${width / 2}px, 0)`;

  return width;
};

const initialActiveNode = {
  branch: 0,
  node: 0,
  show: false,
};

type VersionControlChartProps = {
  copy: Record<string, string>;
};

const VersionControlChart = ({ copy }: VersionControlChartProps) => {
  const animationRef = useRef(null);
  const intervalRef = useRef(null);
  const contentRef = useRef(null);

  const [completed, setCompleted] = useState(false);
  const [activeNode, setActiveNode] = useState(initialActiveNode);
  const [paused, setPaused] = useState(false);
  const [inViewRef, inView] = useInview({});
  const [mobile, setMobile] = useState(false);
  const [graphRef, graphInView] = useInview<SVGSVGElement>({ once: false });

  const data = mobile ? chartData.mobile : chartData.desktop;

  useEffect(() => {
    if (contentRef.current) {
      const { offsetHeight, parentElement } = contentRef.current;

      if (parentElement) {
        // set parent to have a fixed scroll distance depending on the content's height
        parentElement.style.height = `${
          offsetHeight + ANIMATION_SCROLL_DISTANCE
        }px`;
      }

      const nav = document.querySelector('nav');
      const navHeight = nav ? nav.offsetHeight : 0;
      const visibleView = document.documentElement.clientHeight - navHeight;

      // set sticky content at the middle of the viewport
      contentRef.current.style.top = `${
        navHeight + Math.abs((visibleView - offsetHeight) / 2)
      }px`;
    }
    setActiveNode(initialActiveNode);
  }, [mobile]);

  useEffect(() => {
    const resizeListener = () => {
      setMobile(window.innerWidth <= breakpointSizes.small);
    };
    let tick = false;
    let animation;

    const scrollListener = () => {
      if (contentRef.current && animationRef.current) {
        if (!tick) {
          animation = window.requestAnimationFrame(() => {
            const { offsetTop, offsetHeight, parentElement } =
              contentRef.current;

            const { seek, duration } = animationRef.current;

            const progress =
              (offsetTop / (parentElement.offsetHeight - offsetHeight)) * 100;

            setCompleted(progress > 50);

            seek((progress / 100) * duration);

            tick = false;
          });
          tick = true;
        }
      }
    };

    window.addEventListener('scroll', scrollListener, false);
    window.addEventListener('resize', resizeListener, false);
    resizeListener();
    scrollListener();

    return function unbind() {
      window.removeEventListener('scroll', scrollListener, false);
      window.addEventListener('resize', resizeListener, false);
      window.cancelAnimationFrame(animation);
    };
  }, [contentRef, animationRef]);

  useEffect(() => {
    animationRef.current = anime.timeline({
      duration: ANIMATION_DURATION,
      easing: 'linear', // cubicBezier(0, 0.5, 0.5, 1)
      autoplay: false,
    });

    // set chart title length and position
    const titleLength = textUtils.measure(
      String(data.title),
      FONT_STRING,
      12,
      13,
      500
    );
    const width = adaptRectToText(
      titleLength,
      `#project-info rect`,
      BUTTON_PADDING_H
    );
    document.getElementById('project-info').style.transform = `translate(${
      width / 2 + 10
    }px, 75px)`;

    for (let index = 0; index < data.branches.length; index += 1) {
      const branch = data.branches[index];

      const branchActionLines = branch.action.split('\n');

      const longestLineLength = branchActionLines.reduce((acc, line) => {
        const length = textUtils.measure(
          String(line),
          FONT_STRING,
          12,
          13,
          500
        );
        return acc > length ? acc : length;
      }, 0);

      // get action button text lenght and set the rectangle width
      adaptRectToText(
        longestLineLength,
        `#${branch.id}-action rect`,
        BUTTON_PADDING_H
      );

      // animations

      // BRANCH PATHS
      animationRef.current.add(
        {
          targets: `#${branch.path.id}`,
          d: [
            {
              value: getPath(
                branch.path.startingPoint,
                branch.path.length,
                data.borderRadius * 2,
                data.borderRadius / 2
              ),
              delay: index * ANIMATION_STAGGER_DELAY,
              duration: ANIMATION_DURATION * 0.1,
            },
            {
              value: getPath(
                branch.path.startingPoint,
                branch.path.length,
                branch.path.height,
                data.borderRadius
              ),
              duration: ANIMATION_DURATION * 0.9,
            },
          ],
        },
        0
      );

      // BRANCH ACTIONS
      animationRef.current.add(
        {
          targets: `#${branch.id}-action line`,
          y1: [
            {
              value: data.borderRadius * 2,
              delay: index * ANIMATION_STAGGER_DELAY,
              duration: ANIMATION_DURATION * 0.1,
            },
            {
              value: branch.path.height,
              duration: ANIMATION_DURATION * 0.9,
            },
          ],
          y2: [
            {
              value: data.actionPosY + BRANCH_ACTION_HEIGHT_STARTING_OFFSET,
              delay: index * ANIMATION_STAGGER_DELAY,
              duration: ANIMATION_DURATION * 0.1,
            },
            {
              value: data.actionPosY,
              duration: ANIMATION_DURATION * 0.9,
            },
          ],
        },
        0
      );
      animationRef.current.add(
        {
          targets: `#${branch.id}-action`,
          opacity: 1,
          delay: ANIMATION_DURATION * 0.5 + index * 100,
          duration: ANIMATION_DURATION * 0.6,
        },
        0
      );
      animationRef.current.add(
        {
          targets: `#${branch.id}-action .button`,
          translateY: [
            {
              value: data.actionPosY + BRANCH_ACTION_HEIGHT_STARTING_OFFSET,
              duration: ANIMATION_DURATION * 0.1,
              delay: index * ANIMATION_STAGGER_DELAY,
            },
            {
              value: data.actionPosY,
              duration: ANIMATION_DURATION * 0.9,
            },
          ],
          delay: ANIMATION_DURATION * 0.5,
          duration: ANIMATION_DURATION * 0.5,
        },
        0
      );

      // BRANCH CIRCLES
      for (let j = 0; j < branch.circles.length; j += 1) {
        const circle = branch.circles[j];

        // set circle initial position

        const updateCiclesPosition = (progress: number) => {
          const currentValue = mapLinear(
            progress,
            0,
            100,
            circle.position.start,
            circle.position.end
          );

          const mypath: any = document.getElementById(branch.path.id);

          if (mypath) {
            // get path length
            const pathLength = mypath.getTotalLength();

            // get point at that progress
            const pt = mypath.getPointAtLength(
              (pathLength * currentValue) / 100
            );
            const thisCircle = document.getElementById(circle.id);

            // set attribute
            thisCircle.style.transform = `translate(${pt.x}px, ${pt.y}px)`;
          }
        };

        updateCiclesPosition(0);

        // circle position animation
        animationRef.current.add(
          {
            targets: `#${circle.id}`,
            duration: ANIMATION_DURATION * 0.5,
            update: (anim) => updateCiclesPosition(anim.progress),
          },
          0
        );
        // circle avatar
        animationRef.current.add(
          {
            targets: `#${circle.id} .avatar`,
            opacity: 1,
            delay: ANIMATION_DURATION * 0.4,
            duration: ANIMATION_DURATION * 0.6,
          },
          0
        );
      }
    }
  }, [data]);

  useEffect(() => {
    // create autoplay animation to show one label at time
    if (!paused && graphInView) {
      intervalRef.current = setInterval(() => {
        setActiveNode((prev) => {
          if (!prev.show) {
            return { ...prev, show: true };
          }

          let nextBranchIndex = prev.branch;
          let nextNodeIndex = prev.node + 1;

          if (
            !data.branches[nextBranchIndex] ||
            nextNodeIndex > data.branches[nextBranchIndex].circles.length - 2
          ) {
            nextBranchIndex = (prev.branch + 1) % data.branches.length;
            nextNodeIndex = 0;
          }
          return { branch: nextBranchIndex, node: nextNodeIndex, show: false };
        });
      }, LABEL_AUTOPLAY_DURATION);
    } else {
      intervalRef.current = null;
    }
    return () => clearInterval(intervalRef.current);
  }, [paused, data, graphInView]);

  return (
    <Container>
      <ScrollContainer>
        <Content ref={contentRef}>
          <HeadingSection ref={inViewRef}>
            <Heading>
              <TextAnimation text={copy.heading} show={inView} />
            </Heading>
            {copy.byline && (
              <Subheading $show={inView}>{copy.byline}</Subheading>
            )}
          </HeadingSection>

          <SVG
            viewBox={`0 0 ${data.width} ${data.height}`}
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="xMinYMin meet"
            ref={graphRef}
          >
            <defs>
              <filter id="dropshadow" height="130%">
                <feGaussianBlur in="SourceAlpha" stdDeviation="2" />
                <feOffset dx="0" dy="0" result="offsetblur" />
                <feComponentTransfer>
                  <feFuncA type="linear" slope="0.2" />
                </feComponentTransfer>
                <feMerge>
                  <feMergeNode />
                  <feMergeNode in="SourceGraphic" />
                </feMerge>
              </filter>
              <clipPath id="avatar-image-clip">
                <rect
                  fill={COLORS.white.css}
                  height={data.avatarSize - AVATAR_BORDER_SIZE * 2}
                  width={data.avatarSize - AVATAR_BORDER_SIZE * 2}
                  x={AVATAR_BORDER_SIZE}
                  y={AVATAR_BORDER_SIZE}
                  rx={AVATAR_BORDER_RADIUS - AVATAR_BORDER_SIZE}
                />
              </clipPath>
            </defs>
            <g id="project-info" className="button">
              <rect
                height={BUTTON_PADDING_V * 2 + BUTTON_LINE_HEIGHT}
                width={BUTTON_PADDING_H * 2}
                x="0"
                y={-(BUTTON_PADDING_V + BUTTON_FONT_SIZE)}
                rx={BUTTON_BORDER_RADIUS}
              />
              <text>{data.title}</text>
            </g>

            {data.branches.map((branch) => {
              const branchDescriptionLines = branch.description.split('\n');
              const branchActionLines = branch.action.split('\n');

              // render paths first so that they're drawn at the bottom
              return (
                <g key={branch.id} className="branch paths">
                  <path
                    key={branch.path.id}
                    d={getLine(branch.path.startingPoint, branch.path.length)}
                    stroke={branch.color}
                    fill="none"
                    id={branch.path.id}
                  />

                  <g
                    transform={`translate(${
                      branch.path.startingPoint[0] + branch.path.length
                    }, ${branch.path.startingPoint[1] - BRANCH_NAME_HEIGHT})`}
                    id={`${branch.id}-info`}
                    className="info"
                  >
                    <g
                      transform={`translate(
                        0,
                        -${
                          BRANCH_DESC_LINE_HEIGHT *
                          branchDescriptionLines.length
                        }
                      )`}
                    >
                      <text fill={branch.color} className="branchName">
                        {branch.name}
                      </text>
                      <text
                        fill={branch.color}
                        className="branchDescription"
                        transform={`translate(0, ${
                          BRANCH_DESC_LINE_HEIGHT + BRANCH_DESC_OFFSET
                        })`}
                      >
                        {branchDescriptionLines.map((line, index) => {
                          return (
                            <tspan
                              key={line}
                              x="0"
                              y={BRANCH_DESC_LINE_HEIGHT * index}
                            >
                              {line}
                            </tspan>
                          );
                        })}
                      </text>
                    </g>
                  </g>

                  <g
                    transform={`translate(${
                      branch.path.startingPoint[0] +
                      branch.path.length -
                      data.borderRadius
                    }, ${branch.path.startingPoint[1]})`}
                    id={`${branch.id}-action`}
                    opacity="0"
                  >
                    <line
                      x1="0"
                      y1="0"
                      x2="0"
                      y2={data.actionPosY}
                      stroke="black"
                      strokeDasharray={BRANCH_ACTION_LINE_DASH_LENGTH}
                    />
                    <g className="button">
                      <rect
                        height={
                          BUTTON_PADDING_V * 2 +
                          BUTTON_LINE_HEIGHT * branchActionLines.length
                        }
                        width={BUTTON_PADDING_H * 2}
                        x="0"
                        y={-(BUTTON_PADDING_V + BUTTON_FONT_SIZE)}
                        rx={BUTTON_BORDER_RADIUS}
                      />
                      <text className="branchName" y="0">
                        {branchActionLines.map((line, index) => {
                          return (
                            <tspan
                              key={line}
                              x="0"
                              y={BUTTON_LINE_HEIGHT * index}
                            >
                              {line}
                            </tspan>
                          );
                        })}
                      </text>
                    </g>
                  </g>
                </g>
              );
            })}

            <line
              x1="0"
              y1="100"
              x2="100%"
              y2="100"
              stroke={COLORS.brand.regular.css}
              strokeWidth={1}
            />

            {data.branches.map((branch, branchIndex) => {
              // render circles after the rest of elements, so they're drawn on top

              return (
                <g key={branch.id} className="branch circles">
                  {branch.circles.map((circle, circleIndex) => {
                    let label;
                    if (circle.label) {
                      label = completed ? circle.label.end : circle.label.start;
                    }
                    return (
                      <Circle
                        id={circle.id}
                        key={circle.id}
                        onMouseEnter={() => {
                          setPaused(true);
                          setActiveNode({
                            branch: branchIndex,
                            node: circleIndex,
                            show: true,
                          });
                        }}
                        onMouseLeave={() => {
                          setPaused(false);
                        }}
                        active={
                          !paused &&
                          activeNode.show &&
                          branchIndex === activeNode.branch &&
                          circleIndex === activeNode.node
                        }
                        circle={circle}
                        color={branch.color}
                        label={label}
                        avatarSize={data.avatarSize}
                      />
                    );
                  })}
                </g>
              );
            })}
            {graphInView &&
              activeNode.show &&
              data.branches[activeNode.branch] &&
              data.branches[activeNode.branch].circles[activeNode.node] && (
                // keep active circle on top
                <use
                  id="use"
                  xlinkHref={`#${
                    data.branches[activeNode.branch].circles[activeNode.node].id
                  }`}
                />
              )}
          </SVG>
        </Content>
      </ScrollContainer>
    </Container>
  );
};

export default VersionControlChart;
