import React, { memo } from 'react';
import styled from 'styled-components';

import type {
  BlockTeamMemberFragment,
  TeamMemberFragment,
} from '../schemas/api';

import Icon from './Icon';
import Link from './Link';

import useInview from '../hooks/useInview';

import { staggerChildrenFadeTranslateIn } from '../styles/animations';
import COLORS from '../styles/colors';
import TYPO from '../styles/typography';
import BREAKPOINTS from '../styles/breakpoints';
import { Grid, columns, grid } from '../styles/grid';

import * as textUtils from '../utils/text';

const Container = styled(Grid)`
  padding: 120px 12px;
  ${BREAKPOINTS.max.medium`
    padding: 72px 12px;
  `}
`;

const Inner = styled.div`
  ${columns(1, 12)}
  display: flex;
  flex-direction: column;
  gap: 36px;
  width: 100%;
`;

type ContentProps = { $show: boolean };
const Content = styled.div<ContentProps>`
  position: relative;
  ${grid(12)}
  row-gap: 48px;
  column-gap: 24px;
  width: 100%;
  ${(props) =>
    staggerChildrenFadeTranslateIn(props.$show, -10, undefined, 0.05)}
`;

const HeadingSection = styled.div<ContentProps>`
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 6px;
  ${(props) => staggerChildrenFadeTranslateIn(props.$show)}
`;

const Heading = styled.h1`
  ${TYPO.h2}
  text-align:center;
`;

const Subheading = styled.div`
  ${TYPO.p1}
  text-align: center;
  max-width: 820px;
  color: ${COLORS.text.light.css};
  & strong {
    color: ${COLORS.brand.regular.css};
    font-weight: inherit;
    white-space: pre-wrap;
  }
`;

const IconContainer = styled.div`
  border-radius: 3px;
  width: 36px;
  height: 36px;
  color: ${COLORS.white.css};
  background-color: ${COLORS.brand.regular.css};
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.2s ease;
  ${BREAKPOINTS.max.extraSmall`
    display:none;
  `}
`;

const TeamMember = styled.div`
  ${columns(null, 3)}

  ${BREAKPOINTS.max.medium`
    ${columns(null, 4)}
  `}
  ${BREAKPOINTS.max.small`
    ${columns(null, 6)}
  `}
  ${BREAKPOINTS.max.extraSmall`
    ${columns(null, 6)}
  `}
  display: flex;
  flex-direction: column;
  & > a {
    display: flex;
    flex-direction: column;
    width:100%:
  }
  &:hover {
    ${IconContainer} {
      opacity: 1;
    }
  }
`;

type MemberImageProps = {
  $image: string;
};

const MemberImage = styled.div<MemberImageProps>`
  position: relative;
  width: 100%;
  padding-top: 100%;
  background-color: ${COLORS.white.css};
  border-radius: 3px;
  overflow: hidden;
  &:before,
  &:after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
  &:before {
    background: url(${(props) => props.$image});
    background-size: cover;
    background-position: center center;
    filter: grayscale(1) brightness(1.2) contrast(0.8);
    opacity: 0.95;
  }
  &:after {
    background: ${COLORS.brand.regular.css};
    background: background: linear-gradient(315deg, ${
      COLORS.brand.regular.css
    } 0%, ${COLORS.brand.regular.opacity(0.28)} 100%);
    opacity: 0.5;
    mix-blend-mode: lighten;
  }
`;

const MemberRow = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
`;

const MemberInfo = styled.div`
  flex: 1;
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  gap: 0px;
`;

const MemberName = styled.div`
  ${TYPO.p1};
  font-size: 18px;
  font-weight: 700;
  ${BREAKPOINTS.max.extraSmall`
     ${TYPO.p1}
  `}
`;
const MemberRole = styled.div`
  ${TYPO.p2}
  font-style:italic;
  ${BREAKPOINTS.max.extraSmall`
     font-size:12px;
  `}
`;

type CareerMembersProps = {
  data: BlockTeamMemberFragment;
};

function CareerMembers({ data }: CareerMembersProps) {
  const [inViewRef, inView] = useInview({});
  return (
    <Container>
      <Inner>
        <HeadingSection ref={inViewRef} $show={inView}>
          <Heading>{textUtils.highlight(data.heading)}</Heading>
          {data.byline && (
            <Subheading>{textUtils.highlight(data.byline)}</Subheading>
          )}
        </HeadingSection>
        <Content $show={inView}>
          {data.teamMembers.items.map((member: TeamMemberFragment) => (
            <TeamMember key={member.name}>
              <Link link={member.link}>
                {member.picture && <MemberImage $image={member.picture.url} />}
                <MemberRow>
                  <MemberInfo>
                    <MemberName>{member.name}</MemberName>
                    <MemberRole>{member.jobRole}</MemberRole>
                  </MemberInfo>
                  {member.link && (
                    <IconContainer>
                      <Icon name="ExternalLink" width={12} height={12} />
                    </IconContainer>
                  )}
                </MemberRow>
              </Link>
            </TeamMember>
          ))}
        </Content>
      </Inner>
    </Container>
  );
}

export default memo(CareerMembers);
