import React, { useState } from 'react';
import styled from 'styled-components';
import { A11y, Keyboard } from 'swiper/modules';
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/keyboard';
import 'swiper/css/a11y';

import { BlockQuoteFragment } from '../schemas/api';
import markdownStyles from '../styles/markdown';

import Image from './Image';
import TYPO from '../styles/typography';
import COLORS from '../styles/colors';
import Icon from './Icon';
import Markdown from './Markdown';
import Quotes from '../assets/quotes.svg';

const ItemContainer = styled.div`
  display: flex;

  min-height: 200px;
  flex-direction: column;
  gap: 6px;
  @media (min-width: 768px) {
    flex-direction: row;
    min-height: 440px;
    gap: 30px;
  }

  padding: 24px;

  height: 100%;
`;

const Section = styled.div`
  display: flex;

  flex-direction: column;

  gap: 18px;
  flex: 0;

  padding-bottom: 72px;
  @media (min-width: 768px) {
    padding: 0;
    flex: 1;
  }
`;

const QuoteMarks = styled(Image)`
  height: 36px;
  margin-bottom: 18px;
  align-self: flex-start;
`;

const Quote = styled.div`
  font-size: 15px;
  ${markdownStyles}
  & p {
  }
  & strong {
    color: ${COLORS.brand.regular.css};
    font-weight: 600;
  }
`;

const Avatar = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  overflow: hidden;
  flex: 0 0 auto;
`;

const Author = styled.div`
  margin-top: auto;
  display: flex;
  gap: 12px;
  align-items: center;
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
`;

const Name = styled.div`
  ${TYPO.p2};
  font-weight: 500;
`;

const Role = styled.div`
  ${TYPO.small}
  color: ${COLORS.shades.s300.css}
`;

const NavigationContainer = styled.div`
  position: absolute;
  bottom: 24px;
  left: 24px;
  display: flex;
  align-items: center;
  gap: 24px;
`;

const Count = styled.span`
  display: flex;
  gap: 3px;
  flex: 0 0 auto;
  ${TYPO.p2}
`;

const Heading = styled.div`
  ${TYPO.h2}
`;

const ImageContainer = styled.div`
  width: 140px;
  height: 48px;
  display: flex;
  align-items: flex-start;
  position: relative;
`;

const NavButton = styled.button`
  height: 48px;
  width: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 48px;
  pointer-events: all;
  background: ${COLORS.white.css};
  z-index: 10;
  border: 1px solid ${COLORS.shades.s200.css};
  &:hover {
    color: ${COLORS.brand.regular.css};
    border-color: ${COLORS.brand.regular.css};
    & svg {
      stroke-width: 3px;
    }
  }

  &:disabled {
    pointer-events: none;
  }
`;

const Divider = styled.span`
  width: 1px;
  height: 100%;

  display: none;

  @media (min-width: 768px) {
    display: block;
  }

  background: linear-gradient(
    ${COLORS.brand.regular.opacity(1)},
    ${COLORS.brand.regular.opacity(1)},
    ${COLORS.brand.regular.opacity(0)}
  );
`;

type NavigationProps = {
  active: number;
  total: number;
};

function Navigation({ active, total }: NavigationProps) {
  const swiper = useSwiper();

  return (
    <NavigationContainer>
      <NavButton
        onClick={() => swiper.slidePrev()}
        disabled={active === 1}
        aria-label="Previous Slide"
      >
        <Icon name="ArrowLeft" width={16} height={16} />
      </NavButton>
      <Count>
        <span>{active}</span> <span>/</span> <span>{total}</span>
      </Count>
      <NavButton
        onClick={() => swiper.slideNext()}
        disabled={active === total}
        aria-label="Next Slide"
      >
        <Icon name="ArrowRight" width={16} height={16} />
      </NavButton>
    </NavigationContainer>
  );
}

type TestimonialProps = {
  item: BlockQuoteFragment;
};

const Testimonial = ({ item }: TestimonialProps) => {
  return (
    <ItemContainer>
      <Section>
        <ImageContainer>
          {item.companyLogo && (
            <Image
              src={item.companyLogo.url}
              width={item.companyLogo.width}
              height={item.companyLogo.height}
              alt={item.companyLogo.description}
              style={{
                objectFit: 'contain',
                objectPosition: 'left',
                height: '100%',
                width: '100%',
              }}
            />
          )}
        </ImageContainer>
        <Heading>{item.heading}</Heading>
      </Section>
      <Divider />
      <Section>
        <QuoteMarks src={Quotes} width={39} height={36} alt="Quote mark" />
        <Quote>
          <Markdown content={item.quote} />
        </Quote>

        <Author>
          <Avatar>
            <Image
              src={item.picture.url}
              height={48}
              width={48}
              alt={item.picture.title}
            />
          </Avatar>
          <Info>
            <Name>{item.source}</Name>
            <Role>{item.role}</Role>
          </Info>
        </Author>
      </Section>
    </ItemContainer>
  );
};

type TestimonialsCarouselProps = {
  items: BlockQuoteFragment[];
};

const TestimonialsCarousel = ({ items }: TestimonialsCarouselProps) => {
  const [index, setIndex] = useState(0);
  return (
    <Swiper
      modules={[A11y, Keyboard]}
      spaceBetween={0}
      slidesPerView={1}
      keyboard={{ enabled: true, onlyInViewport: true }}
      onSlideChange={(swiper) => {
        setIndex(swiper.activeIndex);
      }}
    >
      <Navigation active={index + 1} total={items.length} />
      {items.map((d) => (
        <SwiperSlide key={d.source}>
          <Testimonial item={d} />
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default TestimonialsCarousel;
