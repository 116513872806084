import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';
import anime from 'animejs';

import COLORS, { Color } from '../styles/colors';

import AnimatedAvatar from './AnimatedAvatar';

import { lissajous } from '../utils/path';

import { ReactComponent as CommentIcon } from '../assets/icons/message-circle.svg';
import { ReactComponent as BoxIcon } from '../assets/icons/box.svg';
import { ReactComponent as PieChartIcon } from '../assets/icons/pie-chart.svg';

import {
  collaboration as data,
  Node,
  NodeType,
} from '../data/featuresAnimationsData';

const WIDTH = 600;
const HEIGHT = 400;

const SVG = styled.svg`
  height: 100%;
  width: 100%;
  overflow: visible;
`;

type CircleContainerProps = {
  $shadowColor: string;
};
const CircleContainer = styled.circle<CircleContainerProps>`
  filter: drop-shadow(
    3px 3px 6px ${(props) => new Color(props.$shadowColor).opacity(0.2)}
  );
`;

type NodeItemProps = React.HTMLAttributes<SVGGElement> & {
  node: Node;
  transform: string;
  canAnimate: boolean;
};

const NodeItem = React.forwardRef(
  (
    { node, canAnimate, ...props }: NodeItemProps,
    ref: React.RefObject<SVGCircleElement>
  ) => {
    if (node.type === NodeType.AVATAR) {
      return (
        <g ref={ref} {...props}>
          <CircleContainer
            $shadowColor={node.avatar && node.avatar.clothing.color}
            r={30}
            cx={0}
            cy={0}
            fill={COLORS.white.css}
          />
          <AnimatedAvatar
            avatar={node.avatar}
            width={52}
            height={52}
            notification={false}
            editing={false}
            x={-26}
            y={-26}
            circle
            canAnimate={canAnimate}
          />
        </g>
      );
    }
    if (node.type === NodeType.COMMENT) {
      return (
        <g ref={ref} {...props}>
          <CircleContainer
            r={28}
            cx={0}
            cy={0}
            fill={COLORS.white.css}
            $shadowColor={COLORS.success.regular.css}
          />
          <circle
            r={24}
            cx={0}
            cy={0}
            fill={COLORS.success.light.css}
            stroke={COLORS.success.regular.css}
            strokeWidth={2}
          />
          <CommentIcon
            stroke={COLORS.success.regular.css}
            strokeWidth={2}
            width={24}
            height={24}
            x={-12}
            y={-12}
          />
        </g>
      );
    }
    if (node.type === NodeType.DIAMOND) {
      return (
        <g ref={ref} {...props}>
          <CircleContainer
            r={28}
            cx={0}
            cy={0}
            fill={COLORS.white.css}
            $shadowColor={COLORS.brand.regular.css}
          />
          <circle
            r={24}
            cx={0}
            cy={0}
            fill={COLORS.brand.light.css}
            stroke={COLORS.brand.regular.css}
            strokeWidth={2}
          />
          <BoxIcon
            stroke={COLORS.brand.regular.css}
            strokeWidth={2}
            width={24}
            height={24}
            x={-12}
            y={-12}
          />
        </g>
      );
    }
    if (node.type === NodeType.CIRCLE) {
      return (
        <g ref={ref} {...props}>
          <CircleContainer
            r={28}
            cx={0}
            cy={0}
            fill={COLORS.white.css}
            $shadowColor={COLORS.warn.regular.css}
          />
          <circle
            r={24}
            cx={0}
            cy={0}
            fill={COLORS.warn.light.css}
            stroke={COLORS.warn.regular.css}
            strokeWidth={2}
          />
          <PieChartIcon
            stroke={COLORS.warn.regular.css}
            strokeWidth={2}
            width={24}
            height={24}
            x={-12}
            y={-12}
          />
        </g>
      );
    }
    if (node.type === NodeType.IMAGE) {
      return (
        <g ref={ref} {...props}>
          <CircleContainer
            r={28}
            cx={0}
            cy={0}
            fill={COLORS.white.css}
            $shadowColor={node.color}
          />
          <circle
            r={24}
            cx={0}
            cy={0}
            fill={COLORS.white.css}
            stroke={node.color}
            strokeWidth={2}
          />
          <image href={node.image} x={-18} y={-18} height={36} width={36} />
        </g>
      );
    }
    return <circle r={6} cx={0} cy={0} fill="black" ref={ref} {...props} />;
  }
);

NodeItem.displayName = 'NodeItem';

export const FlowLogo = ({ ...props }) => {
  return (
    <g {...props}>
      <CircleContainer
        r={40}
        cx={0}
        cy={0}
        fill={COLORS.white.css}
        $shadowColor={COLORS.brand.regular.css}
      />
      <g transform="translate(-16,-16)">
        <path
          d={lissajous(32)}
          fill="none"
          stroke={COLORS.black.css}
          strokeWidth={1.5}
        />
      </g>
    </g>
  );
};

type AnimationCollaborationProps = React.HTMLAttributes<SVGElement> & {
  canAnimate: boolean;
};

export default function AnimationCollaboration({
  canAnimate,
  ...props
}: AnimationCollaborationProps) {
  const animation = useRef({ progress: 0, timeline: null });
  const refs = useRef([]);

  useEffect(() => {
    animation.current.timeline = anime({
      targets: animation.current,
      progress: Math.PI * 2,
      duration: 60000,
      loop: true,
      easing: 'linear',
      autoplay: false,
      update: () => {
        refs.current.forEach((ring, index) => {
          const angle = (Math.PI * 2) / ring.circles.length;
          const d = index % 2 === 0 ? 1 : -1;
          ring.circles.forEach((circle, i) => {
            const value = angle * i + animation.current.progress * d;
            anime.set(circle, {
              translateX: WIDTH / 2 + ring.radius * Math.sin(value),
              translateY: HEIGHT / 2 + ring.radius * Math.cos(value),
            });
          });
        });
      },
    });

    const { timeline } = animation.current;

    return () => {
      // pause animation on component unmount
      timeline.pause();
    };
  }, []);

  useEffect(() => {
    const { timeline } = animation.current;
    if (canAnimate) {
      timeline.play();
    } else {
      timeline.pause();
    }
  }, [canAnimate]);

  return (
    <SVG viewBox={`0 0 ${WIDTH} ${HEIGHT}`} {...props}>
      <defs>
        <pattern
          id="grid"
          x="0"
          y="0"
          width={30}
          height={30}
          patternUnits="userSpaceOnUse"
        >
          <circle cx={15} cy={15} r={1.5} fill={COLORS.shades.s200.css} />
        </pattern>
      </defs>

      <rect x="0" y="25%" width="100%" height="50%" fill="url(#grid)" />
      <FlowLogo transform={`translate(${WIDTH / 2},${HEIGHT / 2})`} />
      <g>
        {data.circles.map((circle, i) => {
          const angle = (Math.PI * 2) / circle.nodes.length;
          return (
            <g key={circle.id}>
              <circle
                r={circle.radius}
                cx={WIDTH / 2}
                cy={HEIGHT / 2}
                fill="none"
                stroke={COLORS.black.css}
                strokeWidth={2}
                className="main"
              />
              {circle.nodes.map((node, j) => {
                const value = angle * j;
                return (
                  <NodeItem
                    key={node.id}
                    node={node}
                    ref={(el) => {
                      if (!refs.current[i]) {
                        refs.current[i] = {
                          radius: circle.radius,
                          circles: [el],
                        };
                      } else {
                        refs.current[i].circles[j] = el;
                      }
                    }}
                    transform={`translate(${
                      WIDTH / 2 + circle.radius * Math.sin(value)
                    }, ${HEIGHT / 2 + circle.radius * Math.cos(value)})`}
                    canAnimate={canAnimate}
                  />
                );
              })}
            </g>
          );
        })}
      </g>
    </SVG>
  );
}
