import React, { useEffect, useState, useMemo, useRef } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { nanoid } from 'nanoid';
import anime from 'animejs';

import AnimatedAvatar from './AnimatedAvatar';

import TYPO from '../styles/typography';
import COLORS, { Color } from '../styles/colors';
import Bell from '../styles/animations/Bell';
import RequirementBox from '../styles/animations/RequirementBox';

import { getPathBezier } from '../utils/path';
import data, { Data as DataType } from '../data/featuresChartData';

const WIDTH = 700;
const HEIGHT = 500;

const DOT_GRID = 30;
const DOT_SIZE = 1.5;

const MASK_RADIUS = 20; // percentage;

const TIMELINE_EVENT_HEIGHT = 60;
const TIMELINE_EVENT_GAP = 20;

const TIMELINE_EVENT_BOX_WIDTH = 390;
const TIMELINE_EVENT_BOX_OFFSET = 150;

const NODE_RADIUS = 10;

const BALLOON_HEIGHT = 36;
const BALLOON_WIDTH = 100;
const BALLOON_AVATAR_GAP = 4;
const BALLOON_PIN_SIZE = 10;

const REQ_SIZE = 24;

const TIMELINE_HEIGHT =
  (TIMELINE_EVENT_HEIGHT + TIMELINE_EVENT_GAP) * data.timeline.length;

const TIMELINE_WIDTH = TIMELINE_EVENT_BOX_WIDTH + TIMELINE_EVENT_BOX_OFFSET;
const TIMELINE_LINE_LENGTH = TIMELINE_HEIGHT - TIMELINE_EVENT_GAP;

const highlight = (list, id, iteration = 0) => {
  const { parents } = list[id];

  if (iteration > 0) {
    return parents;
  }

  const sub = parents
    .flatMap((p) => highlight(list, p, iteration + 1))
    .filter((item, pos, self) => self.indexOf(item) === pos);

  return [...parents, ...sub];
};

const valid = (i, n) =>
  Math.floor(i / data.settings.columns) -
    Math.floor(n / data.settings.columns) ===
  1;

type NodeItem = { id: string; position: [number, number]; parents: string[] };
type NodesList = Record<string, NodeItem>;

const getNodesData = (
  list: string[],
  settings: DataType['settings']
): { nodes: NodesList; highlightNodes: string[] } => {
  const gridGapH = WIDTH / settings.columns;
  const gridGapV = HEIGHT / settings.rows;

  const nodes: NodesList = list.reduce((acc, node: string, i: number) => {
    const x = i % settings.columns;
    const y = Math.floor(i / settings.columns);
    const xs = y % 2 === 0 ? gridGapH / 2 : 0;

    const t = i - settings.columns;
    const d = y % 2 === 0 ? 1 : 0;

    const a = d + t;
    const b = d + t - 1;

    const parents = [a, b]
      .filter((n) => valid(i, n))
      .map((p) => list[p])
      .filter(Boolean);

    return {
      ...acc,
      [node]: {
        id: node,
        position: [xs + x * gridGapH, y * gridGapV],
        parents,
      },
    };
  }, {});

  return { nodes, highlightNodes: highlight(nodes, list[settings.mainNode]) };
};

const offset = (size: number) => keyframes`
  0% { stroke-dashoffset: ${size}px; }
  100% {stroke-dashoffset: 0px; }
`;

const Node = styled.g`
  will-change: transform;
  & circle {
    &:first-child {
      r: ${NODE_RADIUS}px;
      fill: ${COLORS.white.css};
      filter: drop-shadow(3px 3px 6px ${COLORS.brand.regular.opacity(0.2)});
    }
  }
`;

type NodeInnerProps = {
  $active: boolean;
  $spinning?: boolean;
};

const NodeInner = styled.circle.attrs<NodeInnerProps>((props) => ({
  stroke: props.$active ? COLORS.brand.regular.css : COLORS.shades.s250.css,
  strokeDasharray: props.$spinning ? '10px 3px' : '0',
}))`
  r: ${NODE_RADIUS - 4}px;
  fill: none;
  stroke-width: 2px;
  animation: ${(props) =>
    props.$spinning
      ? css`
          ${offset(26)} 2s linear infinite
        `
      : 'none'};
`;

type LinkProps = {
  $active: boolean;
};

const Link = styled.path.attrs<LinkProps>((props) => ({
  strokeDasharray: props.$active ? 2 : 0,
  stroke: props.$active ? COLORS.black.css : COLORS.shades.s200.css,
}))`
  will-change: opacity;
  animation: ${(props) =>
    props.$active
      ? css`
          ${offset(4)} 1s linear infinite
        `
      : 'none'};
`;

const Grid = styled.g`
  ${Link} {
    opacity: 0;
  }
`;

const Zoom = styled.g`
  transform-origin: center;
  will-change: transform;
`;

const Timeline = styled.g`
  transform: translate(
    ${(WIDTH - TIMELINE_WIDTH) / 2}px,
    ${(HEIGHT - TIMELINE_HEIGHT) / 2}px
  );
  & line {
    stroke-dasharray: ${TIMELINE_LINE_LENGTH}px;
    will-change: stroke-dashoffset;
  }
  & .event {
    & .date {
      ${TYPO.p1};
      font-size: 14px;
      will-change: transform, opacity;
    }
    & .value {
      ${TYPO.h2}
    }
    & .byline {
      ${TYPO.p1};
      font-size: 16px;
    }
    & .label {
      ${TYPO.small};
      font-size: 13px;
      fill: ${COLORS.shades.s300.css};
    }
    & ${Node} {
      transform-origin: center;
    }
    & .eventBox {
      filter: drop-shadow(3px 3px 6px ${COLORS.brand.regular.opacity(0.1)});
      will-change: transform, opacity;
    }
  }
`;

const Balloon = styled.g`
  & .controller {
    transform-origin: bottom center;
    will-change: transform;
    & .rect {
      will-change: width, x;
    }
    & .notifications {
      will-change: opacity;
    }
  }
  filter: drop-shadow(3px 3px 6px ${COLORS.brand.regular.opacity(0.1)});
`;

const DotGrid = styled.rect`
  will-change: opacity;
`;

const SVG = styled.svg`
  width: 100%;
  height: auto;

  & * {
    transform-box: fill-box;
  }

  &.progress-0 {
    ${Grid} {
      opacity: 0;
      transition: opacity 0.33s ease;
    }
  }
  &.progress-1,
  &.progress-2,
  &.progress-3 {
    ${Timeline} {
      opacity: 0;
      transition: opacity 0.33s ease;
      transition-delay: 0.5s;
    }
  }
`;

const StyledRequirementBox = styled(RequirementBox)`
  filter: drop-shadow(
    3px 3px 6px
      ${(props) =>
        props.success
          ? COLORS.success.regular.opacity(0.2)
          : COLORS.error.regular.opacity(0.2)}
  );
`;

type AvatarContainerProps = {
  circleColor: string;
};
const AvatarContainer = styled.g<AvatarContainerProps>`
  filter: drop-shadow(
    3px 3px 6px ${(props) => new Color(props.circleColor).opacity(0.5)}
  );
`;
type FeaturesChartProps = {
  progress: number;
  canAnimate: boolean;
};

const FeaturesChart = ({ progress, canAnimate }: FeaturesChartProps) => {
  const [id] = useState(nanoid(3));
  const timeline = useRef(null);
  const timelineLine = useRef(null);
  const dotGrid = useRef(null);
  const grid = useRef(null);
  const gridNodes = useRef(null);
  const gridLinks = useRef(null);
  const ballons = useRef(null);
  const zoom = useRef(null);

  const eventBoxes = useRef([]);
  const eventDates = useRef([]);
  const timelineNodes = useRef([]);

  const nodesList = useRef([]);
  const linksList = useRef([]);
  const balloonsList = useRef([]);

  const balloonRects = useRef([]);
  const notifications = useRef([]);
  const requirements = useRef([]);

  const { nodes, highlightNodes } = useMemo(() => {
    return getNodesData(data.nodesList, data.settings);
  }, []);

  useEffect(() => {
    if (canAnimate && typeof progress === 'number') {
      anime({
        targets: eventBoxes.current,
        translateX: progress === 0 ? 0 : 50,
        opacity: progress === 0 ? 1 : 0,
        easing: 'easeInOutCubic',
        duration: 333,
        delay: anime.stagger(100, { start: progress === 0 ? 400 : 0 }),
      });

      anime({
        targets: eventDates.current,
        translateX: progress === 0 ? 0 : -50,
        opacity: progress === 0 ? 1 : 0,
        easing: 'easeInOutCubic',
        duration: 333,
        delay: anime.stagger(30, { start: progress === 0 ? 400 : 0 }),
      });

      anime({
        targets: timelineLine.current,
        strokeDashoffset: progress === 0 ? 0 : TIMELINE_LINE_LENGTH,
        easing: 'easeInOutCubic',
        duration: 333,
        delay: progress === 0 ? 600 : 0,
      });

      anime({
        targets: dotGrid.current,
        opacity: progress > 0 ? 1 : 0,
        easing: 'easeInOutCubic',
        duration: 333,
      });

      anime({
        targets: timelineNodes.current,
        scale: progress > 0 ? 0 : 1,
        easing: 'easeInOutCubic',
        duration: 333,
        delay: anime.stagger(30, { start: progress > 0 ? 0 : 400 }),
      });

      anime({
        targets: zoom.current,
        scale: progress > 1 ? 1.5 : 1,
        translateX: progress > 1 ? 65 : 0,
        translateY: progress > 1 ? 140 : 0,
        easing: 'easeInOutCubic',
        duration: 333,
      });

      anime({
        targets: nodesList.current,
        scale: progress > 0 ? 1 : 0,
        easing: 'easeInOutCubic',
        duration: 333,
        delay: anime.stagger(80, {
          start: progress > 0 ? 400 : 0,
          grid: [data.settings.columns, data.settings.rows],
          from: 'center',
        }),
      });

      // flat links list and filter out the invalid elements
      // since some elements might be undefined
      const links = linksList.current.flat().filter(Boolean);
      anime({
        targets: links,
        opacity: progress > 0 ? 1 : 0,
        easing: 'easeInOutCubic',
        duration: 333,
        delay: anime.stagger(80, {
          start: progress > 0 ? 400 : 0,
          grid: [data.settings.columns, data.settings.rows],
          from: 'center',
        }),
      });

      anime({
        targets: balloonsList.current,
        scale: progress > 1 ? 1 : 0,
        easing: 'easeOutElastic(1, .6)',
        duration: 1000,
        delay: anime.stagger(20),
      });

      anime({
        targets: notifications.current,
        opacity: progress === 2 ? 1 : 0,
        easing: 'easeInOutCubic',
        duration: 333,
        delay: anime.stagger(100),
      });

      anime({
        targets: balloonRects.current,
        width: progress === 3 ? BALLOON_WIDTH / 2 : BALLOON_WIDTH,
        x: progress === 3 ? BALLOON_WIDTH / 4 : 0,
        easing: 'easeInOutCubic',
        duration: 333,
        delay: anime.stagger(20),
      });

      anime({
        targets: requirements.current,
        opacity: progress === 3 ? 1 : 0,
        easing: 'easeInOutCubic',
        duration: 333,
        delay: anime.stagger(100),
      });
    }
  }, [progress, canAnimate]);

  return (
    <SVG viewBox={`0 0 ${WIDTH} ${HEIGHT}`} className={`progress-${progress}`}>
      <defs>
        <linearGradient id={`gradientV-${id}`} gradientTransform="rotate(90)">
          <stop offset="1%" stopColor="black" />
          <stop offset={`${MASK_RADIUS}%`} stopColor="white" />
          <stop offset={`${100 - MASK_RADIUS}%`} stopColor="white" />
          <stop offset="99%" stopColor="black" />
        </linearGradient>
        <linearGradient id={`gradientH-${id}`} gradientTransform="rotate(0)">
          <stop offset="1%" stopColor="black" />
          <stop offset={`${MASK_RADIUS}%`} stopColor="white" />
          <stop offset={`${100 - MASK_RADIUS}%`} stopColor="white" />
          <stop offset="99%" stopColor="black" />
        </linearGradient>

        <pattern
          id={`grid-${id}`}
          x="0"
          y="0"
          width={DOT_GRID}
          height={DOT_GRID}
          patternUnits="userSpaceOnUse"
        >
          <circle
            cx={DOT_GRID / 2}
            cy={DOT_GRID / 2}
            r={DOT_SIZE}
            fill={COLORS.shades.s200.css}
          />
        </pattern>

        <mask id={`maskV-${id}`}>
          <rect
            x="0"
            y="0"
            width="100%"
            height="100%"
            fill={`url(#gradientV-${id})`}
          />
        </mask>
        <mask id={`maskH-${id}`}>
          <rect
            x="0"
            y="0"
            width="100%"
            height="100%"
            fill={`url(#gradientH-${id})`}
          />
        </mask>
      </defs>

      <Timeline ref={timeline}>
        <line
          x1="120"
          y1="0"
          x2="120"
          y2={TIMELINE_LINE_LENGTH}
          stroke={COLORS.shades.s250.css}
          className="timeline"
          ref={timelineLine}
        />
        <g className="events">
          {data.timeline.map((event, index) => {
            return (
              <g
                className="event"
                key={event.date}
                transform={`translate(0, ${
                  (TIMELINE_EVENT_HEIGHT + TIMELINE_EVENT_GAP) * index
                })`}
              >
                <text
                  className="date"
                  fill={COLORS.shades.s300.css}
                  x="93"
                  y="37"
                  textAnchor="end"
                  ref={(el) => {
                    eventDates.current[index] = el;
                  }}
                >
                  {event.date}
                </text>
                <Node
                  className="node"
                  ref={(el) => {
                    timelineNodes.current[index] = el;
                  }}
                >
                  <circle cx="120" cy={TIMELINE_EVENT_HEIGHT / 2} />
                  <NodeInner
                    cx="120"
                    cy={TIMELINE_EVENT_HEIGHT / 2}
                    $active={event.current}
                  />
                </Node>
                <g
                  className="eventBox"
                  ref={(el) => {
                    eventBoxes.current[index] = el;
                  }}
                >
                  <rect
                    width={TIMELINE_EVENT_BOX_WIDTH}
                    height={TIMELINE_EVENT_HEIGHT}
                    x={TIMELINE_EVENT_BOX_OFFSET}
                    y="0"
                    fill={COLORS.white.css}
                    rx="6"
                  />
                  <text className="value" x="163" y="38">
                    {event.value}
                  </text>
                  <text className="label" x="245" y="22">
                    <tspan fontWeight={700}>{event.name}</tspan>
                    {` ${event.label}`}
                  </text>
                  <text className="byline" x="245" y="43">
                    {event.byline}
                  </text>

                  <AvatarContainer
                    className="avatar"
                    transform={`translate(${
                      TIMELINE_EVENT_BOX_OFFSET + TIMELINE_EVENT_BOX_WIDTH - 50
                    }, ${(TIMELINE_EVENT_HEIGHT - 30) / 2})`}
                    circleColor={event.author.clothing.color}
                  >
                    <circle r={15} cx={15} cy={15} fill="white" />
                    <AnimatedAvatar
                      width={30}
                      height={30}
                      avatar={event.author}
                      editing={false}
                      notification={false}
                      x={0}
                      y={0}
                      circle
                      canAnimate={false}
                    />
                  </AvatarContainer>
                </g>
              </g>
            );
          })}
        </g>
      </Timeline>
      <Grid ref={grid}>
        <DotGrid
          x="0"
          y="0"
          width="100%"
          height="100%"
          fill={`url(#grid-${id})`}
          ref={dotGrid}
        />
        <g mask={`url(#maskH-${id})`}>
          <g mask={`url(#maskV-${id})`}>
            <Zoom ref={zoom}>
              <g className="links" ref={gridLinks}>
                {Object.values(nodes).map((node, indexNode) => {
                  if (!node.parents) {
                    return null;
                  }

                  return node.parents.map((parent, indexParent) => {
                    if (!parent) {
                      return null;
                    }
                    const d = getPathBezier(
                      node.position,
                      nodes[parent].position
                    );

                    return (
                      <Link
                        key={`${parent}-${node.id}`}
                        d={d}
                        fill="none"
                        $active={
                          progress > 1
                            ? data.nodesList[data.settings.mainNode] ===
                                node.id || highlightNodes.includes(node.id)
                            : true
                        }
                        ref={(el) => {
                          if (!linksList.current[indexNode]) {
                            linksList.current[indexNode] = [];
                          }
                          linksList.current[indexNode][indexParent] = el;
                        }}
                      />
                    );
                  });
                })}
              </g>
              <g className="nodes" ref={gridNodes}>
                {Object.values(nodes).map((node, index) => {
                  return (
                    <Node
                      className={`node ${
                        data.nodesList[data.settings.mainNode] === node.id &&
                        'current'
                      }`}
                      key={node.id}
                      ref={(el) => {
                        nodesList.current[index] = el;
                      }}
                    >
                      <circle cx={node.position[0]} cy={node.position[1]} />
                      <NodeInner
                        cx={node.position[0]}
                        cy={node.position[1]}
                        $active={
                          progress > 1
                            ? data.nodesList[data.settings.mainNode] ===
                                node.id || highlightNodes.includes(node.id)
                            : true
                        }
                        $spinning={
                          (progress === 3 &&
                            highlightNodes.includes(node.id)) ||
                          (progress === 2 &&
                            data.nodesList[data.settings.mainNode] === node.id)
                        }
                      />
                    </Node>
                  );
                })}
              </g>
              <g className="balloons" ref={ballons}>
                {highlightNodes.map((id, index) => {
                  const node = nodes[id];

                  const x = BALLOON_WIDTH / 2;
                  const y = BALLOON_HEIGHT + NODE_RADIUS + 6;

                  return (
                    <Balloon
                      key={id}
                      transform={`translate(${node.position[0]}, ${node.position[1]})`}
                    >
                      <g transform={`translate(${-x}, ${-y})`}>
                        <g
                          className="controller"
                          ref={(el) => {
                            balloonsList.current[index] = el;
                          }}
                        >
                          <rect
                            height={BALLOON_HEIGHT}
                            width={BALLOON_WIDTH}
                            x={0}
                            y={0}
                            fill={COLORS.white.css}
                            rx={BALLOON_HEIGHT / 2}
                            className="rect"
                            ref={(el) => {
                              balloonRects.current[index] = el;
                            }}
                          />
                          <rect
                            height={BALLOON_PIN_SIZE}
                            width={BALLOON_PIN_SIZE}
                            x={x}
                            y={BALLOON_HEIGHT - BALLOON_PIN_SIZE + 3}
                            fill={COLORS.white.css}
                            transform="rotate(45)"
                          />
                          <g
                            className="notification"
                            ref={(el) => {
                              notifications.current[index] = el;
                            }}
                          >
                            {data.notifications[index] && progress === 2 && (
                              <>
                                {data.notifications[index].map(
                                  (avatar, index) => (
                                    <AvatarContainer
                                      className="avatar"
                                      transform={`translate(${
                                        index * (30 + BALLOON_AVATAR_GAP) + 3
                                      }, 3)`}
                                      circleColor={avatar.clothing.color}
                                      key={`avatar-${avatar.hair.style}-${id}`}
                                    >
                                      <circle
                                        r={15}
                                        cx={15}
                                        cy={15}
                                        fill="white"
                                      />
                                      <AnimatedAvatar
                                        width={30}
                                        height={30}
                                        avatar={avatar}
                                        editing={false}
                                        notification={false}
                                        x={0}
                                        y={0}
                                        circle
                                      />
                                    </AvatarContainer>
                                  )
                                )}
                                <Bell size={20} x={72} y={8} strokeWidth={4} />
                              </>
                            )}
                          </g>
                          <g
                            className="requirements"
                            ref={(el) => {
                              requirements.current[index] = el;
                            }}
                          >
                            {progress === 3 && (
                              <StyledRequirementBox
                                size={REQ_SIZE}
                                strokeWidth={1}
                                x={BALLOON_WIDTH / 2 - REQ_SIZE / 2}
                                y={BALLOON_HEIGHT / 2 - REQ_SIZE / 2}
                                success={Math.random() > 0.5}
                              />
                            )}
                          </g>
                        </g>
                      </g>
                    </Balloon>
                  );
                })}
              </g>
            </Zoom>
          </g>
        </g>
      </Grid>
    </SVG>
  );
};

export default FeaturesChart;
