import { nanoid } from 'nanoid';
import COLORS from '../styles/colors';

import { Avatar, DEFAULT_AVATAR_SETTINGS } from '../components/AnimatedAvatar';

type Event = {
  date: string;
  value: number;
  label: string;
  name: string;
  byline: string;
  author: Avatar;
  current?: boolean;
};

export type Data = {
  settings: { columns: number; rows: number; mainNode: number };
  timeline: Event[];
  notifications: Avatar[][];
  nodesList: string[];
};

const settings = {
  columns: 5,
  rows: 7,
  mainNode: 17,
};

const data: Data = {
  settings,
  timeline: [
    {
      date: '30 days ago',
      value: 3600,
      name: 'Jane',
      label: 'updated Cell Capacity/mAh',
      byline: 'Assumption',
      author: {
        ...DEFAULT_AVATAR_SETTINGS,
        clothing: { style: 2, color: COLORS.rainbow.purple.regular.css },
        lips: true,
        hair: { style: 1, ponytail: true },
      },
    },
    {
      date: '10 days ago',
      value: 3000,
      name: 'Paul',
      label: 'updated Cell Capacity/mAh',
      byline: 'Calculation',
      author: {
        ...DEFAULT_AVATAR_SETTINGS,
        clothing: { style: 5, color: COLORS.rainbow.cyan.regular.css },
        hair: { style: 5 },
        eyebrows: { ...DEFAULT_AVATAR_SETTINGS.eyebrows, width: 4 },
      },
    },
    {
      date: '3 days ago',
      value: 3200,
      name: 'Aisha',
      label: 'updated Cell Capacity/mA',
      byline: 'Simulation',
      author: {
        ...DEFAULT_AVATAR_SETTINGS,
        clothing: { style: 3, color: COLORS.rainbow.green.regular.css },
        lips: true,
        skin: String(COLORS.warn.dark.mix(COLORS.white, 0.5)),
        hair: { style: 3 },
        glasses: true,
        earrings: true,
      },
    },
    {
      date: 'yesterday',
      value: 3250,
      name: 'Rob',
      label: 'updated Cell Capacity/mAh',
      byline: 'Supplier confirmed',
      current: true,
      author: {
        ...DEFAULT_AVATAR_SETTINGS,
        clothing: { style: 1, color: COLORS.rainbow.yellow.light.css },
        hair: { style: 4 },
      },
    },
  ],
  notifications: [
    [
      {
        ...DEFAULT_AVATAR_SETTINGS,
        clothing: { style: 1, color: COLORS.rainbow.yellow.light.css },
        hair: { style: 4 },
      },
      {
        ...DEFAULT_AVATAR_SETTINGS,
        clothing: { style: 3, color: COLORS.rainbow.purple.light.css },
        lips: true,
        skin: String(COLORS.warn.dark.mix(COLORS.white, 0.5)),
        hair: { style: 3 },
        glasses: true,
        earrings: true,
      },
    ],
    [
      {
        ...DEFAULT_AVATAR_SETTINGS,
        clothing: { style: 3, color: COLORS.rainbow.cyan.regular.css },
        lips: true,
        hair: { style: 5 },
        earrings: true,
      },
      {
        ...DEFAULT_AVATAR_SETTINGS,
        clothing: { style: 2, color: COLORS.rainbow.yellow.light.css },
        lips: true,
        hair: { style: 1, ponytail: true },
      },
    ],
    [
      {
        ...DEFAULT_AVATAR_SETTINGS,
        clothing: { style: 4, color: COLORS.rainbow.green.regular.css },
        lips: true,
        hair: { style: 2 },
      },
      {
        ...DEFAULT_AVATAR_SETTINGS,
        clothing: { style: 4, color: COLORS.rainbow.yellow.light.css },
        lips: true,
        hair: { style: 1 },
      },
    ],
    [
      {
        ...DEFAULT_AVATAR_SETTINGS,
        clothing: { style: 1, color: COLORS.rainbow.purple.light.css },
        hair: { style: 4 },
      },
      {
        ...DEFAULT_AVATAR_SETTINGS,
        clothing: { style: 1, color: COLORS.rainbow.cyan.regular.css },
        hair: { style: 3 },
      },
    ],
    [
      {
        ...DEFAULT_AVATAR_SETTINGS,
        clothing: { style: 3, color: COLORS.rainbow.cyan.regular.css },
        hair: { style: 0 },
      },
      {
        ...DEFAULT_AVATAR_SETTINGS,
        clothing: { style: 4, color: COLORS.rainbow.green.regular.css },
        lips: true,
        hair: { style: 2 },
      },
    ],
  ],
  nodesList: Array((settings.columns + 1) * (settings.rows + 1))
    .fill(0)
    .map(() => nanoid(3)),
};

export default data;
