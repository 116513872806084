import React, { memo } from 'react';
import styled from 'styled-components';

import type { BlockLocationFragment } from '../schemas/api';

import useInview from '../hooks/useInview';

import Link from './Link';
import Image from './Image';

import * as textUtils from '../utils/text';

import { staggerChildrenFadeTranslateIn } from '../styles/animations';
import COLORS from '../styles/colors';
import TYPO from '../styles/typography';
import BREAKPOINTS from '../styles/breakpoints';

import { Grid, columns } from '../styles/grid';

const Outer = styled.div`
  position: relative;
`;

const Container = styled(Grid)`
  padding: 120px 12px;
  ${BREAKPOINTS.max.medium`
    padding: 120px 12px;
  `}
`;

const Inner = styled.div`
  ${columns(1, 12)}
  display: flex;
  flex-direction: column;
  gap: 60px;
  width: 100%;
`;

type ContentProps = { $show: boolean };

const HeadingSection = styled.div<ContentProps>`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  ${BREAKPOINTS.max.small`
    ${columns(1, 12)}
    `}
  ${(props) => staggerChildrenFadeTranslateIn(props.$show)}
`;

const Heading = styled.h1`
  ${TYPO.h2}
`;

const List = styled.div<ContentProps>`
  padding: 12px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 24px;

  ${(props) =>
    staggerChildrenFadeTranslateIn(props.$show, -10, undefined, 0.05)}
`;

const Card = styled.div`
  flex: 0 0 calc(25% - 24px);
  ${BREAKPOINTS.max.small`
  flex: 0 0 calc(50% - 24px);
  `}
  ${BREAKPOINTS.max.extraSmall`
  flex: 0 0 calc(100% - 24px);
  `}
  max-width: 360px;
  overflow: hidden;
  background: ${COLORS.white.css};
  box-shadow: 0px 0px 1px ${COLORS.brand.regular.opacity(0.2)},
    0px 4px 100px ${COLORS.brand.regular.opacity(0.1)};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
  border-radius: 9px;
  padding: 24px;
  position: relative;

  & > a {
    ${TYPO.p2};
    color: ${COLORS.brand.regular.css};
    font-weight: 500;
    &:hover {
      text-decoration: underline;
    }
  }
`;

const Name = styled.div`
  ${TYPO.p1};
  font-size: 18px;
  font-weight: 700;
`;

const Address = styled.div`
  ${TYPO.p2};
  white-space: pre-wrap;
`;

const IconContainer = styled.div`
  display: flex;
  margin-bottom: 12px;
`;

const Background = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  height: 300px;
  width: 100%;
  display: flex;
  z-index: 0;
`;

const DotGrid = styled.div`
  width: 100%;
  height: 100%;
  background: radial-gradient(rgba(0, 0, 0, 0.2) 1px, transparent 1px);
  background-size: 24px 24px;
  align-self: flex-end;
`;

type BlockCtaProps = {
  className?: string;
  data: BlockLocationFragment;
};
function BlockLocationsComponent({ className, data }: BlockCtaProps) {
  const [inViewRef, inView] = useInview({});

  return (
    <Outer>
      <Background>
        <DotGrid />
      </Background>
      <Container className={className}>
        <Inner>
          <HeadingSection ref={inViewRef} $show={inView}>
            {data.heading && (
              <Heading>{textUtils.highlight(data.heading)}</Heading>
            )}
          </HeadingSection>
          <List $show={inView}>
            {data.locations.items.map((location) => {
              return (
                <Card key={location.name}>
                  {location.icon && (
                    <IconContainer>
                      <Image
                        src={location.icon.url}
                        alt={location.name}
                        width={60}
                        height={60}
                      />
                    </IconContainer>
                  )}
                  <Name>{location.name}</Name>
                  <Address>{location.address}</Address>
                  <Link link={location.link}>
                    {location.link.label}
                    {' →'}
                  </Link>
                </Card>
              );
            })}
          </List>
        </Inner>
      </Container>
    </Outer>
  );
}

export default memo(BlockLocationsComponent);
