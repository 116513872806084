import React, { memo } from 'react';
import styled from 'styled-components';

import type { BlockLogoAndTestimonialsFragment } from '../schemas/api';

import Heading from './Heading';

import useInview from '../hooks/useInview';

import TestimonialsCarousel from './Testimonials';

import BREAKPOINTS from '../styles/breakpoints';
import { Grid, columns, grid } from '../styles/grid';

import CompanyInsight from './CompanyInsight';
import { spacing } from '../styles/blocks';

type ContainerProps = {
  $spacing: string;
};
const Container = styled(Grid)<ContainerProps>`
  ${(props) => spacing(props.$spacing || '')}
`;

const TestimonialsContainer = styled.div`
  ${columns(1, 12)};
  display: flex;
  padding: 12px 0;
`;

const CompanyInsights = styled.div`
  ${columns(1, 12)};
  display: flex;
  padding: 12px;
  ${grid(2)};

  ${BREAKPOINTS.max.small`
  ${grid(1)};
`}

  column-gap: 24px;
  row-gap: 24px;
`;

type BlockLogoAndTestimonialsProps = {
  data: BlockLogoAndTestimonialsFragment;
};
function BlockLogoAndTestimonials({ data }: BlockLogoAndTestimonialsProps) {
  const [inViewRef, inView] = useInview({});

  const testimonials = data.testimonials?.items;
  const insights = data.companyInsights?.items;
  return (
    <Container $spacing={data.spacing}>
      <Heading
        heading={data.heading}
        show={inView}
        ref={inViewRef}
        headingLevel="h3"
      />
      {Array.isArray(testimonials) && !!testimonials.length && (
        <TestimonialsContainer>
          <TestimonialsCarousel items={testimonials} />
        </TestimonialsContainer>
      )}
      {Array.isArray(insights) && !!insights.length && (
        <CompanyInsights>
          {insights.map((d) => {
            return <CompanyInsight item={d} key={d.name} />;
          })}
        </CompanyInsights>
      )}
    </Container>
  );
}

export default memo(BlockLogoAndTestimonials);
