import styled, { css } from 'styled-components';

import COLORS from './colors';
import TYPO from './typography';

type Variant = 'primary' | 'secondary';

const getVariant = (variant: Variant) => {
  switch (variant) {
    case 'secondary':
      return css`
        background: ${COLORS.white.css};
        border-color: ${COLORS.shades.s200.css};
        color: ${COLORS.shades.s500.css};
        ::placeholder {
          color: ${COLORS.shades.s250.css};
        }
        &:focus {
          border-color: ${COLORS.brand.regular.css};
        }
        &[disabled='disabled'],
        &:disabled {
          color: ${COLORS.shades.s400.css};
          background: ${COLORS.shades.s100.css};
        }
        &[data-error='true'] {
          border-color: ${COLORS.error.regular.css};
        }
      `;
    case 'primary':
    default:
      return css`
        background: ${COLORS.brand.light.mix(COLORS.white, 0.6) as string};
        border-color: ${COLORS.brand.light.css};
        color: ${COLORS.shades.s500.css};
        ::placeholder {
          color: ${COLORS.shades.s300.css};
          opacity: 0.4;
        }
        &:focus {
          border-color: ${COLORS.brand.regular.css};
        }
        &[disabled='disabled'],
        &:disabled {
          color: ${COLORS.shades.s400.css};
          background: ${COLORS.shades.s100.css};
        }
        &[data-error='true'] {
          border-color: ${COLORS.error.regular.css};
        }
      `;
  }
};

type TextAreaProps = {
  $variant: Variant;
};
export const TextArea = styled.textarea<TextAreaProps>`
  width: 100%;
  min-height: 160px;
  max-height: 300px;
  resize: vertical;
  ${TYPO.p2};

  border-style: solid;
  border-width: 1px;

  border-radius: 3px;
  outline: none;
  padding: 12px;

  ${(props) => getVariant(props.$variant)}

  &[disabled='disabled'],
  &:disabled {
    pointer-events: none;
  }
`;

type InputProps = {
  $variant?: Variant;
};
export const Input = styled.input<InputProps>`
  ${TYPO.p2}
  outline: none;
  flex: 1;
  width: 100%;
  height: 36px;
  border-width: 1px;
  border-style: solid;
  border-radius: 3px;
  padding: 12px 12px;

  ${(props) => getVariant(props.$variant)}
`;

export const Label = styled.label`
  ${TYPO.p2}
`;

export const LabelRow = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 6px;
  gap: 6px;
`;

export const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
  button[type='submit'] {
    align-self: flex-end;
  }
`;

export const ErrorMessage = styled.div`
  display: flex;
  align-items: baseline;
  gap: 6px;
  ${TYPO.p2};
  color: ${COLORS.error.regular.css};
`;
