import React, { memo } from 'react';
import styled from 'styled-components';
import Image from './Image';

import type {
  BlockMediaCollectionFragment,
  MediaItemFragment,
} from '../schemas/api';

import { MediaAnimation } from '../enums/blocks';

import TYPO from '../styles/typography';
import { Grid, columns, grid } from '../styles/grid';
import Button from '../styles/button';
import { staggerChildrenFadeTranslateIn } from '../styles/animations';

import Link from './Link';
import Icon from './Icon';
import Markdown from './Markdown';
import BREAKPOINTS from '../styles/breakpoints';
import useInview from '../hooks/useInview';

import * as textUtils from '../utils/text';

import AnimationCollaboration from './AnimationCollaboration';
import AnimationSync from './AnimationSync';
import AnimationContinuousIntegration from './AnimationContinuousIntegration';
import markdownStyles from '../styles/markdown';
import { spacing } from '../styles/blocks';
import MarkdownInline from './MarkdownInline';

type ContainerProps = {
  $spacing: string;
};

const Container = styled(Grid)<ContainerProps>`
  ${(props) => spacing(props.$spacing || '')}
  position:relative;
`;

const ItemHeading = styled.h1`
  ${TYPO.h2}
  font-weight: 500;
  font-size: 32px;
`;

const Blocks = styled.div`
  ${columns(1, 12)}
  padding-top: 144px;
  display: flex;
  flex-direction: column;
  gap: 174px;
  ${BREAKPOINTS.max.small`
    gap: 144px;
  `}
`;

type ContentProps = {
  $show?: boolean;
};

const Content = styled.div<ContentProps>`
  display: flex;
  flex-direction: column;
  gap: 12px;
  p {
    ${TYPO.p1}
  }
  ${(props) => staggerChildrenFadeTranslateIn(props.$show)}
`;

const IntroImageContainer = styled.div``;
const Paragraph = styled.div`
  & > div {
    ${markdownStyles}
  }
  &:first-child {
    margin-top: 0;
  }
`;

type BlockProps = {
  $reverse: boolean;
};
const Block = styled.div<BlockProps>`
  ${columns(1, 12)}
  ${grid(12)}
  grid-auto-flow: dense;

  ${Content} {
    ${(props) => (props.$reverse ? columns(2, 4) : columns(8, 4))}
    text-align: left;
    display: flex;
    justify-content: center;
    flex-direction: column;
    ${BREAKPOINTS.max.small`
      order:2;
      margin-top:36px;
      text-align:center;
      align-items: center;
      ${columns(null, 12)}
    `}
  }

  ${IntroImageContainer} {
    ${(props) => (props.$reverse ? columns(7, 6) : columns(1, 6))}
    ${BREAKPOINTS.max.small`
      order:1;
      ${columns(null, 12)}
    `}
  }
`;

const HeadingSection = styled.div<ContentProps>`
  ${columns(1, 12)};
  text-align: center;
  ${(props) => staggerChildrenFadeTranslateIn(props.$show)}
`;

const Heading = styled.h1`
  ${TYPO.h3};
  font-size: 24px;
  font-weight: 500;
  max-width: 700px;
  margin: 0 auto;
`;

const GetAnimation = (style: string, canAnimate: boolean) => {
  switch (style) {
    case MediaAnimation.COLLABORATION:
      return <AnimationCollaboration canAnimate={canAnimate} />;
    case MediaAnimation.SYNC:
      return <AnimationSync canAnimate={canAnimate} />;
    case MediaAnimation.CONTINUOUS_INTEGRATION:
      return <AnimationContinuousIntegration canAnimate={canAnimate} />;
    default:
      return <div>Animation {style} not found</div>;
  }
};

type BlockAlternatingItemProps = {
  data: MediaItemFragment;
  reverse: boolean;
};

function BlockAlternatingItem({ data, reverse }: BlockAlternatingItemProps) {
  const [inViewRef, inView] = useInview({});
  const [inViewContentRef, inViewContent] = useInview({ once: false });

  return (
    <Block $reverse={reverse}>
      <Content ref={inViewRef} $show={inView}>
        {data.heading && (
          <ItemHeading>
            <MarkdownInline>{data.heading}</MarkdownInline>
          </ItemHeading>
        )}
        <Paragraph>
          <Markdown content={data.content} />
        </Paragraph>
        {data.cta && (
          <Link link={data.cta}>
            <Button>
              <Icon name="ArrowRight" width={12} height={12} />
              {data.cta.label}
            </Button>
          </Link>
        )}
      </Content>
      <IntroImageContainer ref={inViewContentRef}>
        {data.animation && GetAnimation(data.animation, inViewContent)}
        {data.media && (
          <Image
            src={data.media.url}
            alt={data.heading}
            height={data.media.height}
            width={data.media.width}
            style={{ width: '100%', height: 'auto' }}
          />
        )}
      </IntroImageContainer>
    </Block>
  );
}

type BlockAlternatingFeaturesProps = {
  data: BlockMediaCollectionFragment;
};

function BlockAlternatingFeatures({ data }: BlockAlternatingFeaturesProps) {
  const [inViewRef, inView] = useInview({});
  return (
    <Container $spacing={data.spacing}>
      {data.heading && (
        <HeadingSection ref={inViewRef} $show={inView}>
          <Heading>{textUtils.highlight(data.heading)}</Heading>
        </HeadingSection>
      )}
      <Blocks>
        {data &&
          data.mediaCollection.items.map((item, i) => (
            <BlockAlternatingItem
              data={item}
              reverse={
                data.alignment && data.alignment?.toLowerCase() === 'right'
                  ? i % 2 === 1
                  : i % 2 === 0
              }
              key={item.heading || item.content}
            />
          ))}
      </Blocks>
    </Container>
  );
}

export default memo(BlockAlternatingFeatures);
