import { css } from 'styled-components';

import COLORS from './colors';

const styles = {
  s100: css`
    box-shadow: 0px 1px 5px 0px ${COLORS.black.opacity(0.05) as string};
  `,
  b100: css`
    box-shadow: 0px -1px 5px 0px ${COLORS.black.opacity(0.05) as string};
  `,
  s200: css`
    box-shadow: 0px 4px 10px 0px ${COLORS.black.opacity(0.1) as string},
      0px 0px 1px 0px ${COLORS.black.opacity(0.2) as string};
  `,
  s300: css`
    box-shadow: 0px 0px 1px 0px ${COLORS.black.opacity(0.2) as string},
      0px 10px 18px 0px ${COLORS.black.opacity(0.15) as string};
  `,
  s400: css`
    box-shadow: 0px 0px 1px 0px ${COLORS.black.opacity(0.2) as string},
      0px 18px 28px 0px ${COLORS.black.opacity(0.15) as string};
  `,
};

export default styles;
