import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { useInterval } from 'react-use';
import { animated, useTransition } from '@react-spring/web';
import markdownStyles from '../styles/markdown';
import TYPO from '../styles/typography';
import COLORS from '../styles/colors';
import Image from './Image';
import BREAKPOINTS from '../styles/breakpoints';
import { Direction, generate } from '../styles/dotted-line';
import data from '../data/automatedData';

import RequirementBox from '../styles/animations/RequirementBox';
import Markdown from './Markdown';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flext-start;
  font-size: 13px;
  width: 100%;
  padding: 12px;
  overflow: hidden;

  ${BREAKPOINTS.max.small`
  font-size:10px;
  padding: 0;
  max-height:330px;
`}
`;

const List = styled.div`
  padding-top: 48px;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  gap: 24px;
  position: relative;
  height: 600px;
  ${BREAKPOINTS.max.medium`
  padding-top: 24px;
 gap:12px;
`}

  ${BREAKPOINTS.max.small`
  padding-top: 6px;
 gap:12px;
`}
`;

const Item = styled(animated.div)`
  display: flex;
  max-width: 100%;
  width: 100%;

  overflow: hidden;
  gap: 12px;

  align-items: flex-start;
  position: relative;
  z-index: 1;
`;

const Content = styled(animated.div)`
  flex: 1;
  overflow: hidden;
`;

const Inner = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0px;
`;

const Title = styled.div`
  display: inline-flex;
  gap: 3px;
  align-items: center;
  color: ${COLORS.black.css};
  ${TYPO.p2};
  font-size: 0.9em;
  min-height: 36px;
  height: 36px;
  white-space: nowrap;
  overflow: hidden;

  word-break: break-word;
  & strong {
    white-space: nowrap;
  }
  flex: 0 0 auto;
`;

const Box = styled.div`
  padding: 3px 9px;
  display: inline-flex;
  border: 1px solid ${COLORS.shades.s200.css};
  background: ${COLORS.shades.s100.css};
  border-radius: 3px;
  align-items: center;
  gap: 3px;
  margin: 0 3px;
  ${TYPO.p2};
  font-size: 0.9em;

  white-space: nowrap;
`;

const Comment = styled.div`
  display: flex;

  padding: 6px;
  border: 1px solid ${COLORS.shades.s200.css};
  background: ${COLORS.shades.s100.css};
  border-radius: 3px;
  align-items: center;
  gap: 3px;
  ${TYPO.p2};
  font-size: 0.9em;
  flex: 0 0 auto;

  max-width: 400px;

  & > div {
    ${markdownStyles}
    & > p {
      line-height: 160%;
    }
    & strong {
      font-weight: 600;
    }
  }
`;

const HumanId = styled.span`
  color: ${COLORS.shades.s300.css};
  ${TYPO.p3};
  font-size: 0.8em;
`;

const Avatar = styled(animated.div)`
  width: 30px;
  height: 30px;
  min-height: 30px;
  border-radius: 50%;
  background: ${COLORS.shades.s100.css};
  overflow: hidden;
  display: flex;
  flex: 0 0 auto;

  ${BREAKPOINTS.max.small`
  width: 24px;
  height: 24px;
  min-height: 24px;
`}
`;

const Line = styled.div`
  position: absolute;
  left: 15px;
  top: -16px;

  z-index: 0;

  position: absolute;
  height: 100%;
  width: 1px;

  ${BREAKPOINTS.max.small`
  left: 12px;
`}

  ${generate(COLORS.shades.s300.css, Direction.VERTICAL)}

  mask-image: linear-gradient(to bottom, 
    ${COLORS.black.opacity(0)} 0%,
    ${COLORS.black.opacity(1)} 20%,
    ${COLORS.black.opacity(1)} 80%,
    ${COLORS.black.opacity(0)} 100%);
`;

const offset = <T,>(arr: T[], offset: number, limit: number) =>
  [...arr.slice(offset), ...arr.slice(0, offset)].slice(0, limit);

type AnimationIntegrateProps = React.HTMLAttributes<SVGElement> & {
  canAnimate: boolean;
};

export default function AnimationIntegrate({
  canAnimate,
}: AnimationIntegrateProps) {
  const refMap = useMemo(() => new WeakMap(), []);
  const [count, setCount] = useState(0);

  useInterval(
    () => {
      setCount((prev) => (prev + 1) % data.items.length);
    },
    canAnimate ? 4000 : null
  );

  const index = count % data.items.length;
  const items = offset(data.items, index, 5).reverse();

  const transitions = useTransition(items, {
    keys: (d) => d.id.toString(),
    from: { opacity: 0, x: 50, scale: 0, y: 0, height: 0 },
    enter: (d) => async (next) => {
      const ref = refMap.get(d);
      await next({
        opacity: 1,
        x: 0,
        scale: 1,
        y: 0,
        height: (ref?.offsetHeight || 0) + 2 || 40,
      });
    },

    leave: { opacity: 0, y: 20, scale: 0 },
  });

  return (
    <Container
      style={{ maxWidth: `${data.size[0]}px`, height: `${data.size[1]}px` }}
    >
      <List>
        <Line />
        {transitions(({ opacity, x, y, height, scale }, item) => {
          return (
            <Item style={{ opacity, y, height }}>
              <Avatar style={{ scale }}>
                <Image src={item.user} width={36} height={36} alt="Avatar" />
              </Avatar>
              <Content
                style={{ x }}
                ref={(ref: HTMLDivElement) => ref && refMap.set(item, ref)}
              >
                <Inner>
                  <Title>
                    {item.content.map((d) => {
                      if (d.type === 'strong')
                        return <strong key={d.data}>{d.data}</strong>;
                      if (d.type === 'integration') {
                        return (
                          <Box key={d.data}>
                            <Image
                              src={d.logo}
                              width={18}
                              height={18}
                              alt={d.data}
                            />
                            <span>{d.data}</span>
                          </Box>
                        );
                      }
                      if (d.type === 'requirement') {
                        return (
                          <Box key={d.data}>
                            <HumanId>{d.id}</HumanId>
                            <span>{d.data}</span>
                            <RequirementBox success={d.success} size={16} />
                          </Box>
                        );
                      }
                      return d.data;
                    })}
                  </Title>
                  {item.comment && (
                    <Comment>
                      <Markdown content={item.comment} />
                    </Comment>
                  )}
                </Inner>
              </Content>
            </Item>
          );
        })}
      </List>
    </Container>
  );
}
