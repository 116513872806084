import React, { useState, memo, useEffect } from 'react';
import styled from 'styled-components';

import useInview from '../hooks/useInview';

import type { BlockSplashVideoFragment } from '../schemas/api';

import { staggerChildrenFadeTranslateIn } from '../styles/animations';
import COLORS from '../styles/colors';

import { Grid, columns } from '../styles/grid';
import Heading from './Heading';
import CTAButtons from './CTAButtons';
import Image from './Image';

const Container = styled(Grid)`
  padding: 64px 0;
  display: flex;
  position: relative;
  width: 100%;
  min-height: calc(100vh - 108px);
`;

const Background = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  height: calc(100vh - 108px);
  width: 100%;
  min-height: 700px;
  display: flex;
`;

const DotGrid = styled.div`
  width: 100%;
  height: 30%;
  background: radial-gradient(rgba(0, 0, 0, 0.2) 1px, transparent 1px);
  background-size: 24px 24px;
  align-self: flex-end;
`;

const Inner = styled.div`
  ${columns(1, 12)}
  display: flex;
  flex-direction: column;
  align-items: center;
`;

type ContentProps = { $show: boolean };

const VideoArea = styled.div<ContentProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  max-width: 1180px;
  width: 100%;
  padding: 0 12px;
  margin-top: 24px;
  ${(props) => props.$show && staggerChildrenFadeTranslateIn(props.$show, 48)}
`;

const MediaContainer = styled.div`
  box-shadow: 0px 0px 1px ${COLORS.brand.regular.opacity(0.2)},
    0px 4px 100px ${COLORS.brand.regular.opacity(0.1)};
  border: 2px solid ${COLORS.brand.mid.css};
  border-radius: 6px;
  overflow: hidden;
  display: flex;
  pointer-events: none;
  width: 100%;
  & img {
    width: 100%;
  }
  & video {
    width: 100%;
  }
`;

const VideoContainer = styled(MediaContainer)`
  opacity: 0;
  & video {
    width: 100%;
  }
`;

type HomeSplashHeroProps = {
  data: BlockSplashVideoFragment;
};
function HomeSplashHero({ data }: HomeSplashHeroProps) {
  const [inViewRef, inView] = useInview({});
  const [videoLoaded, setVideoLoaded] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setVideoLoaded(true);
    }, 1000);
  }, []);

  const video = data.video?.contentType.startsWith('video') && data.video;
  const image = data.video?.contentType.startsWith('image') && data.video;
  return (
    <>
      <Background>
        <DotGrid />
      </Background>
      <Container ref={inViewRef}>
        <Inner>
          <Heading
            heading={data.heading}
            byline={data.byline}
            show={inView}
            ref={inViewRef}
          />
          {!!data.callToActions?.items.length && (
            <CTAButtons
              links={data.callToActions?.items}
              byline={data.service}
            />
          )}
          {video && (
            <VideoArea $show={videoLoaded}>
              <VideoContainer>
                <video
                  width={video.width}
                  height={video.height}
                  onLoadedData={() => {
                    setVideoLoaded(true);
                  }}
                  autoPlay
                  playsInline
                  controls={false}
                  loop
                  muted
                >
                  <source src={video.url} type="video/mp4" />
                </video>
              </VideoContainer>
            </VideoArea>
          )}
          {image && (
            <VideoArea $show>
              <MediaContainer>
                <Image
                  src={image.url}
                  width={image.width}
                  height={image.height}
                  alt={image.description}
                  style={{ width: '100%', height: 'auto' }}
                />
              </MediaContainer>
            </VideoArea>
          )}
        </Inner>
      </Container>
    </>
  );
}

export default memo(HomeSplashHero);
