import React, { memo } from 'react';
import styled from 'styled-components';
import Image from './Image';

import type {
  BlockMediaCollectionFragment,
  MediaItemFragment,
} from '../schemas/api';

import { MediaAnimation } from '../enums/blocks';

import TYPO from '../styles/typography';
import { Grid, columns, grid } from '../styles/grid';
import Button from '../styles/button';
import { staggerChildrenFadeTranslateIn } from '../styles/animations';

import Link from './Link';
import Icon, { IconName } from './Icon';
import Markdown from './Markdown';
import BREAKPOINTS from '../styles/breakpoints';
import useInview from '../hooks/useInview';
import Heading from './Heading';
import COLORS from '../styles/colors';

import AnimationCollaboration from './AnimationCollaboration';
import AnimationSync from './AnimationSync';
import AnimationContinuousIntegration from './AnimationContinuousIntegration';
import AnimationIntegrate from './AnimationIntegrate';
import AnimationAutomated from './AnimationAutomated';
import AnimationInsights from './AnimationInsights';
import { spacing } from '../styles/blocks';
import markdownStyles from '../styles/markdown';
import MarkdownInline from './MarkdownInline';

type ContainerProps = {
  $spacing: string;
};
const Container = styled(Grid)<ContainerProps>`
  ${(props) => spacing(props.$spacing || '')}
  position:relative;
`;

const ItemPreHeading = styled.h1`
  ${TYPO.preheading}
  color: ${COLORS.brand.regular.css};
`;

type ItemHeadingProps = {
  size: string; // 'Default' | 'Small'
};

const ItemHeading = styled.h1<ItemHeadingProps>`
  ${TYPO.h2}
  font-size:  ${(props) => (props.size === 'Small' ? 24 : 32)}px;
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3px;
`;

const Blocks = styled.div`
  ${columns(1, 12)}
  padding-top: 144px;
  display: flex;
  flex-direction: column;
  gap: 174px;
  ${BREAKPOINTS.max.small`
    gap: 144px;
  `}
`;

type ContentProps = { $show: boolean };

const Content = styled.div<ContentProps>`
  display: flex;
  flex-direction: column;
  gap: 36px;
  p {
    ${TYPO.p1}
  }
  ${(props) => staggerChildrenFadeTranslateIn(props.$show)}
`;

const IntroImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${COLORS.white.mix(COLORS.shades.s100.css, 0.5) as string};

  border-radius: 9px;
  padding: 12px;

  ${BREAKPOINTS.max.small`
    padding:0;
  `}
`;

type ParagraphProps = {
  $size: string; // 'Default' | 'Small';
};
const Paragraph = styled.div<ParagraphProps>`
  margin-top: 6px;
  & > div {
    font-size: ${(props) => (props.$size === 'Small' ? 14 : 16)}px;
    ${markdownStyles}
    & p {
      line-height: 156%;
    }
  }
  &:first-child {
    margin-top: 0;
  }
`;

type BlockProps = {
  $reverse: boolean;
};
const Block = styled.div<BlockProps>`
  ${columns(1, 12)}
  ${grid(12)}
  grid-auto-flow: dense;
  padding: 0 12px;
  ${Content} {
    ${(props) => (props.$reverse ? columns(2, 4) : columns(8, 4))}
    text-align: left;
    display: flex;
    justify-content: center;
    flex-direction: column;
    ${BREAKPOINTS.max.small`
      order:2;
      margin-top:36px;
      ${columns(null, 12)}
    `}
  }

  ${IntroImageContainer} {
    ${(props) => (props.$reverse ? columns(7, 6) : columns(1, 6))}
    ${BREAKPOINTS.max.small`
      order:1;
      ${columns(null, 12)}
    `}
  }
`;

const List = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 12px 0;
`;

const ListItem = styled.li`
  display: flex;
  align-items: flex-start;
  gap: 12px;
  & > svg {
    margin-top: 3px;
    flex: 0 0 auto;
    color: ${COLORS.brand.regular.css};
  }
`;

const ListItemContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3px;

  & > h3 {
    margin: 0;
    ${TYPO.h3}
    font-size:16px;
    ${BREAKPOINTS.max.small`
    font-size:14px;
    `}
  }

  & > p {
    margin: 0;
    ${TYPO.p2}
  }
`;

const GetAnimation = (style: string, canAnimate: boolean) => {
  switch (style) {
    case MediaAnimation.COLLABORATION:
      return <AnimationCollaboration canAnimate={canAnimate} />;
    case MediaAnimation.SYNC:
      return <AnimationSync canAnimate={canAnimate} />;
    case MediaAnimation.CONTINUOUS_INTEGRATION:
      return <AnimationContinuousIntegration canAnimate={canAnimate} />;
    case MediaAnimation.INTEGRATED:
      return <AnimationIntegrate canAnimate={canAnimate} />;
    case MediaAnimation.ACCESSIBLE:
      return <AnimationAutomated canAnimate={canAnimate} />;
    case MediaAnimation.INSIGHTS:
      return <AnimationInsights canAnimate={canAnimate} />;
    default:
      return <div>Animation {style} not found</div>;
  }
};

type BlockAlternatingItemProps = {
  data: MediaItemFragment;
  reverse: boolean;
};

function BlockAlternatingItem({ data, reverse }: BlockAlternatingItemProps) {
  const [inViewRef, inView] = useInview({});
  const [inViewContentRef, inViewContent] = useInview({ once: false });
  return (
    <Block $reverse={reverse}>
      <Content ref={inViewRef} $show={inView}>
        <Info>
          {data.key && <ItemPreHeading>{data.key}</ItemPreHeading>}
          {data.heading && (
            <ItemHeading size={data.headingSize}>
              <MarkdownInline>{data.heading}</MarkdownInline>
            </ItemHeading>
          )}

          {data.content && (
            <Paragraph $size={data.contentSize}>
              <Markdown content={data.content} />
            </Paragraph>
          )}
        </Info>
        {Array.isArray(data.items?.items) && !!data.items.items.length && (
          <List>
            {data.items.items.map((d) => {
              return (
                <ListItem key={d.sys.id}>
                  <Icon name={d.icon as IconName} width={16} height={16} />
                  <ListItemContent>
                    <h3>{d.heading}</h3>
                    <p>{d.content}</p>
                  </ListItemContent>
                </ListItem>
              );
            })}
          </List>
        )}
        {data.cta && (
          <Link link={data.cta}>
            <Button>
              <Icon name="ArrowRight" width={12} height={12} />
              {data.cta.label}
            </Button>
          </Link>
        )}
      </Content>
      <IntroImageContainer ref={inViewContentRef}>
        {data.animation && GetAnimation(data.animation, inViewContent)}
        {data.media && (
          <Image
            src={data.media.url}
            alt={data.heading}
            height={data.media.height}
            width={data.media.width}
            style={{ width: '100%', height: 'auto' }}
          />
        )}
      </IntroImageContainer>
    </Block>
  );
}

type BlockAlternatingFeaturesProps = {
  data: BlockMediaCollectionFragment;
};

function BlockAlternatingFeatures({ data }: BlockAlternatingFeaturesProps) {
  const [inViewRef, inView] = useInview({});
  return (
    <Container $spacing={data.spacing}>
      {data.heading && (
        <Heading heading={data.heading} show={inView} ref={inViewRef} line />
      )}
      <Blocks>
        {data &&
          data.mediaCollection.items.map((item, i) => (
            <BlockAlternatingItem
              data={item}
              reverse={
                data.alignment && data.alignment?.toLowerCase() === 'right'
                  ? i % 2 === 1
                  : i % 2 === 0
              }
              key={item.heading || item.content}
            />
          ))}
      </Blocks>
    </Container>
  );
}

export default memo(BlockAlternatingFeatures);
