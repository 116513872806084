import React, { memo, useMemo } from 'react';
import styled from 'styled-components';
import Typewriter from 'typewriter-effect';

import type { BlockTextTypewriterFragment } from '../schemas/api';

import COLORS from '../styles/colors';
import TYPO from '../styles/typography';
import BREAKPOINTS from '../styles/breakpoints';

import { findListInsideBrackets } from '../utils/text';

import { Grid, columns } from '../styles/grid';

const Container = styled.div`
  position: relative;
`;

const GridContainer = styled(Grid)`
  display: flex;
  align-items: center;
  min-height: 60vh;

  ${BREAKPOINTS.max.medium`
        min-height: 40vh;
  `}
`;

const Text = styled.div`
  ${columns(1, 12)};
  display: inline;
  ${TYPO.display};
  max-width: 930px;

  ${BREAKPOINTS.max.medium`
       max-width: 640px;
  `}
`;

const Carousel = styled.span`
  & > div {
    display: inline;
  }
  color: ${COLORS.brand.regular.css};
  & .Typewriter__cursor {
    color: transparent;
    position: relative;
    &:after {
      position: absolute;
      left: 5px;
      top: 50%;
      transform: translateY(-50%);
      content: '';
      width: 2px;
      height: 70%;
      background: ${COLORS.brand.regular.css};
      display: inline-block;
    }
  }
`;

const DotGrid = styled.div`
  position: absolute;
  width: 100%;
  height: 30%;
  bottom: 0;
  left: 0;
  background: radial-gradient(rgba(0, 0, 0, 0.2) 1px, transparent 1px);
  background-size: 24px 24px;
`;

type BlockTextTypewriterProps = {
  data: BlockTextTypewriterFragment;
};

function BlockTextTypewriter({ data }: BlockTextTypewriterProps) {
  const blocks = useMemo(() => {
    return findListInsideBrackets(data.content);
  }, [data]);

  return (
    <Container>
      <GridContainer>
        <Text>
          {blocks.map((text) => {
            if (Array.isArray(text)) {
              return (
                <Carousel key={text.join('')}>
                  <Typewriter
                    options={{
                      strings: text,
                      autoStart: true,
                      loop: true,
                      delay: 60,
                      deleteSpeed: 60,
                      cursor: ' ',
                    }}
                  />
                </Carousel>
              );
            }
            return <span key={text}>{text}</span>;
          })}
        </Text>
      </GridContainer>
      <DotGrid />
    </Container>
  );
}

export default memo(BlockTextTypewriter);
