import Excel from '../assets/softwares/excel-logo.svg';
import Matlab from '../assets/softwares/matlab-logo.png';
import Python from '../assets/softwares/python.svg';
import Github from '../assets/softwares/github.png';
import Catia from '../assets/softwares/catia.png';
import Ansys from '../assets/softwares/ansys.png';
import Simulink from '../assets/softwares/simulink.png';
import Siemens from '../assets/softwares/siemens-nx-logo.png';
import Jira from '../assets/softwares/jira.png';
import Onshape from '../assets/softwares/onshape.png';
import User from '../assets/images/user-2.jpg';
import FlowLogo from '../assets/flow-logo-black.svg';

type Vector = [number, number];

export type IntegratedNode = {
  name: string;
  icon: string;
  offset: Vector;
  position: Vector;
  mid: Vector;
};
type IntegratedProps = {
  size: Vector;
  flow: { position: Vector; radius: number; size: number; icon: string };
  nodes: IntegratedNode[];
  user: string;
};
const integrated: IntegratedProps = {
  size: [600, 500],
  flow: { position: [450, 420], radius: 80, size: 60, icon: FlowLogo },
  user: User.src,
  nodes: [
    {
      name: 'Ansys',
      icon: Ansys.src,
      offset: [50, 17],
      position: [90, 440],
      mid: [250, 500],
    },
    {
      name: 'Simulink',
      icon: Simulink.src,
      offset: [50, 17],
      position: [110, 345],
      mid: [250, 500],
    },
    {
      name: 'Excel',
      icon: Excel,
      offset: [50, 17],
      position: [90, 250],
      mid: [250, 500],
    },
    {
      name: 'Matlab',
      icon: Matlab.src,
      offset: [50, 17],
      position: [220, 250],
      mid: [250, 350],
    },
    {
      name: 'Catia',
      icon: Catia.src,
      offset: [50, 17],
      position: [110, 155],
      mid: [250, 500],
    },
    {
      name: 'Python',
      icon: Python,
      offset: [50, 17],
      position: [90, 60],
      mid: [250, 250],
    },
    {
      name: 'Onshape',
      icon: Onshape.src,
      offset: [50, 17],
      position: [250, 155],
      mid: [400, 250],
    },
    {
      name: 'Jira',
      icon: Jira.src,
      offset: [50, 17],
      position: [260, 60],
      mid: [500, 250],
    },
    {
      name: 'Github',
      icon: Github.src,
      offset: [50, 17],
      position: [400, 155],
      mid: [500, 250],
    },
    {
      name: 'Siemens',
      icon: Siemens.src,
      offset: [50, 17],
      position: [420, 60],
      mid: [500, 250],
    },
  ],
};

export default integrated;
