import { Fragment } from 'react';
import styled from 'styled-components';
import COLORS from '../styles/colors';

const Strong = styled.strong`
  color: ${COLORS.brand.regular.css};
  font-weight: inherit;
`;

let canvas = null;
let context = null;

export function measure(
  text: string,
  fontName: string,
  fontSize: number,
  lineHeight: number,
  fontWeight: number | string = 'regular'
) {
  // cache
  canvas = canvas || document.createElement('canvas');
  context = context || canvas.getContext('2d');
  canvas.width = 600;
  canvas.height = 600;

  if (!context) {
    return null;
  }

  // re-use canvas object for better performance
  context.font = `${fontWeight} ${fontSize}px/${lineHeight}px ${fontName}`;
  const metrics = context.measureText(text);
  return metrics.width;
}

export function replaceUnderscoreWithNBSP(text: string) {
  return text.replace(/_/g, '\u00a0');
}

function matchAll(regex: RegExp, str: string) {
  const result: string[] = [];

  let match = regex.exec(str);

  while (match) {
    // store the match data
    result.push(match[1]);

    match = regex.exec(str);
  }

  return result;
}

export function findHighlightedWords(text: string, splitBy?: string) {
  const textParts = text.split(/\*/g);
  const highlighted = matchAll(/\*(.*?)\*/g, text);

  const words = [];

  textParts.forEach((part) => {
    part
      .split(splitBy)
      .filter((w) => !!w)
      .forEach((w) =>
        words.push({
          highlighted: highlighted.includes(part),
          text: w,
        })
      );
  });

  return words;
}

/* eslint-disable react/no-array-index-key */
export function highlight(text: string) {
  // sanity check
  if (!text || typeof text !== 'string' || text === '') {
    return '';
  }

  const words = findHighlightedWords(text);

  return words.map((word, i) => {
    return word.highlighted ? (
      <Strong key={`${word.text}_${i}`}>{word.text}</Strong>
    ) : (
      <Fragment key={`${word.text}_${i}`}>{word.text}</Fragment>
    );
  });
}

export function findListInsideBrackets(text: string) {
  // sanity check
  if (!text) {
    return [];
  }

  const indexStart = text.indexOf('[');
  const indexEnd = text.indexOf(']');

  // if opening or closing brackets are not found return the entire string
  if (indexEnd === -1 || indexStart === -1 || indexStart > indexEnd) {
    return [text];
  }

  const a = text.substring(0, indexStart);
  const b = text.substring(indexStart + 1, indexEnd).split(', ');
  const c = text.substring(indexEnd + 1, text.length);

  return [a, b, c].filter(Boolean);
}
