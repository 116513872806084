import React, { useContext } from 'react';
import { PageContext } from './PageProvider';

import Icon from './Icon';
import Button from '../styles/button';

function CTAButton() {
  const { generic, setShowCTAPopup } = useContext(PageContext);

  if (generic.ctaLink) {
    return (
      <Button as="a" href={generic.ctaLink} target="_blank">
        <Icon name="Heart" width={12} height={12} />
        {generic.ctaButtonText}
      </Button>
    );
  }

  return (
    <Button
      onClick={() => {
        setShowCTAPopup(true);
      }}
    >
      <Icon name="Heart" width={12} height={12} />
      {generic.ctaButtonText}
    </Button>
  );
}

export default CTAButton;
