import React, { memo } from 'react';
import styled from 'styled-components';
import Image from './Image';

import type {
  BlockMediaCollectionFragment,
  MediaItemFragment,
} from '../schemas/api';

import { staggerChildrenFadeTranslateIn } from '../styles/animations';
import TYPO from '../styles/typography';
import COLORS from '../styles/colors';
import { Grid, columns, grid } from '../styles/grid';

import Markdown from './Markdown';
import BREAKPOINTS from '../styles/breakpoints';
import useInview from '../hooks/useInview';

import MarkdownInline from './MarkdownInline';

const Heading = styled.h1`
  ${TYPO.h2}
`;

const Blocks = styled(Grid)`
  margin-top: 225px;
  ${BREAKPOINTS.max.medium`
    margin-top: 180px;
  `}
`;

type ContentProps = {
  $show?: boolean;
};

const Content = styled.div<ContentProps>`
  p {
    ${TYPO.p1}
    color: ${COLORS.text.light.css}
  }
  ${(props) => staggerChildrenFadeTranslateIn(props.$show)}
`;

const IntroImageContainer = styled.div``;
const Paragraph = styled.div`
  margin-top: 12px;
  &:first-child {
    margin-top: 0;
  }
`;

type BlockProps = {
  reverse: boolean;
};

const Block = styled.div<BlockProps>`
  ${columns(1, 12)}
  ${grid(12)}
  margin-bottom: 195px;
  grid-auto-flow: dense;

  &:last-child {
    margin-bottom: 0px;
  }

  ${Content} {
    ${(props) => (props.reverse ? columns(1, 6) : columns(7, 6))}
    text-align: 'left';
    display: flex;
    justify-content: center;
    flex-direction: column;
    ${BREAKPOINTS.max.small`
      ${columns(null, 12)}
      grid-row: 2/2;
    `}
  }

  ${IntroImageContainer} {
    ${(props) => (props.reverse ? columns(8, 5) : columns(1, 5))}
    ${BREAKPOINTS.max.small`
      ${columns(null, 12)};
      grid-row: 1/2;
      margin-bottom: 60px
    `}
  }

  ${BREAKPOINTS.max.small`
    margin-bottom: 180px;
  `}
`;

type BlockAlternatingItemProps = {
  data: MediaItemFragment;
  index: number;
};

function BlockAlternatingItem({ data, index }: BlockAlternatingItemProps) {
  const [inViewRef, inView] = useInview({});
  return (
    <Block reverse={index % 2 === 1}>
      <Content ref={inViewRef} $show={inView}>
        {data.heading && (
          <Heading>
            <MarkdownInline>{data.heading}</MarkdownInline>
          </Heading>
        )}
        <Paragraph>
          <Markdown content={data.content} />
        </Paragraph>
      </Content>
      <IntroImageContainer>
        {data.media && (
          <Image
            src={data.media.url}
            alt={data.heading}
            height={data.media.height}
            width={data.media.width}
            style={{ width: '100%', height: 'auto' }}
          />
        )}
      </IntroImageContainer>
    </Block>
  );
}

type BlockAlternatingSmallImagesProps = {
  data: BlockMediaCollectionFragment;
};

function BlockAlternatingSmallImages({
  data,
}: BlockAlternatingSmallImagesProps) {
  return (
    <Blocks>
      {data &&
        data.mediaCollection.items.map((item, i) => (
          <BlockAlternatingItem
            data={item}
            index={i}
            key={item.heading || item.content}
          />
        ))}
    </Blocks>
  );
}

export default memo(BlockAlternatingSmallImages);
