import React, { memo, useRef, useState } from 'react';
import styled from 'styled-components';
import { useSpring, animated } from '@react-spring/web';

import type { BlockFaqFragment, FaqFragment } from '../schemas/api';

import useInview from '../hooks/useInview';
import Heading from './Heading';
import markdownStyles from '../styles/markdown';

import TYPO from '../styles/typography';
import BREAKPOINTS from '../styles/breakpoints';
import { Grid, columns } from '../styles/grid';
import COLORS from '../styles/colors';
import Icon from './Icon';
import Markdown from './Markdown';

const Container = styled(Grid)`
  margin-top: 108px;
`;

const Content = styled.div`
  ${columns(3, 8)};
  display: flex;
  flex-direction: column;
  padding: 12px 36px;
  background: ${COLORS.white.css};
  border: 1px solid ${COLORS.shades.s200.css};
  border-radius: 9px;
  margin-top: 12px;

  box-shadow: 0 0 200px ${COLORS.brand.regular.opacity(0.03)};
  ${BREAKPOINTS.max.small`
  ${columns(1, 12)}
  padding: 12px;
  margin-top: 6px;
  `}
`;

const Item = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-bottom: 1px solid ${COLORS.shades.s200.css};
  &:last-of-type {
    border-bottom: none;
  }
  border-radius: 6px 6px 0 0;
  &:focus {
    outline: 1px solid ${COLORS.brand.regular.css};
  }
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  padding: 12px;
  border-radius: 6px;
  min-height: 60px;
  & > svg {
    margin-left: auto;
    color: ${COLORS.brand.regular.css};
  }
  cursor: pointer;
  &:hover {
    background: ${COLORS.shades.s100.opacity(0.4)};
  }
  ${TYPO.p1}
`;

const Answer = styled(animated.div)`
  ${TYPO.p2};
  overflow: hidden;

  & > div {
    padding: 12px;
    ${markdownStyles}
    & p {
      ${TYPO.p2};
      color: ${COLORS.shades.s300.css};
      line-height: 156%;
    }
  }
`;

type FaqProps = React.HTMLAttributes<HTMLDivElement> & {
  data: FaqFragment;
  active: boolean;
  toggle: () => void;
};

const Faq = ({ data, active, toggle, ...props }: FaqProps) => {
  const ref = useRef(null);
  const [style] = useSpring(
    () => ({
      height: active ? ref.current?.offsetHeight || 0 : 0,
      config: { tension: 210, friction: 24 },
    }),
    [active]
  );

  return (
    <Item {...props}>
      <Title onClick={toggle}>
        <div>{data.title}</div>
        <Icon
          name={active ? 'ChevronUp' : 'ChevronDown'}
          width={12}
          height={12}
        />
      </Title>
      <Answer style={style}>
        <div ref={ref}>
          <Markdown content={data.description} />
        </div>
      </Answer>
    </Item>
  );
};

type BlockFaqProps = {
  data: BlockFaqFragment;
};
function BlockFaq({ data }: BlockFaqProps) {
  const [inViewRef, inView] = useInview({});
  const [active, setActive] = useState<null | number>(null);

  const toggle = (i: number) => setActive((p) => (p === i ? null : i));

  return (
    <Container>
      <Heading
        show={inView}
        ref={inViewRef}
        heading={data.heading}
        byline={data.byline}
        headingLevel="h2"
      />
      <Content>
        {data.faqs.items.map((d, i) => (
          <Faq
            data={d}
            key={d.title}
            active={i === active}
            toggle={() => {
              toggle(i);
            }}
            aria-expanded={i === active}
            aria-labelledby={d.title}
            tabIndex={0}
            onMouseDown={(e) => e.preventDefault()}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
                toggle(i);
              }
            }}
          />
        ))}
      </Content>
    </Container>
  );
}

export default memo(BlockFaq);
