/* eslint-disable @next/next/no-img-element */

import React from 'react';
import styled from 'styled-components';
import { line, curveBundle } from 'd3-shape';
import { useInterval } from 'react-use';
import { useSpring, animated } from '@react-spring/web';

import COLORS from '../styles/colors';

import data, { IntegratedNode } from '../data/integratedData';

import RequirementBox from '../styles/animations/RequirementBox';
import Image from './Image';

const Container = styled.svg`
  position: relative;
  overflow: visible;
  & foreignObject {
    overflow: visible;
  }

  & .logo {
    filter: drop-shadow(0px 0px 100px ${COLORS.brand.regular.opacity(0.2)});
  }
`;

const Node = styled.div`
  border-radius: 6px;
  border: 1px solid ${COLORS.shades.s200.css};
  background: ${COLORS.white.css};
  padding: 6px 12px;
  display: inline-flex;
  gap: 6px;
  & img {
    width: 24px;
    height: 24px;
  }
`;

type DisplayNode = {
  node: IntegratedNode;
};

const DisplayNode = ({ node }: DisplayNode) => {
  return (
    <switch key={node.name}>
      <foreignObject
        x={node.position[0] - node.offset[0]}
        y={node.position[1] - node.offset[1]}
        width="200"
        height="200"
      >
        <Node>
          <Image src={node.icon} width={24} height={24} alt={node.name} />
          {node.name}
        </Node>
      </foreignObject>
      <text x="20" y="20">
        Your SVG viewer cannot display html.
      </text>
    </switch>
  );
};

type DisplayPathProps = {
  path: string;
  index: number;
};

const DisplayPath = ({ path, index }: DisplayPathProps) => {
  const { offsetDistance } = useSpring({
    from: { offsetDistance: '0%' },
    to: { offsetDistance: '100%' },
    loop: true,
    delay: index * 2000,
    config: {
      duration: 4000,
      easing: (x) => -(Math.cos(Math.PI * x) - 1) / 2,
    },
  });

  return (
    <React.Fragment>
      <path
        d={path}
        fill="none"
        stroke={COLORS.shades.s300.css}
        strokeDasharray="4, 4"
      />
      <animated.circle
        r={3}
        style={{ offsetDistance, offsetPath: `path("${path}")` }}
        fill={COLORS.brand.regular.css}
      />
    </React.Fragment>
  );
};

type DisplayRequirementProps = React.SVGProps<SVGGElement> & {
  count: number;
  active: boolean;
};

const DisplayRequirement = ({
  count,
  active,
  ...props
}: DisplayRequirementProps) => {
  const [index, setIndex] = React.useState(0);

  useInterval(
    () => {
      setIndex((prev) => (prev + 1) % 4);
    },
    active ? 2500 : null
  );

  return (
    <g {...props}>
      {Array.from(Array(count).keys()).map((d, i) => {
        return (
          <RequirementBox
            key={d}
            success={index >= d + 1}
            size={20}
            y={i * 24}
          />
        );
      })}
    </g>
  );
};

type AnimationIntegrateProps = React.HTMLAttributes<SVGElement> & {
  canAnimate: boolean;
};

export default function AnimationIntegrate({
  canAnimate,
}: AnimationIntegrateProps) {
  return (
    <Container
      width="100%"
      height="100%"
      viewBox={`0 0 ${data.size[0]} ${data.size[1]}`}
      style={{ maxWidth: `${data.size[0]}px`, maxHeight: `${data.size[1]}px` }}
      preserveAspectRatio="xMidYMid meet"
    >
      {data.nodes.map((d, i) => {
        const points = [d.position, d.mid, data.flow.position];
        const path = line(
          (d) => d[0],
          (d) => d[1]
        ).curve(curveBundle.beta(0.25))(points);

        return <DisplayPath key={d.name} path={path} index={i} />;
      })}
      {data.nodes.map((d) => {
        return <DisplayNode key={d.name} node={d} />;
      })}
      <g
        transform={`translate(${data.flow.position[0]}, ${data.flow.position[1]})`}
      >
        <circle r={data.flow.radius} fill={COLORS.white.css} className="logo" />
        <image
          x={-data.flow.size / 2}
          y={-data.flow.size / 2}
          href={data.flow.icon}
          height={data.flow.size}
          width={data.flow.size}
        />
      </g>
      <DisplayRequirement
        count={3}
        transform="translate(535, 388)"
        active={canAnimate}
      />
      <foreignObject x="560" y="405" width="40px" height="40px">
        <Image
          width={40}
          height={40}
          src={data.user}
          style={{
            borderRadius: '50%',
            border: `3px solid ${COLORS.brand.light.css}`,
          }}
          alt="User"
        />
      </foreignObject>
    </Container>
  );
}
