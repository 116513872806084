import React, { memo } from 'react';
import styled from 'styled-components';
import Image from './Image';

import type { BlockImageFragment } from '../schemas/api';

import useInview from '../hooks/useInview';

import TYPO from '../styles/typography';
import { Grid, columns } from '../styles/grid';

const Container = styled(Grid)`
  position: relative;
  margin-top: 74px;
  margin-bottom: 36px;
`;

type ImageContainerProps = {
  $show: boolean;
};

const ImageContainer = styled.div<ImageContainerProps>`
  width: 100%;
  height: auto;
  opacity: ${(props) => (props.$show ? 1 : 0)};
  transition: opacity 0.2s ease;
  ${columns(1, 12)}
`;

const Caption = styled.div`
  ${columns(1, 12)}
  ${TYPO.p1}
  margin-top: 12px;
  text-align: right;
`;

type BlockImageGridProps = {
  className?: string;
  data: BlockImageFragment;
};

function BlockImageGrid({ className, data }: BlockImageGridProps) {
  const [inViewRef, inView] = useInview({});
  return (
    <Container className={className}>
      <ImageContainer $show={inView} ref={inViewRef}>
        <Image
          src={data.image.url}
          alt={data.heading}
          width={data.image.width}
          height={data.image.height}
          style={{ width: '100%', height: 'auto' }}
        />
      </ImageContainer>
      {data.showHeading && <Caption>{data.heading}</Caption>}
    </Container>
  );
}

export default memo(BlockImageGrid);
