import React from 'react';
import Modal from 'react-modal';

import COLORS from '../styles/colors';

const content: React.CSSProperties = {
  border: 'none',
  background: 'transparent',
  overflow: 'hidden',
  outline: 'none',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  inset: '0px',
  pointerEvents: 'none',
  maxWidth: '1200px',
  margin: '0 auto',
  gap: '6px',
};

const overlay: React.CSSProperties = {
  background: COLORS.black.opacity(0.6),
  zIndex: 999,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

Modal.setAppElement('#__next');

export default function DisplayModal(props: ReactModal.Props) {
  function afterOpen() {
    document.body.style.overflow = 'hidden';
  }
  function afterClose() {
    document.body.style.overflow = 'visible';
  }

  return (
    <Modal
      onAfterOpen={afterOpen}
      onAfterClose={afterClose}
      shouldCloseOnOverlayClick
      style={{ overlay, content }}
      {...props}
    />
  );
}
