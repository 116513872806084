import Matlab from '../assets/softwares/matlab-logo.png';
import Ansys from '../assets/softwares/ansys.png';
import Siemens from '../assets/softwares/siemens-nx-logo.png';
import Onshape from '../assets/softwares/onshape.png';

type Basic = {
  offset: number;
  depth: number;
  id: number;
  check: boolean;
  name: string;
};

type Requirement = Basic & { type: 'Requirement' };

type Model = Basic & { type: 'Model'; data: string; logo: string };

type Simulation = Basic & { type: 'Simulation'; data: string; logo: string };

type Value = Basic & { type: 'Value'; data: string };

type TestData = Basic & { type: 'Test Data'; data: string };

export type Node = Requirement | Value | Simulation | Model | TestData;

type Props = {
  size: [number, number];
  nodes: Node[];
  links: { source: number; target: number; id: string }[];
  highlight: (number | string)[];
  node: { width: number; heightMin: number; heightMax: number };
  gap: { v: number; h: number };
};

const data: Props = {
  size: [600, 500],
  nodes: [
    {
      id: 0,
      name: 'Total mass',
      check: false,
      type: 'Requirement',
      depth: 0,
      offset: 0,
    },
    {
      id: 1,
      name: 'Buckling test',
      check: true,
      type: 'Test Data',
      data: 'results.csv',
      depth: 1,
      offset: 0,
    },
    {
      id: 2,
      name: 'Main shaft CAD model',
      check: false,
      type: 'Model',
      logo: Siemens.src,
      data: 'Siemens NX',
      depth: 1,
      offset: 1,
    },
    {
      id: 3,
      name: 'Centre of gravity',
      check: true,
      type: 'Value',
      data: '[100, 250, 14]',
      depth: 1,
      offset: 2,
    },
    {
      id: 4,
      name: 'Support mass',
      check: true,
      type: 'Requirement',
      depth: 1,
      offset: 3,
    },
    {
      id: 5,
      name: 'Bearing assembly CAD',
      check: true,
      type: 'Model',
      logo: Onshape.src,
      data: 'Onshape',
      depth: 1,
      offset: 4,
    },
    {
      id: 6,
      name: 'Fatigue calcs',
      check: true,
      type: 'Model',
      logo: Matlab.src,
      data: 'matlab.m',
      depth: 2,
      offset: 0,
    },
    {
      id: 7,
      name: 'Vibration frequency',
      check: false,
      type: 'Simulation',
      logo: Ansys.src,
      data: 'Ansys',
      depth: 2,
      offset: 1,
    },
    {
      id: 8,
      name: 'Sub-frame mass',
      check: true,
      type: 'Requirement',
      depth: 2,
      offset: 2,
    },
  ],
  links: [
    { source: 0, target: 1, id: 'a' },
    { source: 0, target: 2, id: 'b' },
    { source: 0, target: 3, id: 'c' },
    { source: 0, target: 4, id: 'd' },
    { source: 0, target: 5, id: 'e' },
    { source: 2, target: 6, id: 'f' },
    { source: 2, target: 7, id: 'g' },
    { source: 4, target: 8, id: 'h' },
  ],
  highlight: [7, 2, 0],
  node: { width: 170, heightMin: 36, heightMax: 50 },
  gap: { h: 45, v: 38 },
};

// ts-unused-exports:disable-next-line
export default data;
