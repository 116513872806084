import React, { memo, useState } from 'react';
import styled, { css } from 'styled-components';
import { useSpring, animated } from '@react-spring/web';

import Image from './Image';

import type {
  BlockMediaCollectionFragment,
  MediaItemFragment,
} from '../schemas/api';

import Heading from './Heading';
import TYPO from '../styles/typography';
import { Grid, columns, grid } from '../styles/grid';
import Button from '../styles/button';
import COLORS from '../styles/colors';

import Link from './Link';
import Icon, { IconName } from './Icon';
import Markdown from './Markdown';
import BREAKPOINTS from '../styles/breakpoints';
import useInview from '../hooks/useInview';
import { divider } from '../styles/divider';
import MarkdownInline from './MarkdownInline';

const Container = styled(Grid)`
  margin-top: 60px;
  padding: 108px 12px;
  ${divider('left')}
`;

const ItemHeading = styled.h1`
  ${TYPO.h3}
  font-weight: 500;
  font-size: 24px;
`;

const Blocks = styled.div`
  ${columns(1, 12)}
  padding: 60px 0 0;
  display: flex;
  flex-direction: column;
  gap: 174px;
  ${BREAKPOINTS.max.small`
    gap: 144px;
  `}
`;

const Content = styled(animated.div)`
  display: flex;
  flex-direction: column;
  gap: 12px;
  p {
    ${TYPO.p1}
  }
`;

const IntroImageContainer = styled(animated.div)`
  filter: drop-shadow(0px 0px 100px ${COLORS.brand.regular.opacity(0.1)});

  ${BREAKPOINTS.max.small`
  filter:none;
`}
`;

const Paragraph = styled.div`
  &:first-child {
    margin-top: 0;
  }
`;

const Block = styled.div`
  ${columns(1, 12)}
  ${grid(12)}
  grid-auto-flow: dense;
  row-gap: 24px;
  ${Content} {
    ${columns(1, 4)}
    text-align: left;
    display: flex;
    justify-content: center;
    flex-direction: column;
    ${BREAKPOINTS.max.small`
      order:2;
      text-align:center;
      align-items: center;
      ${columns(null, 12)}
    `}
  }

  ${IntroImageContainer} {
    ${columns(6, 7)}
    ${BREAKPOINTS.max.small`
      order:1;
      ${columns(null, 12)}
    `}
  }
`;

const Tabs = styled.div`
  ${columns(1, 12)}
  display: flex;
  gap: 36px;

  align-items: center;

  ${BREAKPOINTS.max.small`
      flex-direction:column;
      gap: 24px;
    `}
`;

type TabProps = {
  $active: boolean;
};

const Tab = styled.div<TabProps>`
  display: flex;
  ${TYPO.p1}
  font-weight: 500;
  cursor: pointer;
  position: relative;
  padding: 6px 0;
  border-bottom: 2px solid transparent;
  color: ${COLORS.black.css};

  ${BREAKPOINTS.max.small`
      font-size:14px;
    `}

  &:hover {
    border-color: ${COLORS.brand.regular.opacity(0.1)};
    color: ${COLORS.shades.s400.css};
  }

  ${(props) =>
    props.$active &&
    css`
      border-color: ${COLORS.brand.regular.css};
      &:hover {
        border-color: ${COLORS.brand.regular.css};
      }
    `}
`;

const List = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 12px 0;
`;

const ListItem = styled.li`
  display: flex;
  align-items: center;
  gap: 12px;
  & > svg {
    color: ${COLORS.brand.regular.css};
  }
  ${TYPO.p1}
`;

type BlockAlternatingItemProps = {
  data: MediaItemFragment;
};

function BlockAlternatingItem({ data }: BlockAlternatingItemProps) {
  const { opacity, image, content } = useSpring({
    from: { image: 50, content: -50, opacity: 0 },
    to: { image: 0, content: 0, opacity: 1 },
    reset: true,
  });

  return (
    <Block>
      <Content style={{ opacity, x: content }}>
        {data.heading && (
          <ItemHeading>
            <MarkdownInline>{data.heading}</MarkdownInline>
          </ItemHeading>
        )}
        <Paragraph>
          <Markdown content={data.content} />
        </Paragraph>
        {Array.isArray(data.items?.items) && !!data.items.items.length && (
          <List>
            {data.items.items.map((d) => {
              return (
                <ListItem key={d.sys.id}>
                  <Icon name={d.icon as IconName} width={16} height={16} />
                  <span>{d.heading}</span>
                </ListItem>
              );
            })}
          </List>
        )}
        {data.cta && (
          <Link link={data.cta}>
            <Button>
              <Icon name="ArrowRight" width={12} height={12} />
              {data.cta.label}
            </Button>
          </Link>
        )}
      </Content>
      {data.media && (
        <IntroImageContainer style={{ opacity, x: image }}>
          <Image
            src={data.media.url}
            alt={data.heading}
            height={data.media.height}
            width={data.media.width}
            style={{ width: '100%', height: 'auto' }}
          />
        </IntroImageContainer>
      )}
    </Block>
  );
}

type BlockTeamCarouselProps = {
  data: BlockMediaCollectionFragment;
};

function BlockTeamCarousel({ data }: BlockTeamCarouselProps) {
  const [inViewRef, inView] = useInview({});
  const [active, setActive] = useState(0);

  const current = data.mediaCollection.items[active];

  return (
    <Container>
      <Heading
        preHeading={data.preHeading}
        heading={data.heading}
        byline={data.byline}
        show={inView}
        ref={inViewRef}
        align="left"
      />
      <Tabs>
        {data.mediaCollection.items.map((d, i) => (
          <Tab
            key={d.sys.id}
            onClick={() => setActive(i)}
            $active={active === i}
          >
            {d.key}
          </Tab>
        ))}
      </Tabs>
      <Blocks key={current?.sys.id || 'current'}>
        {current && <BlockAlternatingItem data={current} />}
      </Blocks>
    </Container>
  );
}

export default memo(BlockTeamCarousel);
