import React from 'react';
import styled from 'styled-components';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import { BlockQuoteFragment } from '../schemas/api';
import markdownStyles from '../styles/markdown';

import Image from './Image';
import TYPO from '../styles/typography';
import COLORS from '../styles/colors';
import Icon from './Icon';
import Markdown from './Markdown';

const Container = styled.div`
  width: 100%;
  position: relative;

  .alice-carousel__prev-btn,
  .alice-carousel__next-btn {
    padding: 0;
    display: flex;
  }
`;

const ItemContainer = styled.div`
  display: flex;
  padding: 0 12px;
  min-height: 400px;
`;

const Inner = styled.div`
  border: 1px solid ${COLORS.shades.s200.css};
  border-radius: 9px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  min-height: 400px;
`;

const Stars = styled.div`
  display: flex;
  & > svg {
    fill: ${COLORS.brand.regular.css};
    stroke: none;
  }
`;

const Quote = styled.div`
  font-size: 14px;
  ${markdownStyles}
  & p {
    ${TYPO.quoteSmall}
  }
  & strong {
    color: ${COLORS.brand.regular.css};
    font-weight: 600;
  }
`;

const Avatar = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  overflow: hidden;
  flex: 0 0 auto;
`;

const Author = styled.div`
  margin-top: auto;
  display: flex;
  gap: 12px;
  align-items: flex-start;
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
`;

const Name = styled.div`
  ${TYPO.p2};
  font-weight: 500;
`;

const Role = styled.div`
  ${TYPO.small}
  color: ${COLORS.shades.s300.css}
`;

type NavButtonProps = {
  $side: 'left' | 'right';
};
const NavButton = styled.button<NavButtonProps>`
  position: absolute;
  top: 0;
  ${(props) => props.$side}: -12px;
  height: 100%;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    color: ${COLORS.brand.regular.css};
    & svg {
      stroke-width: 3px;
    }
  }
`;

type TestimonialProps = {
  item: BlockQuoteFragment;
};

const Testimonial = ({ item }: TestimonialProps) => {
  return (
    <ItemContainer>
      <Inner>
        <Stars>
          {Array.from(Array(5).keys()).map((d) => (
            <Icon key={d} name="Star" width={12} height={12} />
          ))}
        </Stars>
        <Quote>
          <Markdown content={item.quote} />
        </Quote>

        <Author>
          <Avatar>
            <Image
              src={item.picture.url}
              height={48}
              width={48}
              alt={item.picture.title}
            />
          </Avatar>
          <Info>
            <Name>{item.source}</Name>
            <Role>{item.role}</Role>
          </Info>
        </Author>
      </Inner>
    </ItemContainer>
  );
};

type TestimonialsCarouselProps = {
  items: BlockQuoteFragment[];
};

const TestimonialsCarousel = ({ items }: TestimonialsCarouselProps) => {
  const disabled = items.length > 3;

  return (
    <Container>
      <AliceCarousel
        infinite
        mouseTracking
        autoPlay
        autoPlayStrategy="action"
        autoPlayInterval={3000}
        disableDotsControls
        disableButtonsControls={!disabled}
        items={items.map((d) => (
          <Testimonial item={d} key={d.source} />
        ))}
        responsive={{
          0: {
            items: 1,
          },
          768: {
            items: 2,
          },
          1024: {
            items: 3,
          },
        }}
        renderNextButton={({ isDisabled }) => (
          <NavButton disabled={isDisabled} $side="right">
            <Icon name="ChevronRight" height={12} width={12} />
          </NavButton>
        )}
        renderPrevButton={({ isDisabled }) => (
          <NavButton disabled={isDisabled} $side="left">
            <Icon name="ChevronLeft" height={12} width={12} />
          </NavButton>
        )}
      />
    </Container>
  );
};

export default TestimonialsCarousel;
