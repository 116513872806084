import React from 'react';
import styled from 'styled-components';
import { CompaniesGroupFragment } from '../schemas/api';

import Image from './Image';
import TYPO from '../styles/typography';
import BREAKPOINTS from '../styles/breakpoints';
import COLORS from '../styles/colors';
import Icon from './Icon';
import Button from '../styles/button';
import LinkJSX from './Link';

const Container = styled.div`
  border: 1px solid ${COLORS.shades.s200.css};
  border-radius: 9px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3px;
`;

const Name = styled.div`
  ${TYPO.p1};
  font-weight: 500;
`;

const Description = styled.div`
  ${TYPO.p2}
  color: ${COLORS.shades.s300.css}
`;

const Logos = styled.div`
  display: flex;
  gap: 24px;

  & > * {
    flex: 1;
  }

  ${BREAKPOINTS.max.medium`
  gap: 24px;
`}
  ${BREAKPOINTS.max.small`
gap: 12px;
`}
`;

const Footer = styled.div`
  display: flex;
  gap: 60px;
  align-items: center;
  margin-top: auto;

  ${BREAKPOINTS.max.medium`
  gap: 36px;
`}
  ${BREAKPOINTS.max.small`
  gap: 12px;
  flex-direction:column;
`}
`;

type CompanyInsightProps = {
  item: CompaniesGroupFragment;
};

const CompanyInsight = ({ item }: CompanyInsightProps) => {
  return (
    <Container>
      <Info>
        <Name>{item.name}</Name>
        <Description>{item.description}</Description>
      </Info>

      <Footer>
        <Logos>
          {item.companies.items.map((d) => (
            <span key={d.logo.title}>
              <Image
                alt={d.logo.title}
                src={d.logo.url}
                height={d.logo.height}
                width={d.logo.width}
                style={{ objectFit: 'contain', width: '100%', height: '100%' }}
                priority
              />
            </span>
          ))}
        </Logos>
        <LinkJSX link={item.cta}>
          <Button $variant="secondary" $shape="rounded">
            <Icon name="ChevronRight" width={12} height={12} />
            {item.cta.label}
          </Button>
        </LinkJSX>
      </Footer>
    </Container>
  );
};

export default CompanyInsight;
